import store from '@/store'
import { PROJECT_FEATURE_IS_ACTIVE, FINANCES_FEATURE_IS_ACTIVE } from '@/store/getters.type'
import features from './features'

const financesIndependentFeatures = [
  'Referral',
  'SSL',
  'PaymentGateway',
  'PaymentInvoice',
  'PromisedPayment',
  'CustomerPayBonus',
  'PostPaid',
  'ExternalPaymentRules',
  'DocumentFlow',
  'MarketingTasks',
  'ManagedServers',
]

const isActiveFeature = (feature, optionName) => {
  const financeFeatureIsActive = store.getters[FINANCES_FEATURE_IS_ACTIVE]
  const isActive = store.getters[PROJECT_FEATURE_IS_ACTIVE](feature, optionName)

  if (isActive && (!financesIndependentFeatures.includes(feature) || financeFeatureIsActive)) {
    return true
  }

  return false
}

const isAnyActiveFeature = (...list) => {
  let result = false
  list.forEach(feature => {
    if (isActiveFeature(feature)) {
      result = true
    }
  })
  return result
}

export default {
  isActiveFeature,
  isAnyActiveFeature,
  features,
}
