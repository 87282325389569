import ApiService from '@/api'
import { PROJECT_ACCEPT, PROJECT_CREATE, PROJECT_DECLINE, PROJECT_DELETE, PROJECT_INFO_FETCH, PROJECT_LEAVE, PROJECT_LOGO, PROJECT_MEMBER_INVITE, PROJECT_MEMBER_INVITE_RESEND, PROJECT_MEMBER_REMOVE, PROJECT_MEMBER_ROLE_CHANGE, PROJECT_NAME, PROJECT_CUSTOM_LOGO_URL, PROJECT_DARK_THEME_TOGGLE } from '../actions.type'
import { PROJECT_FEATURE_IS_ACTIVE, FINANCES_FEATURE_IS_ACTIVE } from '../getters.type'
import { PROJECT_ROLE_SET, PROJECT_SET } from '../mutations.type'

const state = {
  current: null,
  expire: null,
}

const getters = {
  [PROJECT_FEATURE_IS_ACTIVE]: (state) => (feature, optionName) => {
    const featureObj = state.current.Features.find(x => x.Id === feature)
    if (featureObj) {
      if (featureObj.Id === 'CloudDirector') {
        return featureObj.FeatureOptions[optionName]
      } else {
        return true
      }
    }
  },
  [FINANCES_FEATURE_IS_ACTIVE]: state => Boolean(state.current.Features.find(x => x.Id === 'Finances')),
}

const actions = {
  [PROJECT_CREATE] (context, model) {
    return ApiService.post('/app-api/project', model, {
      transformRequest: (data) => {
        return data
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_INFO_FETCH] () {
    return ApiService.get('/app-api/project/settings/json')
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_DELETE] () {
    return ApiService.delete('/app-api/project')
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_NAME] (context, model) {
    return ApiService.post('/app-api/project/name', model)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_LOGO] (context, model) {
    return ApiService.post('/app-api/project/logo', model, {
      headers: {
        'not-transform': (!!(model && model.get && model.get('ProjectLogo'))),
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_CUSTOM_LOGO_URL] () {
    return ApiService.get('/app-api/project/logo')
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_LEAVE] () {
    return ApiService.post('/app-api/project/leave')
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_MEMBER_INVITE] (context, model) {
    return ApiService.post('/app-api/project/account/invite', model)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_MEMBER_INVITE_RESEND] (context, accountId) {
    return ApiService.post(`/app-api/project/account/${accountId}/resend-invite`)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_MEMBER_ROLE_CHANGE] (context, props) {
    return ApiService.post(`/app-api/project/account/${props.accountId}/role`, props.model)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_MEMBER_REMOVE] (context, accountId) {
    return ApiService.delete(`/app-api/project/account/${accountId}`)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_ACCEPT] (context, projectId) {
    return ApiService.post(`/app-api/project/${projectId}/invite/accept`)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_DECLINE] (context, projectId) {
    return ApiService.post(`/app-api/project/${projectId}/invite/reject`)
      .then(({ data }) => {
        return data
      })
  },
  [PROJECT_DARK_THEME_TOGGLE] () {
    return ApiService.post(`/app-api/app/toggle-theme`)
      .then(({ data }) => {
        return data
      })
  },
}

const mutations = {
  [PROJECT_SET] (state, project) {
    state.current = project
    state.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_PROJECT_EXIRE_MS)).getTime()
  },
  [PROJECT_ROLE_SET] (state, role) {
    state.current.Role = role
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
