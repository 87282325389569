import { ceilDeci, toFixedNum } from '@/lib/numbers'
import store from '@/store'

function getPrice (price, precision) {
  price = price || 0

  price = getDiscount(price)

  price = getFormat(price, precision)

  return price
}

function getDiscount (price) {
  if (
    store.state.project.current.Discount &&
    new Date(store.state.project.current.Discount.ValidFrom) <= new Date() &&
    (!store.state.project.current.Discount.ValidTill || (new Date(store.state.project.current.Discount.ValidTill > new Date())))
  ) {
    return (price / 100) * (100 - store.state.project.current.Discount.Percent)
  }

  return price
}

function getFormat (price, precision) {
  return ceilDeci(toFixedNum(price, precision || 2), precision || 2)
}

export default {
  install (app) {
    const name = 'price'

    app.config.globalProperties['$' + name] = getPrice
    app[name] = getPrice
  },
}
