import { createApp } from 'vue'
import qs from 'qs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@/plugins/app-notifications'
import i18n from '@/i18n.js'
import store from '@/store'
import router from '@/router'
import { COMMON_SET_VERSION } from '../store/mutations.type'
const app = createApp()
const $t = (key) => i18n.global.t(key)
app.use(Notifications)
class ServerValidationError extends Error {
  constructor (message, error) {
    super(message)
    this.isServerValidationError = true
    this.modelState = error.response.data.ModelState
    this.error = error
  }
}

const projectIdInterceptor = (config) => {
  if (config.method === 'get') {
    config.params = config.params || {}
    if (!config.params['pid']) {
      if (!store.state.project.current) {
        return config
      }
      config.params['pid'] = store.state.project.current.Id
    }
  } else {
    config.headers['X-PID'] = store.state.project.current.Id
  }

  return config
}

const xsrfTokenInterceptor = (config) => {
  if (config.method !== 'get') {
    config.data = config.data || {}
    if (config.data instanceof FormData) {
      config.data.append('__RequestVerificationToken', store.state.app.data.Token)
    } else {
      config.data['__RequestVerificationToken'] = store.state.app.data.Token
    }
  }
  return config
}

const responseInterceptor = response => {
  if (response && response.headers && response.headers['x-script-version']) {
    if (!store.state.version) {
      store.commit(COMMON_SET_VERSION, response.headers['x-script-version'])
    } else {
      if (response.headers['x-script-version'] !== store.state.version) {
        window.location.reload()
      }
    }
  }

  if (response.data && response.data.redirectTo) {
    if (response.data.redirectTo[0] === '/') {
      router.push(response.data.redirectTo)
    } else {
      window.location.href = response.data.redirectTo
    }
    return new Promise(() => {})
  }

  return response
}

const errorInterceptor = error => {
  let isServerValidationError = false

  if (error && error.response) {
    switch (error.response.status) {
      case 400: {
        if (error.response.data && error.response.data.ModelState) {
          app.config.globalProperties.$notify.addModelErrors(error.response.data.ModelState)
          isServerValidationError = true
        } else if (typeof error.response.data === 'string') {
          try {
            let data = JSON.parse(error.response.data)
            if (data.ModelState) {
              app.config.globalProperties.$notify.addModelErrors(data.ModelState)
              isServerValidationError = true
            } else {
              app.config.globalProperties.$notify.error($t('shared.apiError.500'))
            }
          } catch {
            app.config.globalProperties.$notify.error($t('shared.apiError.500'))
          }
        } else {
          app.config.globalProperties.$notify.error($t('shared.apiError.500'))
        }
        break
      }
      case 401: {
        const currentLoc = window.location
        window.location.href = '/app-api/account/signin?returnUrl=' + currentLoc
        // window.location.reload()
        return new Promise(() => {})
      }
      case 404:
        if (error.config.method === 'get') {
          router.replace({ name: 'error-404' })
          return new Promise(() => {})
        } else {
          app.notify.error($t('shared.apiError.404-post'))
        }
        break
      case 500: {
        app.notify.error($t('shared.apiError.500'))
        break
      }
      case 503: {
        router.replace({ name: 'error-503' })
        break
      }
    }
  }

  if (isServerValidationError) {
    return Promise.reject(new ServerValidationError(error.message || 'Server validation error', error))
  } else {
    return Promise.reject(error)
  }
}

const instance = axios.create({
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  transformRequest (data, headers) {
    if (data && !headers['not-transform']) {
      headers['content-type'] = 'application/x-www-form-urlencoded'
      return qs.stringify(data)
    }

    return data
  },
})

app.use(VueAxios, instance);
app.config.globalProperties.axios.interceptors.request.use(projectIdInterceptor);
app.config.globalProperties.axios.interceptors.request.use(xsrfTokenInterceptor);
app.config.globalProperties.axios.interceptors.response.use(responseInterceptor, errorInterceptor);

const ApiService = {

  query (resource, params) {
    return app.axios.get(resource, params)
  },

  get (resource, slug = '') {
    return app.axios.get(`${resource}/${slug}`)
  },

  post (resource, params, config) {
    return app.axios.post(`${resource}`, params, config)
  },

  update (resource, slug, params, config) {
    return app.axios.put(`${resource}/${slug}`, params, config)
  },

  put (resource, params, config) {
    return app.axios.put(`${resource}`, params, config)
  },

  delete (resource, config) {
    return app.axios.delete(resource, config)
  },

  head (resource, config) {
    return app.axios.head(resource, config)
  },
}

export default ApiService
