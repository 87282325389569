<template>
  <Popup @close="onClose">
    <template #default>
      <div
        class="popup-project-invite"
      >
        <span class="title">{{ $t('projects.shared.popup.invite.title') }}</span>
        <i18n-t
          keypath="projects.shared.popup.invite.desc"
          tag="div"
          class="popup-project-invite-desc"
        >
          <template #project>
            <b>{{ project.Name }}</b>
          </template>
        </i18n-t>
      </div>
    </template>
    <template #controls>
      <BaseButton
        :submit="submit"
        reverse
        @click="onDecline"
      >
        {{ $t('projects.shared.popup.invite.btn.decline') }}
      </BaseButton>
      <BaseButton
        :submit="submit"
        @click="onAccept"
      >
        {{ $t('projects.shared.popup.invite.btn.accept') }}
      </BaseButton>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/shared/Popup.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { PROJECT_ACCEPT, PROJECT_DECLINE } from '@/store/actions.type'

export default {
  components: {
    Popup,
    BaseButton,
  },
  props: {
    project: { type: Object, required: true },
  },
  emits: ['close', 'decline', 'accept'],
  data () {
    return {
      submit: false,
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    onAccept () {
      if (this.submit) {
        return
      }

      this.submit = true
      this.$store.dispatch(PROJECT_ACCEPT, this.project.Id)
        .then(() => {
          this.$emit('accept', this.project.Id)
        })
        .catch(() => {
          this.submit = false
        })
    },
    onDecline () {
      if (this.submit) {
        return
      }

      this.submit = true
      this.$store.dispatch(PROJECT_DECLINE, this.project.Id)
        .then(() => {
          this.$emit('decline')
        })
        .catch(() => {
          this.submit = false
        })
    },
  },

}
</script>
