const accountLogos = [
  {
    name: '1.jpg',
    path: process.env.BASE_URL + 'default-project-logos/1.jpg',
  },
  {
    name: '2.jpg',
    path: process.env.BASE_URL + 'default-project-logos/2.jpg',
  },
  {
    name: '3.jpg',
    path: process.env.BASE_URL + 'default-project-logos/3.jpg',
  },
  {
    name: '4.jpg',
    path: process.env.BASE_URL + 'default-project-logos/4.jpg',
  },
  {
    name: '5.jpg',
    path: process.env.BASE_URL + 'default-project-logos/5.jpg',
  },
  {
    name: '6.jpg',
    path: process.env.BASE_URL + 'default-project-logos/6.jpg',
  },
  {
    name: '7.jpg',
    path: process.env.BASE_URL + 'default-project-logos/7.jpg',
  },
  {
    name: '8.jpg',
    path: process.env.BASE_URL + 'default-project-logos/8.jpg',
  },
  {
    name: '9.jpg',
    path: process.env.BASE_URL + 'default-project-logos/9.jpg',
  },
]

export default {
  accountLogos,
}
