const SIWithImage = 'SIWithImage'
const PrivateNetwork = 'PrivateNetwork'
const DNS = 'DNS'
const SSL = 'SSL'
const Referral = 'Referral'
const PaymentGateway = 'PaymentGateway'
const PaymentInvoice = 'PaymentInvoice'
const PromisedPayment = 'PromisedPayment'
const API = 'API'
const CustomerPayBonus = 'CustomerPayBonus'
const Unoc = 'Unoc'
const Support = 'Support'
const SimpleOneSupport = 'SimpleOneSupport'
const PublicNetwork = 'PublicNetwork'
const Storage = 'Storage'
const EmailMarketing = 'EmailMarketing'
const CpaMarketing = 'CpaMarketing'
const Messengers = 'Messengers'
const IPv6Network = 'IPv6Network'
const RoutedNetwork = 'RoutedNetwork'
const MultiProjects = 'MultiProjects'
const Vstack = 'Vstack'
const PostPaid = 'PostPaid'
const ExternalPaymentRules = 'ExternalPaymentRules'
const UnocV2 = 'UnocV2'
const Kubernetes = 'Kubernetes'
const CloudDirector = 'CloudDirector'
const ManagedServers = 'ManagedServers'
const DocumentFlow = 'DocumentFlow'
const ConfiguredServers = 'Vpn'
const MarketingTasks = 'MarketingTasks'
const Cdn = 'Cdn'
const CdnS3 = 'CdnS3'
const CdnVod = 'CdnVod'
const Finances = 'Finances'
const Llm = 'Llm'
const FeedbackForm = 'FeedbackForm'

export default {
  SIWithImage,
  PrivateNetwork,
  DNS,
  SSL,
  Referral,
  PaymentGateway,
  PaymentInvoice,
  PromisedPayment,
  API,
  CustomerPayBonus,
  Unoc,
  Support,
  SimpleOneSupport,
  PublicNetwork,
  Storage,
  EmailMarketing,
  CpaMarketing,
  Messengers,
  IPv6Network,
  RoutedNetwork,
  MultiProjects,
  Vstack,
  PostPaid,
  ExternalPaymentRules,
  UnocV2,
  Kubernetes,
  CloudDirector,
  ManagedServers,
  DocumentFlow,
  ConfiguredServers,
  MarketingTasks,
  Cdn,
  CdnS3,
  CdnVod,
  Finances,
  Llm,
  FeedbackForm,
}
