import { MANAGED_CALCULATOR_FETCH, MANAGED_CREATE, MANAGED_CANCEL, MANAGED_DETAIL_AUDIT_FETCH, MANAGED_DETAIL_FETCH, MANAGED_LIST_FETCH, MANAGED_SERVER_SUBSCRIBE_FETCH, MANAGED_SERVER_SUBSCRIBE_ADD } from '../actions.type'

import ApiService from '@/api'

const state = {
}

// getters
const getters = {
}
// actions
const actions = {
  [MANAGED_LIST_FETCH] () {
    return ApiService.get('/app-api/managed-server-subscriptions/json')
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_CALCULATOR_FETCH] () {
    return ApiService.get('/app-api/managed-server-subscriptions/create/json')
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_CREATE] (context, model) {
    return ApiService.post('/app-api/managed-server-subscriptions/create', model)
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_CANCEL] (context, subscriptionId) {
    return ApiService.post(`/app-api/managed-server-subscriptions/${subscriptionId}/cancel`)
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_DETAIL_FETCH] (context, subscriptionId) {
    return ApiService.get(`/app-api/managed-server-subscriptions/${subscriptionId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_DETAIL_AUDIT_FETCH] (context, subscriptionId) {
    return ApiService.get(`/app-api/managed-server-subscriptions/${subscriptionId}/audit-report`)
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_SERVER_SUBSCRIBE_FETCH] (context, params) {
    return ApiService.get(`/app-api/managed-server-subscriptions/${params.type}/${params.serverId}`)
      .then(({ data }) => {
        return data
      })
  },
  [MANAGED_SERVER_SUBSCRIBE_ADD] (context, params) {
    return ApiService.post(`/app-api/managed-server-subscriptions/${params.subscriptionId}/${params.type}/${params.serverId}`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
