import ApiService from '@/api'

import {
  SSL_CALCULATOR_FETCH,
  SSL_CSR_DECODE,
  SSL_EMAIL_APPROVAL,
  SSL_CHECK_DOMAIN,
  SSL_LIST_FETCH,
  SSL_CREATE,
  SSL_CERTIFICATE_FETCH,
  SSL_CERTIFICATE_CANCEL,
  SSL_CERTIFICATE_DELETE,
  SSL_CERTIFICATE_PROLONG,
  SSL_CERTIFICATE_REISSUE,
  SSL_CERTIFICATE_RESUME,
  SSL_CERTIFICATE_REVALIDATE,
  SSL_CSR_GENERATE,
  SSL_CERTIFICATE_TASK,
  SSL_CERTIFICATE_AUTO_PROLONG,
  SSL_CERTIFICATE_UPDATE,
} from '../actions.type'

const state = {

}

// getters
const getters = {

}

// actions
const actions = {
  [SSL_CALCULATOR_FETCH] () {
    return ApiService.get('/app-api/ssl/create/json')
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CSR_DECODE] (context, model) {
    return ApiService.post('/app-api/ssl/csr/decode', model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CSR_GENERATE] (context, model) {
    return ApiService.post('/app-api/ssl/csr/generate', model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_EMAIL_APPROVAL] (context, model) {
    return ApiService.post('/app-api/ssl/dcv/email-approver-list', model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CHECK_DOMAIN] (context, model) {
    return ApiService.post('/app-api/ssl/dcv/check-domain', model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CREATE] (context, model) {
    return ApiService.post('/app-api/ssl/create', model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_LIST_FETCH] () {
    return ApiService.get('/app-api/ssl/list/json')
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_FETCH] (context, certId) {
    return ApiService.get(`/app-api/ssl/${certId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_CANCEL] (context, certId) {
    return ApiService.post(`/app-api/ssl/${certId}/cancel`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_DELETE] (context, certId) {
    return ApiService.post(`/app-api/ssl/${certId}/delete`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_PROLONG] (context, params) {
    return ApiService.post(`/app-api/ssl/${params.certId}/prolong`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_REISSUE] (context, params) {
    return ApiService.post(`/app-api/ssl/${params.certId}/reissue`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_RESUME] (context, certId) {
    return ApiService.post(`/app-api/ssl/${certId}/resume`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_REVALIDATE] (context, certId) {
    return ApiService.post(`/app-api/ssl/${certId}/revalidate`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_TASK] (context, taskId) {
    return ApiService.get(`/app-api/ssl/tasks/${taskId}`)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_AUTO_PROLONG] (context, params) {
    return ApiService.put(`/app-api/ssl/${params.certId}/change-auto-prolong`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [SSL_CERTIFICATE_UPDATE] (context, params) {
    return ApiService.post(`/app-api/ssl/${params.certId}/add-san`, params.model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations,
}
