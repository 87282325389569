export const PROJECT_FEATURE_IS_ACTIVE = 'projectFeatureIsActive'
export const FINANCES_FEATURE_IS_ACTIVE = 'financesFeatureIsActive'

export const SIMPLEONE_GET_LINK_PORTAL = 'supportSimpleOneGetLink'
export const SIMPLEONE_GET_LINK_SUPPORT = 'supportSimpleOneGetLinkFull'
export const SIMPLEONE_GET_LINK_PORTAL_ESSENCE = 'supportSimpleOneGetLinkEssense'

export const VSTACK_SERVERS_IS_EXPIRED = 'vstackServersIsExpired'
export const VSTACK_NETWORKS_IS_EXPIRED = 'vstackNetworksIsExpired'

export const MOBILE_MENU_OPENED = 'mobileMenuOpened'
export const IS_FEEDBACK_FORM_ALLOWED = 'getFeedbackFormAllowed'

export const IS_API_CLI_ALLOWED = 'isApiCliAllowed'
export const IS_API_TERRAFORM_ALLOWED = 'isApiTerraformAllowed'

export const GET_PARTNER_API_LINK = 'getPartnerApiLink'

export const GET_TEST_PAID_AMOUNT = 'getTestPaidAmount'
