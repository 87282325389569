import { BONUS_FETCH } from '../actions.type'

import ApiService from '@/api'
import { BONUS_SET } from '../mutations.type'

const state = {
  data: null,
  expire: null,
}

// getters
const getters = {
}
// actions
const actions = {
  [BONUS_FETCH] (context, isForce = false) {
    if (!isForce && state.expire && state.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.data)
      })
    } else {
      return ApiService.get('/app-api/bonus')
        .then(({ data }) => {
          context.commit(BONUS_SET, data)
          return data
        })
    }
  },
}

// mutations
const mutations = {
  [BONUS_SET] (state, bonus) {
    state.data = bonus
    state.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_BONUS_EXPIRE)).getTime()
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
