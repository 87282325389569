<template>
  <div
    v-if="errors.length > 0"
    class="vue-validation-errors"
  >
    <div
      v-for="error in errors"
      :key="error"
      class="vue-validation-errors-item"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelState: { type: Object, default: null },
    name: { type: String, required: true },
  },
  computed: {
    errors () {
      if (!this.modelState || !this.name || !this.name.length) {
        return []
      }

      if (this.modelState[this.name] && this.modelState[this.name].length) {
        return this.modelState[this.name]
      }

      let fieldPath = this.name.split('.')
      let value = this.modelState
      let notFound = false

      fieldPath.forEach(x => {
        if (value[x]) {
          value = value[x]
        } else {
          notFound = true
        }
      })
      return notFound ? [] : value
    },
  },
}
</script>

<style lang="less" scoped>
.vue-validation-errors {
  width: 100%;
  color: var(--color-danger);

  &-item {
    width: 100%;
    font-size: 12px;
    color: var(--color-danger);
    letter-spacing: 0.48px;

    & + & {
      padding-top: 5px;
      margin-top: 5px;
      border-top: 1px solid var(--color-danger);
    }
  }
}
</style>
