import {
  SUPPORT_TICKETS_FETCH,
  SUPPORT_TICKET_FETCH,
  SUPPORT_TICKET_CREATE,
  SUPPORT_TICKET_REPLY,
  SUPPORT_TICKET_CLOSE,
  SUPPORT_TICKET_LIKE,
  SUPPORT_ALERTS_FETCH,
  SUPPORT_ALERT_FETCH,
  SUPPORT_ALERT_LIKE,
  SUPPORT_ALERT_REPLY,
  SUPPORT_TICKETS_SUM,
} from '../actions.type'

import { UNREAD_SUPPORT_SET } from '../mutations.type'
import ApiService from '@/api'

const state = {
  unreadSupport: 0,
}

// getters
const getters = {
  getCountMsg (state) {
    return state.unreadSupport
  },
}

// actions
const actions = {
  [SUPPORT_TICKETS_FETCH] () {
    return ApiService.get('/app-api/support/tickets')
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKET_CREATE] (context, model) {
    return ApiService.post('/app-api/support/tickets', model, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKET_FETCH] (context, ticketId) {
    return ApiService.get(`/app-api/support/tickets/${ticketId}`)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKET_REPLY] (context, params) {
    return ApiService.post(`/app-api/support/tickets/${params.ticketId}/comments`, params.model, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKET_CLOSE] (context, ticketId) {
    return ApiService.post(`/app-api/support/tickets/${ticketId}/close`)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKET_LIKE] (context, params) {
    return ApiService.post(`/app-api/support/tickets/${params.ticketId}/comments/${params.model.ResponseId}/like`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_ALERT_LIKE] (context, params) {
    return ApiService.post(`/app-api/support/alerts/${params.ticketId}/comments/${params.commentId}/like`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_ALERTS_FETCH] () {
    return ApiService.get('/app-api/support/alerts')
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_ALERT_FETCH] (context, ticketId) {
    return ApiService.get(`/app-api/support/alerts/${ticketId}`)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_ALERT_REPLY] (context, params) {
    return ApiService.post(`/app-api/support/alerts/${params.ticketId}/comments`, params.model, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_TICKETS_SUM] (context) {
    return ApiService.get(`/app-api/support/warning/sum`)
      .then(({ data }) => {
        context.commit(UNREAD_SUPPORT_SET, data)
      })
  },
}

// mutations
const mutations = {
  [UNREAD_SUPPORT_SET] (state, data) {
    state.unreadSupport = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
