import { HELP_DETAILS_FETCH } from '../actions.type'

import ApiService from '@/api'

const state = {
}

// getters
const getters = {
}
// actions
const actions = {
  [HELP_DETAILS_FETCH] (context, name) {
    return ApiService.get(`/app-api/help/details/${name}/json`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
