<template>
  <div class="vue-notifications">
    <transition-group name="vue-notifications-popup">
      <div
        v-for="item in notifications"
        :key="item.name"
        :class="['vue-notifications-item', item.type, { 'right-bar' : !$store.state.footerRightBar, 'dark' : isDarkThemeSelected }]"
      >
        <span
          class="vue-notifications-item-icon"
          :class="[item.type]"
          v-html="iconType(item.type)"
        />
        <div class="vue-notifications-item-name">
          {{ item.name }}
        </div>
        <button
          class="vue-notifications-item-close"
          @click="deleteItem(item)"
          v-html="ICON_CROSS"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { ICON_OK, ICON_CROSS, ICON_INFO_MONOSPACE } from '@/svg/icons'
import { eventBus, eventTypes } from '@/plugins/eventBus.js'
export default {
  name: 'BaseNotifications',
  data () {
    return {
      notifications: [],
      ICON_CROSS,
      ICON_OK,
      ICON_INFO_MONOSPACE,
    }
  },
  computed: {
    width () {
      let main = document.getElementsByClassName('layout__content main-content')[0]
      return main.offsetWidth
    },
    isDarkThemeSelected () {
      return this.$store.getters.getDarkThemeState
    },
  },
  mounted () {
    eventBus.$on(eventTypes.ADD, this.addNotice)
    eventBus.$on(eventTypes.ADD_MODEL_ERRORS, this.addModelErrors)
  },
  methods: {
    deleteItem (item) {
      let index = this.notifications.indexOf(item)
      if (index > -1) {
        this.notifications.splice(index, 1)
      }
    },
    addNotice (item) {
      let notice = this.notifications.find(n => n.name === item.name)
      if (!notice) {
        this.notifications.push(item)
        item.timer = setTimeout(() => {
          this.deleteItem(item)
        }, 4000)
      }
    },
    addModelErrors (modelState) {
      if (modelState) {
        for (let errorField in modelState) {
          modelState[errorField].forEach((error) => {
            this.addNotice({ name: error, type: 'error' })
          })
        }
      }
    },
    addConfirmation (name) {
      this.addNotice({ name: name, type: 'confirmation' })
    },
    iconType (type) {
      if (type === 'error') {
        return ICON_CROSS
      } else if (type === 'confirmation') {
        return ICON_OK
      } else {
        return ICON_INFO_MONOSPACE
      }
    },
  },
}

</script>

<style lang="less" scoped>
.vue-notifications {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 10051;
  width: 282px;

  &-popup {
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(300px);
      transition: all 0.5s ease;
    }

    &-leave-from,
    &-enter-to {
      transition: all 0.5s ease;
      opacity: 1;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    background-color: var(--color-background);
    border-radius: var(--border-radius);

    &.right-bar {
      right: 320px;
    }

    &-name {
      max-width: 186px;
      margin-right: 26px;
    }

    &-close {
      position: absolute;
      top: 12px;
      right: 15px;
      cursor: pointer;
      background-color: inherit;
      border: 0;

      :deep(svg) {
        width: 8px;
        height: 8px;

        & path {
          stroke: var(--menu-color-icon);
        }
      }

      &:hover {
        font-weight: 600;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 16px;
      border-radius: 50%;

      &.error {
        :deep(svg) {
          width: 8px;
          height: 8px;

          & path {
            stroke: #fff;
          }
        }

        background-color: #ff4236;
      }

      &.warning,
      &.notice {
        :deep(svg) {
          width: 5px;
          height: 10px;

          & path {
            fill: #fff;
            stroke: unset;
          }
        }

        background-color: #fdc201;
      }

      &.confirmation {
        :deep(svg) {
          & path {
            fill: #fff;
          }
        }

        background-color: #2d7446;
      }
    }

    &.error {
      border-bottom: 2px solid #ff4236;

      &.dark {
        border-bottom: 2px solid #ff4236;

        & .vue-notifications-item-icon {
          :deep(svg) {
            & path {
              stroke: #ff4236;
            }
          }
        }

        & .vue-notifications-item-icon {
          background-color: #ff423633;
        }
      }
    }

    &.warning,
    &.notice {
      border-bottom: 2px solid #fdc201;

      &.dark {
        border-bottom: 2px solid #f5b316;

        & .vue-notifications-item-icon {
          :deep(svg) {
            & path {
              fill: #f5b316;
              stroke: unset;
            }
          }
        }

        & .vue-notifications-item-icon {
          background-color: #f5b31633;
        }
      }
    }

    &.confirmation {
      border-bottom: 2px solid #32aa5c;
    }
  }
}

</style>
