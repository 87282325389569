const SS_BY = 'serverspace.by'
const SS_IO = 'serverspace.io'
const SS_US = 'serverspace.us'
const SS_RU = 'serverspace.ru'
const SS_IN = 'serverspace.in'
const SS_TR = 'serverspace.com.tr'
const SS_KZ = 'serverspace.kz'
const SS_CA = 'serverspace.ca'
const SS_BR = 'serverspace.com.br'
const SS_TEST = 'ss4test.com'
const DOM_RU = 'Дом.ru'
const OBIT = 'obit.ru'
const LINCORE = 'lincore.kz'
const YOTA = 'yotacloud.com'
const IAASHUB = 'iaashub.io'
const ITGLOBAL = 'itglobal'
const TELEDATA = 'Teledata'
const FLUIR = 'fluircloud.com'
const ZIQCLOUD = 'ziqcloud.com'
const NETOOZE = 'Netooze'
const ADLOOK = 'adlook.cloud'
const ONEOFFICE = '1office'
const KORUS = 'korusconsulting.ru'
const CLOUDTEK = 'cloudtek.kz'
const FALCON = 'falconcloud.ae'
const MIRAN = 'vc.miran.ru'
const EKACOD = 'ekacod.ru'
const GLOS = 'glos.online'
const UZUM = 'uzum'

export default {
  SS_BY,
  SS_IO,
  SS_US,
  SS_RU,
  SS_IN,
  SS_TR,
  SS_KZ,
  SS_CA,
  SS_BR,
  SS_TEST,
  DOM_RU,
  OBIT,
  LINCORE,
  YOTA,
  IAASHUB,
  ITGLOBAL,
  TELEDATA,
  FLUIR,
  ZIQCLOUD,
  NETOOZE,
  ADLOOK,
  ONEOFFICE,
  KORUS,
  CLOUDTEK,
  FALCON,
  MIRAN,
  EKACOD,
  GLOS,
  UZUM,
}
