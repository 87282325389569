<template>
  <Popup
    @close="$emit('close')"
  >
    <template #default>
      <div class="test-paid-popup">
        <i18n-t
          keypath="billing.shared.test-paid.popup.title"
          tag="div"
          class="title"
        >
          <template #bonus>
            <span>{{ formatCurrency(extendedTest.Bonus) }}</span>
          </template>
          <template #amount>
            <span>{{ formatCurrency(extendedTest.Amount) }}</span>
          </template>
        </i18n-t>
        <div class="test-paid-popup-description">
          {{ $t('billing.shared.test-paid.popup.description', { duration: $tc('billing.shared.test-paid.popup.day', extendedTest.DurationDays) }) }}
        </div>
      </div>
    </template>
    <template #controls>
      <BaseButton
        :reverse="true"
        @click="onClose"
      >
        {{ $t('billing.shared.test-paid.popup.btn-back') }}
      </BaseButton>
      <BaseButton
        class="confirm-button"
        :submit="submit"
        @click="activate"
      >
        <i18n-t
          keypath="billing.shared.test-paid.popup.btn-ok"
          tag="span"
        >
          <template #amount>
            <span>{{ formatCurrency(extendedTest.Amount) }}</span>
          </template>
        </i18n-t>
      </BaseButton>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/shared/Popup.vue'
import BaseButton from '@/components/base/BaseButton.vue'

import { BILLING_TEST_PAID } from '@/store/actions.type'
import { SET_TEST_PAID_AMOUNT, COMMON_MOBILE_MENU } from '@/store/mutations.type'
import formatCurrency from '@/lib/formatCurrency'

export default {
  components: {
    Popup,
    BaseButton,
  },
  props: {
    extendedTest: { type: Object, required: true },
  },
  emits: ['close'],
  data () {
    return {
      submit: false,
      modelState: null,
    }
  },
  methods: {
    activate () {
      if (!this.submit) {
        this.submit = true
        this.modelState = null
        this.$store.dispatch(BILLING_TEST_PAID)
          .then(() => {
            this.submit = false
            this.$emit('close')
            this.$metrics.allGoal('panel', 'billing', 'external_test')

            this.$store.commit(SET_TEST_PAID_AMOUNT, this.extendedTest.Amount)
            this.$store.commit(COMMON_MOBILE_MENU, false)
            this.$router.push({ name: 'billing-add-money', hash: '#TabIndividual' })
          })
          .catch((error) => {
            if (error.isServerValidationError) {
              this.modelState = error.error.response.data.ModelState
            }
            this.submit = false
          })
      }
    },
    onClose () {
      this.$emit('close')
      window.location.reload()
    },
    formatCurrency,
  },
}
</script>

<style lang="less" scoped>
.test-paid-popup-description,
.title {
  color: var(--color-base);
}

:deep(.vue-popup-close) {
  color: var(--color-base);
}
</style>