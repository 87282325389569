export const COMMON_APP_SETTINGS = 'commonAppSettings'
export const COMMON_DEFAULT_FEATURE = 'commonDefaultFeature'
export const SEND_FEEDBACK_FORM = 'sendFeedbackForm'

export const COMMON_NOTIFICATIONS_FETCH = 'commonNotificationsFetch'
export const COMMON_NOTIFICATION_CLOSE = 'commonNotificationCLose'
export const COMMON_FETCH_PROFILE = 'commonFetchProfile'

export const BONUS_FETCH = 'bonusFetch'

export const PROJECT_CREATE = 'projectCreate'
export const PROJECT_INFO_FETCH = 'projectInfoFetch'
export const PROJECT_DELETE = 'projectDelete'
export const PROJECT_NAME = 'projectName'
export const PROJECT_LOGO = 'projectLogo'
export const PROJECT_CUSTOM_LOGO_URL = 'projectCustomLogoUrl'
export const PROJECT_MEMBER_REMOVE = 'projectMemberRemove'
export const PROJECT_MEMBER_ROLE_CHANGE = 'projectMemberRoleChange'
export const PROJECT_MEMBER_INVITE = 'projectMemberInvite'
export const PROJECT_MEMBER_INVITE_RESEND = 'projectMemberInviteResend'
export const PROJECT_LEAVE = 'projectLeave'
export const PROJECT_ACCEPT = 'projectAccept'
export const PROJECT_DECLINE = 'projectDecline'
export const PROJECT_DARK_THEME_TOGGLE = 'projectDarkTheme'

export const DNS_FETCH_DOMAINS = 'dnsFetchDomains'
export const DNS_CREATE_DOMAIN = 'dnsCreateDomain'
export const DNS_DELETE_DOMAIN = 'dnsDeleteDomain'
export const DNS_FETCH_DOMAIN = 'dnsFetchDomain'
export const DNS_CREATE_RECORD = 'dnsCreateRecord'
export const DNS_EDIT_RECORD = 'dnsEditRecord'
export const DNS_DELETE_RECORD = 'dnsDeleteRecord'
export const DNS_TASK_DOMAIN = 'dnsTaskDomain'
export const DNS_TASK_RECORD = 'dnsTaskRecord'

export const VSTACK_SERVERS_FETCH = 'vstackServersFetch'
export const VSTACK_SERVERS_ITEM_FETCH = 'vstackServersItemFetch'
export const VSTACK_SERVER_CALCULATOR_FETCH = 'vstackServerCalculatorFetch'
export const VSTACK_SERVER_CREATE = 'vstackServerCreate'
export const VSTACK_SERVER_EDIT = 'vstackServerEdit'
export const VSTACK_SSH_CREATE = 'vstackSshCreate'
export const VSTACK_SERVER_DELETE = 'vstackServerDelete'
export const VSTACK_SERVER_FETCH = 'vstackServerFetch'
export const VSTACK_SERVER_PERFORMANCE = 'vstackServerPerformance'
export const VSTACK_SERVER_WEBCONSOLE_FETCH = 'vstackServerWebconsoleFetch'
export const VSTACK_SERVER_ORDER_FETCH = 'vstackServerOrderFetch'
export const VSTACK_SERVER_ORDER_CANCEL = 'vstackServerOrderCancelFetch'
export const VSTACK_TASK_FETCH = 'vstackServerTask'
export const VSTACK_SERVER_CONFIGURATION_CHANGE = 'vstackServerConfigurationChange'
export const VSTACK_SERVER_POWER_ACTION = 'vstackServerPowerAction'
export const VSTACK_SERVER_DISK_CREATE = 'vstackServerDiskCreate'
export const VSTACK_SERVER_DISK_EDIT = 'vstackServerDiskEdit'
export const VSTACK_SERVER_DISK_DELETE = 'vstackServerDiskDelete'
export const VSTACK_SERVER_SNAPSHOT_CREATE = 'vstackServerSnapshotCreate'
export const VSTACK_SERVER_SNAPSHOT_DELETE = 'vstackServerSnapshotDelete'
export const VSTACK_SERVER_SNAPSHOT_RESTORE = 'vstackServerSnapshotRestore'
export const VSTACK_SERVER_LICENSES_AVAILABALE_FETCH = 'vstackServerLicensesAvailableFetch'
export const VSTACK_SERVER_LICENSES_ADD = 'vstackServerLicensesAdd'
export const VSTACK_SERVER_NIC_EDIT = 'vstackServerNicEdit'
export const VSTACK_SERVER_NETWORKS_AVAILABALE_FETCH = 'vstackServerNetworksAvailableFetch'
export const VSTACK_SERVER_NETWORK_DISCONNECT = 'vstackServerNetworkDisconnect'
export const VSTACK_SERVER_BOOT_MEDIA_CHANGE = 'vstackServerBootMediaChange'
export const VSTACK_SERVER_TAG_ADD = 'vstackServerTagAdd'
export const VSTACK_SERVER_TAG_REMOVE = 'vstackServerTagRemove'
export const VSTACK_NETWORK_SERVER_ADD = 'vstackNetworkServerAdd'
export const VSTACK_NETWORK_SERVER_SHARED_ADD = 'vstackNetworkServerSharedAdd'
export const VSTACK_NETWORK_SERVER_DETELE = 'vstackNetworkServerDelete'
export const VSTACK_NETWORKS_FETCH = 'vstackNetworksFetch'
export const VSTACK_NETWORK_FETCH = 'vstackNetworkFetch'
export const VSTACK_NETWORK_EDIT_NAME_DESCRIPTION = 'vstackNetworkEditNameDescription'
export const VSTACK_NETWORK_DELETE = 'vstackNetworkDelete'
export const VSTACK_NETWORK_CALCULATOR_FETCH = 'vstackNetworkCalculatorFetch'
export const VSTACK_NETWORK_CREATE = 'vstackNetworkCreate'
export const VSTACK_NETWORK_CONNECT = 'vstackNetworkConnect'
export const VSTACK_NETWORK_TAG_ADD = 'vstackNetworkTagAdd'
export const VSTACK_NETWORK_TAG_REMOVE = 'vstackNetworkTagRemove'
export const VSTACK_EDGES_FETCH = 'vstackEdgesFetch'
export const VSTACK_EDGE_CALCULAOTR_FETCH = 'vstackEdgeCalculatorFetch'
export const VSTACK_EDGE_CREATE = 'vstackEdgeCreate'
export const VSTACK_EDGE_TAG_ADD = 'vstackEdgeTagAdd'
export const VSTACK_EDGE_TAG_REMOVE = 'vstackEdgeTagRemove'
export const VSTACK_EDGE_FETCH = 'vstackEdgeFetch'
export const VSTACK_EDGE_DELETE = 'vstackEdgeDelete'
export const VSTACK_EDGE_NAME = 'vstackEdgeName'
export const VSTACK_EDGE_BANDWIDTH = 'vstackEdgeBandwidth'
export const VSTACK_EDGE_FIREWALL_RULES = 'vstackEdgeFirewallRules'
export const VSTACK_EDGE_NAT_RULES = 'vstackEdgeNatRules'
export const VSTACK_EDGE_NETWORK_CONNECT = 'vstackEdgeNetworkConnect'
export const VSTACK_EDGE_NETWORK_DISCONNECT = 'vstackEdgeNetworkDisconnect'
export const VSTACK_EDGE_ACTIONS = 'vstackEdgeActions'

export const VSTACK_SERVER_GET_REASON = 'vstackDeleteReason'
export const VSTACK_SERVER_SEND_REASON = 'vstackSendReason'
export const VSTACK_GROUP_ADD = 'vstackGroupAdd'
export const VSTACK_GROUP_DETAIL = 'vstackGroupDetail'
export const VSTACK_GROUP_DELETE = 'vstackGroupDelete'

export const VSTACK_BACKUP_ACTIVATE = 'vstackBackupActivate'
export const VSTACK_BACKUP_RESTORE = 'vstackBackupRestore'
export const VSTACK_BACKUP_DISABLE = 'vstackBackupDisable'
export const VSTACK_BACKUP_RESTORE_POINT_ADD = 'vstackBackupRestorePointAdd'
export const VSTACK_BACKUP_RESTORE_POINT_REMOVE = 'vstackBackupRestorePointRemove'

export const ACCOUNT_USER_DELETE = 'accountUserDelete'
export const ACCOUNT_API_KEYS_FETCH = 'accountApiKeysFetch'
export const ACCOUNT_API_KEY_CREATE = 'accountApiKeyCreate'
export const ACCOUNT_API_KEY_DELETE = 'accountApiKeyDelete'
export const ACCOUNT_INFO_FETCH = 'accountInfoFetch'
export const ACCOUNT_INFO_EDIT = 'accountInfoEdit'
export const ACCOUNT_PHONE_EDIT = 'accountPhoneEdit'
export const ACCOUNT_PHONE_CODE_APPROVE = 'accountPhoneCodeApprove'
export const ACCOUNT_PHONE_CODE_RESEND = 'accountPhoneCodeResend'
export const ACCOUNT_PHONE_AUTH_ENABLE = 'accountPhoneAuthEnable'
export const ACCOUNT_SSH_FETCH = 'accountSshFetch'
export const ACCOUNT_SSH_CREATE = 'accountSshCreate'
export const ACCOUNT_SSH_DELETE = 'accountSshDelete'
export const ACCOUNT_CHANGE_PASSWORD = 'accountChangePassword'
export const ACCOUNT_APP_GENERATE_CODE = 'accountAppGenerateCode'
export const ACCOUNT_APP_AUTH_ENABLE = 'accountAppAuthEnable'
export const ACCOUNT_SECURITY_FETCH = 'accountSecurityFetch'
export const ACCOUNT_PROMOCODE_ACTIVATE = 'accountPromocodeActivate'
export const ACCOUNT_2FACTOR_DISABLE = 'account2factorDisable'
export const ACCOUNT_COMPANY_FETCH = 'accountCompanyFetch'
export const ACCOUNT_COMPANY_SAVE = 'accountCompanySave'
export const ACCOUNT_COMPANY_INN = 'accountCompanyInn'
export const ACCOUNT_COMPANY_AUTO_INVOICE = 'accountCompanyAutoInvoice'

export const BILLING_TEST_PAID = 'billingTestPaid'
export const BILLING_DETAIL_FETCH = 'billingDetailFetch'
export const BILLING_DETAIL_BY_MONTH_FETCH = 'billingDetailByMonthFetch'
export const BILLING_DETAIL_BY_MONTH_DAY_FETCH = 'billingDetailByMonthDayFetch'
export const BILLING_DETAIL_BY_MONTH_DAY_INSTANCE_FETCH = 'billingDetailByMonthDayInstanceFetch'
export const BILLING_DETAIL_INVOICES_FETCH = 'billingDetailInvoicesFetch'
export const BILLING_DETAIL_ACTS_FETCH = 'billingDetailActsFetch'
export const BILLING_ACT_FETCH = 'billingActFetch'
export const BILLING_ACT_DOWNLOAD = 'billingActDownload'
export const BILLING_ACT_PARCELBILL_DOWNLOAD = 'billingActParcelBillDownload'
export const BILLING_RECONSILIATION_REPORT_SETTINGS = 'billingReconsiliationReportSettings'
export const BILLING_RECONSILIATION_REPORT_FETCH = 'billingReconsiliationReportFetch'
export const BILLING_RECONSILIATION_REPORT_DOWNLOAD = 'billingReconsiliationReportDownload'
export const BILLING_INVOICE_FETCH = 'billingInvoiceFetch'
export const BILLING_INVOICE_DOWNLOAD = 'billingInvoiceDownload'
export const BILLING_PAYMENTS_FETCH = 'billingPaymentsFetch'
export const BILLING_ADD_PROMISED = 'billingAddPromised'
export const BILLING_PAYMENT_INVOICE_FETCH = 'billingPaymentInvoiceFetch'
export const BILLING_PAYMENT_INVOICE_SET = 'billingPaymentInvoiceSet'
export const BILLING_PAYMENT_INVOICE_CREATE = 'billingPaymentInvoiceCreate'
export const BILLING_PAYMENT_PAY_BEPAID = 'billingPaymentPayBepaid'
export const BILLING_PAYMENT_PAY_BEPAID_TOKEN = 'billingPaymentPayBepaidToken'
export const BILLING_PAYMENT_PAY_BEPAID_STATUS = 'billingPaymentPayBepaidStatus'
export const BILLING_PAYMENT_PAY_BEPAID_ERIP = 'billingPaymentPayBepaidErip'
export const BILLING_PAYMENT_PAY_BEPAID_ERIP_STATUS = 'billingPaymentPayBepaidEripStatus'
export const BILLING_PAYMENT_PAY_CLOUD_PAYMENT = 'billingPaymentPayCloudPayment'
export const BILLING_PAYMENT_PAY_CLOUD_PAYMENT_TOKEN = 'billingPaymentPayCloudPaymentToken'
export const BILLING_PAYMENT_PAY_CLOUD_PAYMENT_STATUS = 'billingPaymentPayCloudPaymentStatus'
export const BILLING_PAYMENT_PAY_STRIPE = 'billingPaymentPayStripe'
export const BILLING_PAYMENT_PAY_STRIPE_TOKEN = 'billingPaymentPayStripeToken'
export const BILLING_PAYMENT_PAY_STRIPE_STATUS = 'billingPaymentPayStripeStatus'
export const BILLING_PAYMENT_PAY_PAYPAL = 'billingPaymentPayPaypal'
export const BILLING_PAYMENT_PAY_PAYPAL_CARDS = 'billingPaymentPayPaypalCards'
export const BILLING_PAYMENT_PAY_PAYPAL_CAPTURE = 'billingPaymentPayPaypalStatus'
export const BILLING_PAYMENT_PAY_EXTERNAL = 'billingPaymentPayExternal'
export const BILLING_PAYMENT_PAY_ROBOKASSA = 'billingPaymentPayRobokassa'
export const BILLING_PAYMENT_PAY_ROBOKASSA_STATUS = 'billingPaymentPayRobokassaStatus'
export const BILLING_PAYMENT_AUTOPAY_BEPAID_SET = 'billingPaymentAutopaymentBepaidSet'
export const BILLING_PAYMENT_AUTOPAY_CLOUD_PAYMENT_SET = 'billingPaymentAutopaymentCloudPaymentdSet'
export const BILLING_PAYMENT_AUTOPAY_STRIPE_SET = 'billingPaymentAutopaymentStripeSet'
export const BILLING_PAYMENT_AUTOPAY_DISABLE = 'billingPaymentAutopaymentDisable'
export const BILLING_PAYMENT_AUTOPAY_EDIT = 'billingPaymentAutopaymentEdit'
export const BILLING_PAYMENT_CARD_DELETE = 'billingPaymentCardDelete'
export const BILLING_BONUS_TASKS = 'billingBonusTasks'
export const BILLING_BONUS_TASK_SEND = 'billingBonusTaskSend'
export const BILLING_TARIFFS_FETCH = 'billingTariffsFetch'
export const BILLING_GET_QRCODE_SBP = 'billingGetQrCodeSbp'
export const BILLING_GET_STATUS_SBP = 'billingGetStatusSbp'

export const BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT = 'billingPaymentPayTipTopPayment'
export const BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_TOKEN = 'billingPaymentPayTipTopPaymentToken'
export const BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_STATUS = 'billingPaymentPayTipTopPaymentStatus'
export const BILLING_PAYMENT_AUTOPAY_TIP_TOP_SET = 'billingPaymentAutoPayTipTopSet'

export const VCD_PUBLIC_LIST_FETCH = 'vcdPublicListFetch'
export const VCD_PRIVATE_LIST_FETCH = 'vcdPrivateListFetch'
export const VCD_CALCULATOR_FETCH = 'vcdCalculatorFetch'
export const VCD_VDC_CREATE = 'vcdVdcCreate'
export const VCD_PUBLIC_VDC_DELETE = 'vcdPublicVdcDelete'
export const VCD_PRIVATE_VDC_DELETE = 'vcdPrivateVdcDelete'
export const VCD_PUBLIC_VDC_EDIT = 'vcdPublicVdcEdit'
export const VCD_PRIVATE_VDC_EDIT = 'vcdPrivateVdcEdit'
export const VCD_PUBLIC_VDC_DETAIL = 'vcdPublicVdcDetail'
export const VCD_PRIVATE_VDC_DETAIL = 'vcdPrivateVdcDetail'
export const VCD_FINANCE = 'vcdFinance'
export const VCD_PUBLIC_USAGE = 'vcdPublicUsage'
export const VCD_PRIVATE_USAGE = 'vcdPrivateUsage'

export const MANAGED_LIST_FETCH = 'managedListFetch'
export const MANAGED_CALCULATOR_FETCH = 'managedCalculatorFetch'
export const MANAGED_CREATE = 'managedCreate'
export const MANAGED_CANCEL = 'managedCancel'
export const MANAGED_DETAIL_FETCH = 'managedDetailFetch'
export const MANAGED_DETAIL_AUDIT_FETCH = 'managedDetailAuditFetch'
export const MANAGED_SERVER_SUBSCRIBE_FETCH = 'managedServerSubscribeFetch'
export const MANAGED_SERVER_SUBSCRIBE_ADD = 'managedServerSubscribeAdd'

export const SUPPORT_TICKETS_FETCH = 'supportTicketsFetch'
export const SUPPORT_TICKET_CREATE = 'supportTicketCreate'
export const SUPPORT_TICKET_FETCH = 'supportTicketFetch'
export const SUPPORT_TICKET_REPLY = 'supportTicketReply'
export const SUPPORT_TICKET_CLOSE = 'supportTicketClose'
export const SUPPORT_TICKET_LIKE = 'supportTicketLike'
export const SUPPORT_ALERTS_FETCH = 'supportAlertsFetch'
export const SUPPORT_ALERT_FETCH = 'supportAlertFetch'
export const SUPPORT_ALERT_LIKE = 'supportAlertLike'
export const SUPPORT_ALERT_REPLY = 'supportAlertReply'
export const SUPPORT_TICKETS_SUM = 'supportTicketsSum'

export const SUPPORT_SIMPLEONE_GET_DATA = 'supportSimpleOneGetLink'

export const STORAGE_DETAIL_FETCH = 'storageDetailFetch'
export const STORAGE_TOKEN_FETCH = 'storageTokenFetch'
export const STORAGE_ACTIVATE = 'storageActivate'
export const STORAGE_DEACTIVATE = 'storageDeactivate'
export const STORAGE_TASK_FETCH = 'storageTaskFetch'

export const STORAGE_FOLDER_FETCH = 'storageFolderFetch'
export const STORAGE_FOLDER_CREATE = 'storageFolderCreate'
export const STORAGE_CONTAINER_CREATE = 'storageContainerCreate'
export const STORAGE_CONTAINER_METADATA_UPDATE = 'storageContainerMetadataUpdate'
export const STORAGE_CONTAINER_INFO = 'storageContainerInfo'
export const STORAGE_OBJECT_DELETE = 'storageObjectDelete'
export const STORAGE_OBJECTS_DELETE_BULK = 'storageObjectsDeleteBulk'
export const STORAGE_FILE_DOWNLOAD = 'storageFileDownload'
export const STORAGE_FILE_UPLOAD = 'storageFileUpload'
export const STORAGE_FILE_COPY = 'storageFileCopy'

export const TAGS_FETCH = 'tagsFetch'

export const VMWARE_SERVER_CALCULATOR_FETCH = 'vmwareServerCalculatorFetch'
export const VMWARE_SERVER_CREATE = 'vmwareServerCreate'
export const VMWARE_SERVERS_FETCH = 'vmwareServersFetch'
export const VMWARE_SERVER_PERFORMANCE = 'vmwareServerPerformance'
export const VMWARE_SERVER_DETAIL_FETCH = 'vmwareServerDetailFetch'
export const VMWARE_SERVER_CONSOLE_TOKEN_FETCH = 'vmwareServerConsoleTokenFetch'
export const VMWARE_SERVER_DELETE = 'vmwareServerDelete'
export const VMWARE_TASK_FETCH = 'vmwareTaskFetch'
export const VMWARE_SERVER_POWER_ACTION = 'vmwareServerPowerAction'
export const VMWARE_SERVER_REBUILD_GET_IMAGES = 'vmwareServerRebuildGetImages'
export const VMWARE_SERVER_REBUILD = 'vmwareServerRebuild'
export const VMWARE_SERVER_COPY = 'vmwareServerCopy'
export const VMWARE_SERVER_CONFIGURATION_CHANGE = 'vmwareServerConfigurationChange'
export const VMWARE_SERVER_RENAME = 'vmwareServerRename'
export const VMWARE_SERVER_DISK_CREATE = 'vmwareServerDiskCreate'
export const VMWARE_SERVER_DISK_EDIT = 'vmwareServerDiskEdit'
export const VMWARE_SERVER_DISK_DELETE = 'vmwareServerDiskDelete'
export const VMWARE_SERVER_LICENSES_AVAILABALE_FETCH = 'vmwareServerLicensesAvailableFetch'
export const VMWARE_SERVER_LICENSES_ADD = 'vmwareServerLicensesAdd'
export const VMWARE_SERVER_SNAPSHOT_CREATE = 'vmwareServerSnapshotCreate'
export const VMWARE_SERVER_SNAPSHOT_DELETE = 'vmwareServerSnapshotDelete'
export const VMWARE_SERVER_SNAPSHOT_RESTORE = 'vmwareServerSnapshotRestore'
export const VMWARE_SERVER_BACKUP_SETTINGS = 'vmwareServerBackupSettings'
export const VMWARE_SERVER_BACKUP_RESTORE = 'vmwareServerBackupRestore'
export const VMWARE_SERVER_BACKUP_DISABLE = 'vmwareServerBackupDisable'
export const VMWARE_SERVER_NETWORKS_AVAILABALE_FETCH = 'vmwareServerNetworksAvailableFetch'
export const VMWARE_SERVER_NIC_EDIT = 'vmwareServerNicEdit'
export const VMWARE_SERVER_NETWORK_CLIENT_ADD = 'vmwareServerNetworkClientAdd'
export const VMWARE_SERVER_NETWORK_SHARED_ADD = 'vmwareServerNetworkSharedAdd'
export const VMWARE_SERVER_NETWORK_DISCONNECT = 'vmwareServerNetworkDisconnect'
export const VMWARE_SERVER_FIREWALL_RULE_VALIDATE = 'vmwareServerFirewallRuleValidate'
export const VMWARE_SERVER_FIREWALL_UPDATE = 'vmwareServerFirewallUpdate'
export const VMWARE_SERVER_TAG_ADD = 'vmwareServerTagAdd'
export const VMWARE_SERVER_TAG_REMOVE = 'vmwareServerTagRemove'
export const VMWARE_NETWORKS_FETCH = 'vmwareNetworksFetch'
export const VMWARE_NETWORK_CALCULATOR_FETCH = 'vmwareNetworkCalculatorFetch'
export const VMWARE_NETWORK_CREATE = 'vmwareNetworkCreate'
export const VMWARE_NETWORK_FETCH = 'vmwareNetworkFetch'
export const VMWARE_NETWORK_CONNECT = 'vmwareNetworkConnect'
export const VMWARE_NETWORK_NAT_CREATE = 'vmwareNetworkNatCreate'
export const VMWARE_NETWORK_NAT_EDIT = 'vmwareNetworkNatEdit'
export const VMWARE_NETWORK_NAT_DELETE = 'vmwareNetworkNatDelete'
export const VMWARE_NETWORK_VPN_CREATE = 'vmwareNetworkVpnCreate'
export const VMWARE_NETWORK_VPN_EDIT = 'vmwareNetworkVpnEdit'
export const VMWARE_NETWORK_VPN_DELETE = 'vmwareNetworkVpnDelete'
export const VMWARE_NETWORK_VPN_STATE = 'vmwareNetworkVpnState'
export const VMWARE_NETWORK_FIREWALL_RULE_VALIDATE = 'vmwareNetworkFirewallRuleValidate'
export const VMWARE_NETWORK_FIREWALL_UPDATE = 'vmwareNetworkFirewallUpdate'
export const VMWARE_NETWORK_EDIT = 'vmwareNetworkEdit'
export const VMWARE_NETWORK_DELETE = 'vmwareNetworkDelete'
export const VMWARE_NETWORK_TAG_ADD = 'vmwareNetworkTagAdd'
export const VMWARE_NETWORK_TAG_REMOVE = 'vmwareNetworkTagRemove'
export const VMWARE_TEMPLATES_FETCH = 'vmwareTemplatesFetch'
export const VMWARE_TEMPLATES_SERVERS = 'vmwareTemplatesServers'
export const VMWARE_TEMPLATE_CREATE = 'vmwareTemplateCreate'
export const VMWARE_TEMPLATE_DELETE = 'vmwareTemplateDelete'
export const VMWARE_TEMPLATE_DETAIL = 'vmwareTemplateDetail'
export const VMWARE_SERVER_GET_REASON = 'vmwareDeleteReason'
export const VMWARE_SERVER_SEND_REASON = 'vmwareSendReason'

export const DOCUMENT_FLOW_GET = 'documentFlowGet'
export const DOCUMENT_FLOW_UPDATE = 'documentFlowUpdate'
export const DOCUMENT_FLOW_UPDATE_BR = 'documentFlowUpdateBr'
export const DOCUMENT_GET_VAT_RATE = 'documentFlowGetVatRate'

export const REFERRAL_DETAIL_FETCH = 'referralDetailFetch'
export const REFERRAL_INVITE = 'referralInvite'
export const REFERRAL_TRANSFER_MONEY = 'referralTransferMoney'

export const K8S_CLUSTER_LIST_FETCH = 'k8sClusterListFetch'
export const K8S_CALCULATOR_FETCH = 'k8sCalculatorFetch'
export const K8S_CLUSTER_CREATE = 'k8sClusterCreate'
export const K8S_CLUSTER_DELETE = 'k8sClusterDelete'
export const K8S_CLUSTER_DETAIL_FETCH = 'k8sClusterDetailFetch'
export const K8S_CLUSTER_UPGRADE = 'k8sClusterUpgrade'
export const K8S_CLUSTER_GROUP_EDIT = 'k8sClusterGroupEdit'
export const K8S_CLUSTER_GROUP_ADD = 'k8sClusterGroupAdd'
export const K8S_CLUSTER_GROUP_DELETE = 'k8sClusterGroupDelete'
export const K8S_CLUSTER_GROUP_INGRESS_ENABLE = 'k8sClusterGroupIngressEnable'
export const K8S_CLUSTER_TAG_ADD = 'k8sClusterTagAdd'
export const K8S_CLUSTER_TAG_REMOVE = 'k8sClusterTagRemove'
export const K8S_TASK_FETCH = 'k8sTaskFetch'
export const K8S_ORDER_DETAIL_FETCH = 'k8sOrderDetailFetch'
export const K8S_ORDER_CANCEL = 'k8sOrderCancel'
export const K8S_DASHBOARD_ACTIVATE = 'k8sDashboardActivate'

export const SSL_CALCULATOR_FETCH = 'sslCalculatorFetch'
export const SSL_CSR_DECODE = 'sslCsrDecode'
export const SSL_CSR_GENERATE = 'sslCsrGenerate'
export const SSL_EMAIL_APPROVAL = 'sslEmailApproval'
export const SSL_CHECK_DOMAIN = 'sslCheckDomain'
export const SSL_CREATE = 'sslCreate'
export const SSL_LIST_FETCH = 'sslListFetch'
export const SSL_CERTIFICATE_FETCH = 'sslCertificateFetch'
export const SSL_CERTIFICATE_CANCEL = 'sslCertificateCancel'
export const SSL_CERTIFICATE_DELETE = 'sslCertificateDelete'
export const SSL_CERTIFICATE_PROLONG = 'sslCertificateProlong'
export const SSL_CERTIFICATE_REISSUE = 'sslCertificateReissue'
export const SSL_CERTIFICATE_RESUME = 'sslCertificateResume'
export const SSL_CERTIFICATE_REVALIDATE = 'sslCertificateRevalidate'
export const SSL_CERTIFICATE_TASK = 'sslCertificateTask'
export const SSL_CERTIFICATE_AUTO_PROLONG = 'sslCertificateAutoProlong'
export const SSL_CERTIFICATE_UPDATE = 'sslCertificateUpdate'

export const FEEDBACK_SUBMIT = 'feedbackSubmit'

export const HELP_DETAILS_FETCH = 'helpDetailsFetch'

export const CONFIGURED_CALCULATOR_FETCH = 'configuredServerCalculatorFetch'
export const CONFIGURED_SERVER_CREATE = 'configuredServerCreate'
export const CONFIGURED_SERVERS_FETCH = 'configuredServersFetch'
export const CONFIGURED_SERVER_FETCH = 'configuredServerFetch'
export const CONFIGURED_SERVER_ORDER_FETCH = 'configuredServerOrderFetch'
export const CONFIGURED_TASK_FETCH = 'configuredServerTaskFetch'
export const CONFIGURED_SERVER_DELETE = 'configuredServerDelete'
export const CONFIGURED_SERVER_ORDER_CANCEL = 'configuredServerOrderCancelFetch'
export const CONFIGURED_USER_ADD = 'configuredUserAdd'
export const CONFIGURED_USER_DELETE = 'configuredUserDelete'
export const CONFIGURED_SERVER_REBOOT = 'configuredServerReboot'
export const CONFIGURED_SERVER_RESET = 'configuredServerReset'
export const CONFIGURED_BANDWIDTH_EDIT = 'configuredBandwidthEdit'

export const CDN_DOMAIN_ENABLE = 'cdnDomainEnable'
export const CDN_DOMAIN_DETAIL = 'cdnDomainDetail'
export const CDN_DOMAIN_DISABLE = 'cdnDomainDisable'
export const CDN_DELETE = 'cdnDelete'
export const CDN_ORDER_CANCEL = 'cdnOrderCancel'
export const CDN_ORDER_DETAIL = 'cdnOrderDetail'
export const CDN_ORDER_SAGA = 'cdnOrderTask'
export const CDN_DOMAINS_LIST = 'cdnDomainsList'
export const CDN_DOMAIN_CONNECT_DELEGATION = 'cdnDomainConnectDelegation'
export const CDN_DOMAIN_CONNECT_CNAME = 'cdnDomainConnectCname'
export const CDN_ORDER_CALCULATOR = 'cdnDomainsAvailable'
export const CDN_ORDER_ADD_RECORD = 'cdnDomainAddRecord'
export const CDN_ORDER_DELETE_RECORD = 'cdnDomainDeleteRecord'
export const CDN_ORDER_CONTINUE = 'cdnOrderContinue'
export const CDN_GET_REMOTE_ORIGINS = 'cdnGetRemoteOrigins'
export const CDN_GET_REMOTE_CERTIFICATES = 'cdnGetRemoteCertificates'
export const CDN_PARSE_CERTIFICATE_FILES = 'cdnGetOwnCertificateInfo'
export const CDN_PERSONAL_SETTINGS_ACTIVATE = 'cdnPersonalSettingsActivate'
export const CDN_PERSONAL_SETTINGS_MODIFY = 'cdnPersonalSettingsModificate'
export const CDN_ADD_LOCATION = 'cdnAddLocation'
export const CDN_DELETE_LOCATION = 'cdnDeleteLocation'
export const CDN_UPDATE_LOCATION = 'cdnUpdateLocation'
export const CDN_HEADER_VALIDATE = 'cdnHeaderValidate'
export const CDN_CORS_VALIDATE = 'cdnCorsValidate'

export const ITGLOBAL_CRM_LEAD = 'itglobalCrmLead'
export const CDN_DOMAIN_DELEGATED_UPDATE = 'cdnDomainDelegatedUpdate'
export const CDN_DOMAIN_CNAME_UPDATE = 'cdnDomainCnameUpdate'
export const CDN_DOMAIN_PURGE_CACHE = 'cdnDomainPurgeCache'
export const CDN_DOMAIN_REFILL_CACHE = 'cdnDomainRefillCache'
export const CDN_DOMAIN_CERTIFICATE_UPDATE = 'cdnDomainCertificateUpdate'
export const CDN_S3_ORDER_CALCULATOR = 'cdnS3OrderCalculator'
export const CDN_S3_CREATE_ORDER = 'cdnS3CreateOrder'
export const CDN_S3_ORDER_DETAIL = 'cdnS3OrderDetail'
export const CDN_S3_LIST = 'cdnS3List'
export const CDN_S3_ORDER_CANCEL = 'cdnS3OrderCancel'
export const CDN_S3_CREATE_OTHER_PROVIDER = 'cdnS3CreateOtherProvider'
export const CDN_S3_DETAIL = 'cdnS3Detail'
export const CDN_S3_RESOURCE_DELETE = 'cdnS3ResourceDelete'
export const CDN_S3_PARSE_ENDPOINT = 'cdnS3ParseEndpoint'
export const CDN_S3_BUCKET_ENABLE = 'cdnS3BucketEnable'
export const CDN_S3_BUCKET_DISABLE = 'cdnS3BucketDisable'
export const CDN_S3_BUCKET_UPDATE = 'cdnS3BucketUpdate'
export const CDN_S3_BUCKET_CREATE = 'cdnS3BucketCreate'
export const CDN_S3_BUCKET_DELETE = 'cdnS3BucketDelete'
export const CDN_S3_RESOURCE_EDIT = 'cdnS3ResourceEdit'
export const CDN_S3_PERSONAL_SETTINGS_ACTIVATE = 'cdnS3PersonalSettingsActivate'
export const CDN_S3_PERSONAL_SETTINGS_MODIFY = 'cdnS3PersonalSettingsModify'
export const CDN_S3_PURGE_CACHE = 'cdnS3PurgeCache'

export const CDN_VOD_CALCULATOR = 'cdnVodCalculator'
export const CDN_VOD_CONNECT = 'cdnVodConnect'
export const CDN_VOD_LIST = 'cdnVodList'
export const CDN_VOD_DELETE = 'cdnVodDelete'
export const CDN_VOD_ENABLE = 'cdnVodEnable'
export const CDN_VOD_DISABLE = 'cdnVodDisable'
export const CDN_VOD_DETAIL = 'cdnVodDetail'
export const CDN_VOD_RENAME = 'cdnVodRename'

export const GPT_ADD_API_KEY = 'gptAddApiKey'
export const GPT_GET_API_KEYS = 'gptGetApiKeys'
export const GPT_GET_MODELS = 'gptGetModels'
export const GPT_RENAME_API_KEY = 'gptRenameApiKey'
export const GPT_DELETE_API_KEY = 'gptDeleteApiKey'
export const GPT_GET_TASK = 'gptGetTask'
export const GPT_GET_MODEL = 'gptGetModel'
