import {
  VCD_PUBLIC_LIST_FETCH,
  VCD_PRIVATE_LIST_FETCH,
  VCD_PUBLIC_VDC_DETAIL,
  VCD_PRIVATE_VDC_DETAIL,
  VCD_CALCULATOR_FETCH,
  VCD_VDC_CREATE,
  VCD_PUBLIC_VDC_DELETE,
  VCD_PRIVATE_VDC_DELETE,
  VCD_PUBLIC_VDC_EDIT,
  VCD_PRIVATE_VDC_EDIT,
  VCD_FINANCE,
  VCD_PUBLIC_USAGE,
  VCD_PRIVATE_USAGE,
} from '../actions.type'

import ApiService from '@/api'

const state = {

}

// getters
const getters = {

}
// actions
const actions = {
  [VCD_PUBLIC_LIST_FETCH] () {
    return ApiService.get('/app-api/vcd')
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PRIVATE_LIST_FETCH] () {
    return ApiService.get('/app-api/vcd/private')
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PUBLIC_VDC_DETAIL] (context, params) {
    return ApiService.get(`/app-api/vcd/${params.vdcId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PRIVATE_VDC_DETAIL] (context, params) {
    return ApiService.get(`/app-api/vcd/private/${params.vdcId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_CALCULATOR_FETCH] () {
    return ApiService.get('/app-api/vcd/create')
      .then(({ data }) => {
        return data
      })
  },
  [VCD_VDC_CREATE] (context, model) {
    return ApiService.post('/app-api/vcd/create', model)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PUBLIC_VDC_DELETE] (context, model) {
    return ApiService.post(`/app-api/vcd/delete`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PRIVATE_VDC_DELETE] (context, model) {
    return ApiService.post(`/app-api/vcd/private/delete`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PUBLIC_VDC_EDIT] (context, model) {
    return ApiService.post(`/app-api/vcd/change-quota`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PRIVATE_VDC_EDIT] (context, model) {
    return ApiService.post(`/app-api/vcd/private/change-quota`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_FINANCE] (context, params) {
    return ApiService.get(`/app-api/vcd/${params.vdcId}/finance`)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PUBLIC_USAGE] (context, params) {
    return ApiService.get(`/app-api/vcd/${params.vdcId}/usage`)
      .then(({ data }) => {
        return data
      })
  },
  [VCD_PRIVATE_USAGE] (context, params) {
    return ApiService.get(`/app-api/vcd/private/${params.vdcId}/usage`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations,
}
