export const toFixedNum = (num, precision) => {
  return parseFloat(num.toFixed(precision))
}

export const ceilDeci = (num, precision) => {
  let exp = precision * -1
  let value = toFixedNum(num, 2).toString().split('e')
  value = Math.ceil(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  value = value.toString().split('e')
  return parseFloat(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}
