import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'
import i18n from '@/i18n.js'
import metrics from '@/metrics'
import features from '@/feature/features'
import { BONUS_FETCH, COMMON_APP_SETTINGS } from '@/store/actions.type'
import feature from '@/feature'

const $t = (key) => i18n.global.t(key)

const AppDefault = () => import(/* webpackChunkName: "defaultview" */ '@/components/Default.vue')

const ConfiguredCreate = () => import(/* webpackChunkName: "configured" */ '@/components/features/configured-servers/pages/create/Create.vue')
const ConfiguredList = () => import(/* webpackChunkName: "configured" */ '@/components/features/configured-servers/pages/list/List.vue')
const ConfiguredDetail = () => import(/* webpackChunkName: "configured" */ '@/components/features/configured-servers/pages/detail/Detail.vue')
const ConfiguredOrder = () => import(/* webpackChunkName: "configured" */ '@/components/features/configured-servers/pages/order/Order.vue')

const VstackCreate = () => import(/* webpackChunkName: "vstack" */ '@/components/features/vstack/pages/create/Create.vue')
const VstackList = () => import(/* webpackChunkName: "vstack" */ '@/components/features/vstack/pages/list/VstackList.vue')
const VstackOrder = () => import(/* webpackChunkName: "vstack" */ '@/components/features/vstack/pages/order/Order.vue')
const VstackDetail = () => import(/* webpackChunkName: "vstack" */ '@/components/features/vstack/pages/detail/Detail.vue')
const VstackConsole = () => import(/* webpackChunkName: "vstack" */ '@/components/features/vstack/pages/webConsole/WebConsole.vue')

const VstackNetworksCreate = () => import(/* webpackChunkName: "vstack-network" */ '@/components/features/vstacknetworks/pages/create/Create.vue')
const VstackNetworksList = () => import(/* webpackChunkName: "vstack-network" */ '@/components/features/vstacknetworks/pages/list/List.vue')
const VstackNetworksDetail = () => import(/* webpackChunkName: "vstack-network" */ '@/components/features/vstacknetworks/pages/detail/Detail.vue')

const VstackEdgeList = () => import(/* webpackChunkName: "vstack-edge" */ '@/components/features/vstack-edge/pages/list/List.vue')
const VstackEdgeCreate = () => import(/* webpackChunkName: "vstack-edge" */ '@/components/features/vstack-edge/pages/create/Create.vue')
const VstackEdgeDetail = () => import(/* webpackChunkName: "vstack-edge" */ '@/components/features/vstack-edge/pages/detail/Detail.vue')

const DnsDomains = () => import(/* webpackChunkName: "dns" */ '@/components/features/dns/pages/domains/Domains.vue')
const DnsRecords = () => import(/* webpackChunkName: "dns" */ '@/components/features/dns/pages/records/Records.vue')

const UnocOrderCreate = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/OrderCreatePage/index.vue')
const UnocOrderBusy = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/OrderBusyPage/index.vue')
const UnocOrderNeedMoney = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/OrderNeedMoneyPage/index.vue')
const UnocInfobases = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/InfobasesPage/index.vue')
const UnocInfobaseDetails = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/InfobaseDetailsPage/index.vue')
const UnocEmployees = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/EmployeesPage/index.vue')
const UnocEmployeeDetails = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/EmployeeDetailsPage/index.vue')
const UnocTerminalServer = () => import(/* webpackChunkName: "unoc" */ '@/components/features/unoc/pages/TerminalServerPage/index.vue')

const VcdList = () => import(/* webpackChunkName: "vcd" */ '@/components/features/vcd/pages/List/List.vue')
const VcdCreate = () => import(/* webpackChunkName: "vcd" */ '@/components/features/vcd/pages/Create/Create.vue')
const VcdDetail = () => import(/* webpackChunkName: "vcd" */ '@/components/features/vcd/pages/Detail/Detail.vue')

const ManagedList = () => import(/* webpackChunkName: "managed" */ '@/components/features/managed/pages/List/List.vue')
const ManagedCreate = () => import(/* webpackChunkName: "managed" */ '@/components/features/managed/pages/Create/Create.vue')
const ManagedDetail = () => import(/* webpackChunkName: "managed" */ '@/components/features/managed/pages/Detail/Detail.vue')

const AccountManage = () => import(/* webpackChunkName: "account" */ '@/components/features/account/pages/manage/Manage.vue')

const Automatization = () => import(/* webpackChunkName: "account" */ '@/components/features/account/pages/automatization/Automatization.vue')

const StorageDetail = () => import(/* webpackChunkName: "storage" */ '@/components/features/storage/pages/detail/Detail.vue')

const VmwareServerCreate = () => import(/* webpackChunkName: "vmware" */ '@/components/features/vmware/pages/create/Create.vue')
const VmwareServerDetail = () => import(/* webpackChunkName: "vmware" */ '@/components/features/vmware/pages/detail/Detail.vue')
const VmwareServerConsole = () => import(/* webpackChunkName: "vmware" */ '@/components/features/vmware/pages/console/WebConsole.vue')
const VmwareServersList = () => import(/* webpackChunkName: "vmware" */ '@/components/features/vmware/pages/list/List.vue')
const VmwareNetworkCreate = () => import(/* webpackChunkName: "vmware-network" */ '@/components/features/vmware-networks/pages/create/Create.vue')
const VmwareNetworkDetail = () => import(/* webpackChunkName: "vmware-network" */ '@/components/features/vmware-networks/pages/detail/Detail.vue')
const VmwareNetworksList = () => import(/* webpackChunkName: "vmware-network" */ '@/components/features/vmware-networks/pages/list/List.vue')
const VmwareTemplateCreate = () => import(/* webpackChunkName: "vmware-template" */ '@/components/features/vmware-templates/pages/create/Create.vue')
const VmwareTemplatesList = () => import(/* webpackChunkName: "vmware-template" */ '@/components/features/vmware-templates/pages/list/List.vue')
const VmwareTemplateDetail = () => import(/* webpackChunkName: "vmware-template" */ '@/components/features/vmware-templates/pages/detail/Detail.vue')

const DocumentFlow = () => import(/* webpackChunkName: "document-flow" */ '@/components/features/document-flow/shared/DocumentFlow.vue')

const K8sCreate = () => import(/* webpackChunkName: "k8s" */ '@/components/features/k8s/pages/create/Create.vue')
const K8sList = () => import(/* webpackChunkName: "k8s" */ '@/components/features/k8s/pages/list/List.vue')
const K8sDetail = () => import(/* webpackChunkName: "k8s" */ '@/components/features/k8s/pages/details/Details.vue')
const K8sOrder = () => import(/* webpackChunkName: "k8s" */ '@/components/features/k8s/pages/order/Order.vue')

const ErrorNotFound = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/NotFound/NotFound.vue')
const ErrorBadRequest = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/BadRequest/BadRequest.vue')
const ErrorContentLengthTooLarge = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/ContentLengthTooLarge/ContentLengthTooLarge.vue')
const ErrorForbidden = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/Forbidden/Forbidden.vue')
const ErrorInternalServerError = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/InternalServerError/InternalServerError.vue')
const ErrorMaintenance = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/Maintenance/Maintenance.vue')
const ErrorManagedServerPolicyViolation = () => import(/* webpackChunkName: "errors" */ '@/components/features/errors/pages/ManagedServerPolicyViolation/ManagedServerPolicyViolation.vue')

const ReferralDetail = () => import(/* webpackChunkName: "referral" */ '@/components/features/referral/pages/detail/Detail.vue')

const SupportTicketsList = () => import(/* webpackChunkName: "support" */ '@/components/features/support/pages/list/List.vue')
const SupportTicketCreate = () => import(/* webpackChunkName: "support" */ '@/components/features/support/pages/create/Create.vue')
const SupportTicketDetail = () => import(/* webpackChunkName: "support" */ '@/components/features/support/pages/detail/Detail.vue')
const SupportTicketAlertDetail = () => import(/* webpackChunkName: "support" */ '@/components/features/support/pages/detail/DetailAlert.vue')

const SslCreate = () => import(/* webpackChunkName: "ssl" */ '@/components/features/ssl/pages/create/Create.vue')
const SslList = () => import(/* webpackChunkName: "ssl" */ '@/components/features/ssl/pages/list/List.vue')
const SslDetail = () => import(/* webpackChunkName: "ssl" */ '@/components/features/ssl/pages/detail/Detail.vue')

const FeedbackAdd = () => import(/* webpackChunkName: "feedback" */ '@/components/features/feedback/pages/add/Add.vue')
const FeedbackComplete = () => import(/* webpackChunkName: "feedback" */ '@/components/features/feedback/pages/complete/Complete.vue')

const HelpDetails = () => import(/* webpackChunkName: "vstack" */ '@/components/features/help/pages/details/Details.vue')

const BillingDetail = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/detail/Detail.vue')
const BillingInfo = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/info/Info.vue')
const BillingInvoice = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/invoice/Invoice.vue')
const BillingAct = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/act/Act.vue')
const BillingReconsiliationReport = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/reconsiliation-report/ReconsiliationReport.vue')
const BillingAddMoney = () => import(/* webpackChunkName: "billing" */ '@/components/features/billing/pages/add-money/AddMoney.vue')

const OneClickAppDetail = () => import(/* webpackChunkName: "one-click" */ '@/components/features/vstack/pages/detail/DetailOneClickApp.vue')

const CdnList = () => import(/* webpackChunkName: "cdn" */ '@/components/features/cdn/pages/domains/Domains.vue')
const CdnCreate = () => import(/* webpackChunkName: "cdn" */ '@/components/features/cdn/pages/create/Create.vue')
const CdnDetail = () => import(/* webpackChunkName: "cdn" */ '@/components/features/cdn/pages/detail/Detail.vue')
const CdnOrder = () => import(/* webpackChunkName: "cdn" */ '@/components/features/cdn/pages/order/Order.vue')

const HalloweenAI = () => import(/* webpackChunkName: "halloween-ai" */ '@/components/features/halloween-ai/Empty.vue')
const NewYearPromo = () => import(/* webpackChunkName: "halloween-ai" */ '@/components/features/promo-ny/Empty.vue')

const CdnS3Create = () => import(/* webpackChunkName: "cdn-s3" */ '@/components/features/cdn-s3/pages/create/Create.vue')
const CdnS3List = () => import(/* webpackChunkName: "cdn-s3" */ '@/components/features/cdn-s3/pages/list/List.vue')
const CdnS3Detail = () => import(/* webpackChunkName: "cdn-s3" */ '@/components/features/cdn-s3/pages/detail/Detail.vue')
const CdnS3Order = () => import(/* webpackChunkName: "cdn-s3" */ '@/components/features/cdn-s3/pages/order/Order.vue')

const CdnVodList = () => import(/* webpackChunkName: "cdn-vod" */ '@/components/features/cdn-vod/pages/list/List.vue')
const CdnVodCreate = () => import(/* webpackChunkName: "cdn-vod" */ '@/components/features/cdn-vod/pages/create/Create.vue')
const CdnVodDetail = () => import(/* webpackChunkName: "cdn-vod" */ '@/components/features/cdn-vod/pages/detail/Detail.vue')

const GptList = () => import(/* webpackChunkName: "gpt" */ '@/components/features/gpt/pages/list/List.vue')
const GptModel = () => import(/* webpackChunkName: "gpt" */ '@/components/features/gpt/pages/detail/Model.vue')

// only for itglobal partner features
const VmwarePrivateCloudList = () => import(/* webpackChunkName: "vmware-private-cloud" */ '@/components/features/itglobal-only/vmware-private-cloud/pages/list/List.vue')
const VmwarePrivateCloudCreate = () => import(/* webpackChunkName: "vmware-private-cloud" */ '@/components/features/itglobal-only/vmware-private-cloud/pages/create/Create.vue')
const VmwarePrivateCloudDetail = () => import(/* webpackChunkName: "vmware-private-cloud" */ '@/components/features/itglobal-only/vmware-private-cloud/pages/detail/Detail.vue')
const VdiCloudList = () => import(/* webpackChunkName: "vdi-cloud" */ '@/components/features/itglobal-only/vdi-cloud/pages/list/List.vue')
const VdiCloudCreate = () => import(/* webpackChunkName: "vdi-cloud" */ '@/components/features/itglobal-only/vdi-cloud/pages/create/Create.vue')
const S3StorageList = () => import(/* webpackChunkName: "s3-storage" */ '@/components/features/itglobal-only/s3-storage/pages/list/List.vue')
const S3StorageCreate = () => import(/* webpackChunkName: "s3-storage" */ '@/components/features/itglobal-only/s3-storage/pages/create/Create.vue')
const ManagedItList = () => import(/* webpackChunkName: "managed-it" */ '@/components/features/itglobal-only/managed-it/pages/list/List.vue')
const ManagedItCreate = () => import(/* webpackChunkName: "managed-it" */ '@/components/features/itglobal-only/managed-it/pages/create/Create.vue')
const VmwareDisasterRecoveryList = () => import(/* webpackChunkName: "vmware-disaster-recovery" */ '@/components/features/itglobal-only/vmware-disaster-recovery/pages/list/List.vue')
const VmwareDisasterRecoveryCreate = () => import(/* webpackChunkName: "vmware-disaster-recovery" */ '@/components/features/itglobal-only/vmware-disaster-recovery/pages/create/Create.vue')
const VeeamCloudConnectList = () => import(/* webpackChunkName: "veeam" */ '@/components/features/itglobal-only/veeam-cloud/pages/list/List.vue')
const VeeamCloudConnectCreate = () => import(/* webpackChunkName: "veeam" */ '@/components/features/itglobal-only/veeam-cloud/pages/create/Create.vue')
const DdosProtectList = () => import(/* webpackChunkName: "ddos" */ '@/components/features/itglobal-only/ddos-protect/pages/list/List.vue')
const DdosProtectCreate = () => import(/* webpackChunkName: "ddos" */ '@/components/features/itglobal-only/ddos-protect/pages/create/Create.vue')
const SecurityList = () => import(/* webpackChunkName: "security" */ '@/components/features/itglobal-only/security/pages/list/List.vue')
const SecurityCreate = () => import(/* webpackChunkName: "security" */ '@/components/features/itglobal-only/security/pages/create/Create.vue')
const AICloudList = () => import(/* webpackChunkName: "ai-cloud" */ '@/components/features/itglobal-only/ai-cloud/pages/list/List.vue')
const AICloudCreate = () => import(/* webpackChunkName: "ai-cloud" */ '@/components/features/itglobal-only/ai-cloud/pages/create/Create.vue')
const Vdi3DCloudList = () => import(/* webpackChunkName: "3d-vdi-cloud" */ '@/components/features/itglobal-only/3d-vdi-cloud/pages/list/List.vue')
const Vdi3DCloudCreate = () => import(/* webpackChunkName: "3d-vdi-cloud" */ '@/components/features/itglobal-only/3d-vdi-cloud/pages/create/Create.vue')
const SOCList = () => import(/* webpackChunkName: "soc" */ '@/components/features/itglobal-only/soc/pages/list/List.vue')
const CommunigateProCloudList = () => import(/* webpackChunkName: "communigate-pro-cloud" */ '@/components/features/itglobal-only/communigate-pro-cloud/pages/list/List.vue')
const CommunigateProCloudCreate = () => import(/* webpackChunkName: "communigate-pro-cloud" */ '@/components/features/itglobal-only/communigate-pro-cloud/pages/create/Create.vue')
const ExchangeCloudList = () => import(/* webpackChunkName: "exchange-cloud" */ '@/components/features/itglobal-only/exchange-cloud/pages/list/List.vue')
const ExchangeCloudCreate = () => import(/* webpackChunkName: "exchange-cloud" */ '@/components/features/itglobal-only/exchange-cloud/pages/create/Create.vue')

// createApp.use(createRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    component: AppDefault,
  },
  {
    path: '/gpt',
    name: 'gpt-list',
    component: GptList,
    meta: {
      title: 'GPT',
      name: 'GPT',
    },
  },
  {
    path: '/gpt/:modelId',
    name: 'gpt-model',
    component: GptModel,
    props: (route) => ({ modelId: parseInt(route.params.modelId) }),
    meta: {
      title: 'gpt.title.detail',
    }
  },
  {
    path: '/exchange-cloud/list',
    name: 'exchange-cloud-list',
    component: ExchangeCloudList,
    meta: {
      title: 'Exchange Cloud',
      name: 'Exchange Cloud',
    },
  },
  {
    path: '/exchange-cloud/create',
    name: 'exchange-cloud-create',
    component: ExchangeCloudCreate,
    meta: {
      title: 'Exchange Cloud',
      name: 'Exchange Cloud',
    },
  },
  {
    path: '/communigate-pro-cloud/list',
    name: 'communigate-pro-cloud-list',
    component: CommunigateProCloudList,
    meta: {
      title: 'CommuniGate Pro Cloud',
      name: 'CommuniGate Pro Cloud',
    },
  },
  {
    path: '/communigate-pro-cloud/create',
    name: 'communigate-pro-cloud-create',
    component: CommunigateProCloudCreate,
    meta: {
      title: 'CommuniGate Pro Cloud',
      name: 'CommuniGate Pro Cloud',
    },
  },
  {
    path: '/halloween-ai/index',
    name: 'halloween-ai',
    component: HalloweenAI,
    meta: {
      title: 'halloween-ai.title.index',
    },
  },
  {
    path: '/new-year-promo/index',
    name: 'new-year-promo',
    component: NewYearPromo,
    meta: {
      titleNy: 'layout.menu.features.new-year-promo-ny',
      titleChristmas: 'layout.menu.features.new-year-promo-christmas',
      name: 'meta-name',
    },
  },
  {
    path: '/vmware-private/list',
    name: 'vmware-private-list',
    component: VmwarePrivateCloudList,
    meta: {
      title: 'VMware Private Cloud',
      name: 'VMware Private Cloud',
    },
  },
  {
    path: '/vmware-private/create',
    name: 'vmware-private-create',
    component: VmwarePrivateCloudCreate,
    meta: {
      title: 'VMware Private Cloud',
      name: 'VMware Private Cloud',
    },
  },
  {
    path: '/vmware-private/:vdcId',
    name: 'vmware-private-detail',
    component: VmwarePrivateCloudDetail,
    props: (route) => ({ vdcId: route.params.vdcId }),
    meta: {
      title: 'VMware Private Cloud',
      name: 'VMware Private Cloud',
    },
  },
  {
    path: '/3d-vdi-cloud/list',
    name: '3d-vdi-cloud-list',
    component: Vdi3DCloudList,
    meta: {
      title: '3D VDI Cloud',
      name: '3D VDI Cloud',
    },
  },
  {
    path: '/3d-vdi-cloud/create',
    name: '3d-vdi-cloud-create',
    component: Vdi3DCloudCreate,
    meta: {
      title: '3D VDI Cloud',
      name: '3D VDI Cloud',
    },
  },
  {
    path: '/vdi-cloud/list',
    name: 'vdi-cloud-list',
    component: VdiCloudList,
    meta: {
      title: 'VDI Cloud',
      name: 'VDI Cloud',
    },
  },
  {
    path: '/vdi-cloud/create',
    name: 'vdi-cloud-create',
    component: VdiCloudCreate,
    meta: {
      title: 'VDI Cloud',
      name: 'VDI Cloud',
    },
  },
  {
    path: '/ai-cloud/list',
    name: 'ai-cloud-list',
    component: AICloudList,
    meta: {
      title: 'AI Cloud',
      name: 'AI Cloud',
    },
  },
  {
    path: '/ai-cloud/create',
    name: 'ai-cloud-create',
    component: AICloudCreate,
    meta: {
      title: 'AI Cloud',
      name: 'AI Cloud',
    },
  },
  {
    path: '/s3-storage/list',
    name: 's3-storage-list',
    component: S3StorageList,
    meta: {
      title: 'S3 Storage',
      name: 'S3 Storage',
    },
  },
  {
    path: '/s3-storage/create',
    name: 's3-storage-create',
    component: S3StorageCreate,
    meta: {
      title: 'S3 Storage',
      name: 'S3 Storage',
    },
  },
  {
    path: '/managed-it/list',
    name: 'managed-it-list',
    component: ManagedItList,
    meta: {
      title: 'Managed IT',
      name: 'Managed IT',
    },
  },
  {
    path: '/managed-it/create',
    name: 'managed-it-create',
    component: ManagedItCreate,
    meta: {
      title: 'Managed IT',
      name: 'Managed IT',
    },
  },
  {
    path: '/vmware-disaster-recovery/list',
    name: 'vmware-disaster-recovery-list',
    component: VmwareDisasterRecoveryList,
    meta: {
      title: 'VMware Disaster Recovery',
      name: 'VMware Disaster Recovery',
    },
  },
  {
    path: '/vmware-disaster-recovery/create',
    name: 'vmware-disaster-recovery-create',
    component: VmwareDisasterRecoveryCreate,
    meta: {
      title: 'VMware Disaster Recovery',
      name: 'VMware Disaster Recovery',
    },
  },
  {
    path: '/veeam-cloud/list',
    name: 'veeam-cloud-list',
    component: VeeamCloudConnectList,
    meta: {
      title: 'Veeam Cloud Connect',
      name: 'Veeam Cloud Connect',
    },
  },
  {
    path: '/veeam-cloud/create',
    name: 'veeam-cloud-create',
    component: VeeamCloudConnectCreate,
    meta: {
      title: 'Veeam Cloud Connect',
      name: 'Veeam Cloud Connect',
    },
  },
  {
    path: '/ddos-protect/list',
    name: 'ddos-protect-list',
    component: DdosProtectList,
    meta: {
      title: 'ddos-protect.title.index',
    },
  },
  {
    path: '/ddos-protect/create',
    name: 'ddos-protect-create',
    component: DdosProtectCreate,
    meta: {
      title: 'ddos-protect.title.index',
    },
  },
  {
    path: '/soc/list',
    name: 'soc-list',
    component: SOCList,
    meta: {
      title: 'ddos-protect.title.index',
    },
  },
  {
    path: '/security/list',
    name: 'security-list',
    component: SecurityList,
    meta: {
      title: 'Security',
      name: 'Security',
    },
  },
  {
    path: '/security/create',
    name: 'security-create',
    component: SecurityCreate,
    meta: {
      title: 'Security',
      name: 'Security',
    },
  },
  {
    path: '/cdn-s3/create',
    name: 'cdn-s3-create',
    component: CdnS3Create,
    meta: {
      title: 'cdn-s3.title.create',
      feature: [features.CdnS3],
    },
  },
  {
    path: '/cdn-s3/list',
    name: 'cdn-s3-list',
    component: CdnS3List,
    meta: {
      title: 'cdn-s3.title.list',
      feature: [features.CdnS3],
    },
  },
  {
    path: '/cdn-s3/domains/:resourceId',
    name: 'cdn-s3-detail',
    component: CdnS3Detail,
    props: (route) => ({ resourceId: parseInt(route.params.resourceId), bucketId: parseInt(route.query.bucketId) }),
    meta: {
      title: 'cdn-s3.title.detail',
      feature: [features.CdnS3],
    },
  },
  {
    path: '/cdn-s3/orders/:orderId',
    name: 'cdn-s3-order',
    component: CdnS3Order,
    props: (route) => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: 'cdn-s3.title.order',
      feature: [features.CdnS3],
    },
  },
  {
    path: '/cdn/domains',
    name: 'cdn-domains',
    component: CdnList,
    meta: {
      title: 'cdn.title.index',
      feature: [features.Cdn],
      isRoot: true,
    },
  },
  {
    path: '/cdn/create',
    name: 'cdn-create',
    component: CdnCreate,
    meta: {
      title: 'cdn.title.create',
      feature: [features.Cdn],
      sitemap: [
        'cdn-domains',
      ],
    },
  },
  {
    path: '/cdn/domains/:domainId',
    name: 'cdn-detail',
    props: (route) => ({ domainId: parseInt(route.params.domainId) }),
    component: CdnDetail,
    meta: {
      title: 'cdn.title.detail',
      feature: [features.Cdn],
      sitemap: [
        'cdn-domains',
      ],
    },
  },
  {
    path: '/cdn/orders/:orderId',
    name: 'cdn-order',
    component: CdnOrder,
    props: (route) => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: 'cdn.title.order',
      feature: [features.Cdn],
      sitemap: [
        'cdn-domains',
      ],
    },
  },
  {
    path: '/cdn-vod/list',
    name: 'cdn-vod-list',
    component: CdnVodList,
    meta: {
      title: 'cdn-vod.title.create',
      feature: [features.CdnVod],
    },
  },
  {
    path: '/cdn-vod/create',
    name: 'cdn-vod-create',
    component: CdnVodCreate,
    meta: {
      title: 'cdn-vod.title.create',
      feature: [features.CdnVod],
    },
  },
  {
    path: '/cdn-vod/list/:vodId',
    name: 'cdn-vod-detail',
    props: (route) => ({ vodId: parseInt(route.params.vodId) }),
    component: CdnVodDetail,
    meta: {
      title: 'cdn-vod.title.create',
      feature: [features.CdnVod],
    },
  },
  {
    path: '/configured-servers/list',
    name: 'configured-list',
    component: ConfiguredList,
    meta: {
      title: 'configured-servers.title.index',
      feature: [features.ConfiguredServers],
      isRoot: true,
    },
  },
  {
    path: '/configured-servers/create',
    name: 'configured-create',
    component: ConfiguredCreate,
    meta: {
      title: 'configured-servers.title.create',
      feature: [features.ConfiguredServers],
      sitemap: [
        'configured-list',
      ],
    },
  },
  {
    path: '/configured-servers/:serverId',
    name: 'configured-detail',
    component: ConfiguredDetail,
    props: (route) => ({ serverId: parseInt(route.params.serverId) }),
    meta: {
      title: 'configured-servers.title.detail',
      feature: [features.ConfiguredServers],
      sitemap: [
        'configured-list',
      ],
    },
  },
  {
    path: '/configured-servers/orders/:orderId',
    name: 'configured-order',
    component: ConfiguredOrder,
    props: (route) => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: 'configured-servers.title.order',
      feature: [features.ConfiguredServers],
      sitemap: [
        'configured-list',
      ],
    },
  },
  {
    path: '/vstack/servers',
    name: 'vstack-index',
    redirect: { name: 'vstack-list' },
    meta: {
      title: 'vstack.title.index',
      feature: [features.Vstack],
    },
  },
  {
    path: '/vstack/servers/create',
    name: 'vstack-create',
    component: VstackCreate,
    meta: {
      title: 'vstack.title.create',
      feature: [features.Vstack],
      sitemap: [
        'vstack-index',
      ],
    },
  },
  {
    path: '/vstack/servers/list',
    name: 'vstack-list',
    component: VstackList,
    meta: {
      title: 'vstack.title.list',
      feature: [features.Vstack],
      isRoot: true,
      sitemap: [
        'vstack-index',
      ],
    },
  },
  {
    path: '/vstack/servers/order/:orderId',
    name: 'vstack-order',
    component: VstackOrder,
    props: (route) => ({ orderId: parseInt(route.params.orderId) }),
    meta: {
      title: 'vstack.title.order-detail',
      feature: [features.Vstack],
      sitemap: [
        'vstack-index',
      ],
    },
  },
  {
    path: '/vstack/servers/:serverId',
    name: 'vstack-server',
    component: VstackDetail,
    props: (route) => ({ serverId: route.params.serverId }),
    meta: {
      title: 'vstack.title.server-detail',
      feature: [features.Vstack],
      sitemap: [
        'vstack-index',
      ],
    },
  },
  {
    path: '/vstack/servers/:serverId/console',
    name: 'vstack-server-console',
    component: VstackConsole,
    props: (route) => ({ serverId: route.params.serverId }),
    meta: {
      title: 'vstack.title.console',
      feature: [features.Vstack],
      sitemap: [
        'vstack-index',
        'vstack-server',
      ],
    },
  },
  {
    path: '/vstack/networks',
    name: 'vstacknetworks-index',
    redirect: { name: 'vstacknetworks-list' },
    meta: {
      title: 'vstacknetworks.title.index',
      feature: [features.Vstack],
    },
  },
  {
    path: '/vstack/networks/create',
    name: 'vstacknetworks-create',
    component: VstackNetworksCreate,
    meta: {
      title: 'vstacknetworks.title.create',
      feature: [features.Vstack],
      sitemap: [
        'vstacknetworks-index',
      ],
    },
  },
  {
    path: '/vstack/networks/list',
    name: 'vstacknetworks-list',
    component: VstackNetworksList,
    meta: {
      title: 'vstacknetworks.title.list',
      feature: [features.Vstack],
      sitemap: [
        'vstacknetworks-index',
      ],
    },
  },
  {
    path: '/vstack/networks/:networkId',
    name: 'vstacknetworks-detail',
    component: VstackNetworksDetail,
    props: (route) => ({ networkId: route.params.networkId }),
    meta: {
      title: 'vstacknetworks.title.detail',
      feature: [features.Vstack],
      sitemap: [
        'vstacknetworks-index',
      ],
    },
  },
  {
    path: '/vstack/edges/list',
    name: 'vstack-edge-list',
    component: VstackEdgeList,
    meta: {
      title: 'vstack-edge.title.list',
      feature: [features.Vstack],
    },
  },
  {
    path: '/vstack/edges/create',
    name: 'vstack-edge-create',
    component: VstackEdgeCreate,
    meta: {
      title: 'vstack-edge.title.create',
      feature: [features.Vstack],
      sitemap: [
        'vstack-edge-list',
      ],
    },
  },
  {
    path: '/vstack/edges/:edgeId',
    name: 'vstack-edge-detail',
    component: VstackEdgeDetail,
    props: (route) => ({ edgeId: route.params.edgeId }),
    meta: {
      title: 'vstack-edge.title.detail',
      feature: [features.Vstack],
      sitemap: [
        'vstack-edge-list',
      ],
    },
  },
  {
    path: '/dns',
    name: 'dns-index',
    redirect: { name: 'dns-domains' },
    meta: {
      title: 'dns.title.index',
      name: $t('dns.sitemap.index'),
      feature: [features.DNS],
    },
  },
  {
    path: '/dns/domains/list',
    name: 'dns-domains',
    component: DnsDomains,
    meta: {
      title: 'dns.title.domains',
      feature: [features.DNS],
      isRoot: true,
      sitemap: [
        'dns-index',
      ],
    },
  },
  {
    path: '/dns/domains/:id/records/list',
    name: 'dns-records',
    component: DnsRecords,
    props: (route) => ({ id: route.params.id }),
    meta: {
      title: 'dns.title.records',
      feature: [features.DNS],
      sitemap: [
        'dns-index',
        'dns-domains',
      ],
    },
  },
  {
    path: '/1c/infobases',
    name: 'unoc-index',
    redirect: { name: 'unoc-infobases' },
    meta: {
      title: 'u2.title.index',
      feature: [features.UnocV2],
    },
  },
  {
    path: '/1c/order',
    name: 'unoc-createbase',
    component: UnocOrderCreate,
    meta: {
      title: 'u2.title.createbase',
      feature: [features.UnocV2],
      sitemap: [
        'unoc-index',
      ],
    },
  },
  {
    path: '/1c/infobases/list',
    name: 'unoc-infobases',
    component: UnocInfobases,
    meta: {
      title: 'u2.title.infobases',
      feature: [features.UnocV2],
      isRoot: true,
    },
  },
  {
    path: '/1c/infobases/:id',
    name: 'unoc-infobase-details',
    component: UnocInfobaseDetails,
    props: (route) => ({ id: route.params.id }),
    meta: {
      title: 'u2.title.infobase-details',
      feature: [features.UnocV2],
      sitemap: [
        'unoc-index',
      ],
    },
  },
  {
    path: '/1c/order/:id',
    name: 'unoc-order-busy',
    component: UnocOrderBusy,
    props: (route) => ({ id: route.params.id }),
    meta: {
      title: 'u2.title.order-busy',
      feature: [features.UnocV2],
      sitemap: [
        'unoc-index',
      ],
    },
  },
  {
    path: '/1c/order/:id',
    name: 'unoc-order-needmoney',
    component: UnocOrderNeedMoney,
    props: (route) => ({ id: route.params.id }),
    meta: {
      title: 'u2.title.order-needmoney',
      feature: [features.UnocV2],
      sitemap: [
        'unoc-index',
      ],
    },
  },
  {
    path: '/1c/employees',
    name: 'unoc-employees-index',
    redirect: { name: 'unoc-employees' },
    meta: {
      title: 'u2.title.employees',
      feature: [features.UnocV2],
    },
  },
  {
    path: '/1c/employees/list',
    name: 'unoc-employees',
    component: UnocEmployees,
    meta: {
      title: 'u2.title.employees',
      feature: [features.UnocV2],
    },
  },
  {
    path: '/1c/employees/:id',
    name: 'unoc-employee-detail',
    component: UnocEmployeeDetails,
    props: (route) => ({ id: route.params.id }),
    meta: {
      title: 'u2.title.employee-detail',
      feature: [features.UnocV2],
      sitemap: [
        'unoc-employees',
      ],
    },
  },
  {
    path: '/1c/terminal-server',
    name: 'unoc-terminal-server',
    component: UnocTerminalServer,
    meta: {
      title: 'u2.title.terminal-server',
      feature: [features.UnocV2],
    },
  },
  {
    path: '/vcd',
    name: 'vcd-list',
    component: VcdList,
    meta: {
      title: 'vcd.title.list',
      feature: [features.CloudDirector],
      isRoot: true,
    },
  },
  {
    path: '/vcd/create',
    name: 'vcd-create',
    component: VcdCreate,
    meta: {
      title: 'vcd.title.create',
      feature: [features.CloudDirector],
      sitemap: [
        'vcd-list',
      ],
    },
  },
  {
    path: '/vcd/:vdcId',
    name: 'vcd-detail',
    component: VcdDetail,
    props: (route) => ({ vdcId: route.params.vdcId }),
    meta: {
      title: 'vcd.title.detail',
      feature: [features.CloudDirector],
      sitemap: [
        'vcd-list',
      ],
    },
  },
  {
    path: '/managed-server-subscriptions',
    name: 'managed-list',
    component: ManagedList,
    meta: {
      title: 'managed.title.list',
      feature: [features.ManagedServers],
      isRoot: true,
    },
  },
  {
    path: '/managed-server-subscriptions/create',
    name: 'managed-create',
    component: ManagedCreate,
    props: (route) => ({ serverId: route.query.serverId, type: route.query.type }),
    meta: {
      title: 'managed.title.create',
      feature: [features.ManagedServers],
      sitemap: [
        'managed-list',
      ],
    },
  },
  {
    path: '/managed-server-subscriptions/:subscriptionId',
    name: 'managed-detail',
    component: ManagedDetail,
    props: (route) => ({ subscriptionId: parseInt(route.params.subscriptionId) }),
    meta: {
      title: 'managed.title.detail',
      feature: [features.ManagedServers],
      sitemap: [
        'managed-list',
      ],
    },
  },
  {
    path: '/support',
    name: 'tickets-list',
    component: SupportTicketsList,
    meta: {
      title: 'support.title.list',
      feature: [features.Support],
      isRoot: true,
    },
  },
  {
    path: '/support/create-request',
    name: 'ticket-create',
    component: SupportTicketCreate,
    meta: {
      title: 'support.title.create',
      feature: [features.Support],
      sitemap: [
        'tickets-list',
      ],
    },
  },
  {
    path: '/support/request/:ticketId',
    name: 'ticket-detail',
    component: SupportTicketDetail,
    props: (route) => ({ ticketId: parseInt(route.params.ticketId) }),
    meta: {
      title: 'support.title.detail',
      feature: [features.Support],
      sitemap: [
        'tickets-list',
      ],
    },
  },
  {
    path: '/support/alerts/:ticketId',
    name: 'ticket-alert-detail',
    component: SupportTicketAlertDetail,
    props: (route) => ({ ticketId: parseInt(route.params.ticketId) }),
    meta: {
      title: 'support.title.alert-detail',
      feature: [features.Support],
      sitemap: [
        'tickets-list',
      ],
    },
  },
  {
    path: '/account/manage',
    name: 'account-manage',
    component: AccountManage,
    meta: {
      title: 'account.title.manage',
    },
  },
  {
    path: '/automation',
    name: 'automation',
    component: Automatization,
    meta: {
      title: 'account.title.automatization',
    },
  },
  {
    path: '/storage',
    name: 'storage-index',
    redirect: { name: 'storage-detail' },
    meta: {
      title: 'storage.title.index',
      feature: [features.Storage],
    },
  },
  {
    path: '/storage/detail',
    name: 'storage-detail',
    component: StorageDetail,
    props: (route) => ({ path: route.query.path }),
    meta: {
      title: 'storage.title.detail',
      feature: [features.Storage],
      isRoot: true,
    },
  },
  {
    path: '/servers/create',
    name: 'vmware-server-create',
    component: VmwareServerCreate,
    props: (route) => ({
      imageId: parseInt(route.query.imageId),
      cpu: parseInt(route.query.cpu),
      ramGb: parseInt(route.query.ramGb),
      sizeGb: parseInt(route.query.sizeGb),
      locationName: route.query.locationName,
      os: route.query.os,
    }),
    meta: {
      title: 'vmware.title.create',
      feature: [features.SIWithImage],
      sitemap: [
        'vmware-servers-list',
      ],
    },
  },
  {
    path: '/servers',
    name: 'vmware-servers-list',
    component: VmwareServersList,
    meta: {
      title: 'vmware.title.list',
      feature: [features.SIWithImage],
      isRoot: true,
    },
    props: (route) => ({ query: route.query.query ? route.query.query.toString() : '' }),
  },
  {
    path: '/servers/:serverId',
    name: 'vmware-server-detail',
    component: VmwareServerDetail,
    props: (route) => ({ serverId: parseInt(route.params.serverId) }),
    meta: {
      title: 'vmware.title.detail',
      feature: [features.SIWithImage],
      sitemap: [
        'vmware-servers-list',
      ],
    },
  },
  {
    path: '/servers/:serverId/web-console',
    name: 'vmware-server-console',
    component: VmwareServerConsole,
    props: (route) => ({ serverId: parseInt(route.params.serverId) }),
    meta: {
      title: 'vmware.title.console',
      feature: [features.SIWithImage],
      sitemap: [
        'vmware-servers-list',
        'vmware-server-detail',
      ],
    },
  },
  {
    path: '/networks/create',
    name: 'vmware-network-create',
    component: VmwareNetworkCreate,
    props: (route) => ({
      locationName: route.query.locationName,
      type: route.query.type,
    }),
    meta: {
      title: 'vmware-networks.title.create',
      feature: [features.PublicNetwork, features.PrivateNetwork, features.RoutedNetwork],
      sitemap: [
        'vmware-networks-list',
      ],
    },
  },
  {
    path: '/networks',
    name: 'vmware-networks-list',
    component: VmwareNetworksList,
    meta: {
      title: 'vmware-networks.title.list',
      feature: [features.PublicNetwork, features.PrivateNetwork, features.RoutedNetwork],
    },
  },
  {
    path: '/networks/:networkId',
    name: 'vmware-network-detail',
    component: VmwareNetworkDetail,
    props: (route) => ({ networkId: parseInt(route.params.networkId) }),
    meta: {
      title: 'vmware-networks.title.detail',
      feature: [features.PublicNetwork, features.PrivateNetwork, features.RoutedNetwork],
      sitemap: [
        'vmware-networks-list',
      ],
    },
  },
  {
    path: '/networks/:networkType/:networkId',
    name: 'vmware-network-type-detail',
    component: VmwareNetworkDetail,
    props: (route) => ({ networkId: parseInt(route.params.networkId), networkType: route.params.networkType }),
    meta: {
      title: 'vmware-networks.title.detail',
      feature: [features.PublicNetwork, features.PrivateNetwork, features.RoutedNetwork],
      sitemap: [
        'vmware-networks-list',
      ],
    },
  },
  {
    path: '/server-templates/create',
    name: 'vmware-template-create',
    component: VmwareTemplateCreate,
    meta: {
      title: 'vmware-templates.title.create',
      feature: [features.SIWithImage],
      sitemap: [
        'vmware-templates-list',
      ],
    },
  },
  {
    path: '/server-templates',
    name: 'vmware-templates-list',
    component: VmwareTemplatesList,
    meta: {
      title: 'vmware-templates.title.list',
      feature: [features.SIWithImage],
    },
  },
  {
    path: '/server-templates/:templateId',
    name: 'vmware-template-detail',
    component: VmwareTemplateDetail,
    props: (route) => ({ templateId: parseInt(route.params.templateId) }),
    meta: {
      title: 'vmware-templates.title.detail',
      feature: [features.SIWithImage],
      sitemap: [
        'vmware-templates-list',
      ],
    },
  },
  {
    path: '/documentflow',
    name: 'document-flow',
    component: DocumentFlow,
    meta: {
      title: 'document-flow.title.default',
      feature: [features.DocumentFlow],
    },
  },
  {
    path: '/kubernetes/clusters',
    name: 'k8s-list',
    component: K8sList,
    meta: {
      title: 'k8s.title.list',
      feature: [features.Kubernetes],
      isRoot: true,
    },
  },
  {
    path: '/kubernetes/clusters/create',
    name: 'k8s-create',
    component: K8sCreate,
    meta: {
      title: 'k8s.title.create',
      feature: [features.Kubernetes],
      sitemap: [
        'k8s-list',
      ],
    },
  },
  {
    path: '/kubernetes/clusters/orders/:orderId',
    name: 'k8s-cluster-order',
    props: (route) => ({ orderId: route.params.orderId }),
    component: K8sOrder,
    meta: {
      title: 'k8s.title.order',
      feature: [features.Kubernetes],
      sitemap: [
        'k8s-list',
      ],
    },
  },
  {
    path: '/kubernetes/clusters/:clusterId',
    name: 'k8s-cluster-detail',
    props: (route) => ({ clusterId: route.params.clusterId }),
    component: K8sDetail,
    meta: {
      title: 'k8s.title.detail',
      feature: [features.Kubernetes],
      sitemap: [
        'k8s-list',
      ],
    },
  },
  {
    path: '/referral',
    name: 'referral',
    component: ReferralDetail,
    meta: {
      title: 'referral.title.detail',
      feature: [features.Referral],
      isRoot: true,
    },
  },
  {
    path: '/errors/500',
    name: 'error-500',
    component: ErrorInternalServerError,
    meta: {
      title: 'errors.title.500',
      name: '500',
    },
  },
  {
    path: '/errors/503',
    name: 'error-503',
    component: ErrorMaintenance,
    meta: {
      title: 'errors.title.503',
      name: '503',
    },
  },
  {
    path: '/errors/managed-server-policy-violation',
    name: 'error-mspv',
    component: ErrorManagedServerPolicyViolation,
    meta: {
      title: 'errors.title.mspv',
      name: 'managed-server-policy-violation',
    },
  },
  {
    path: '/errors/content-length-too-large',
    name: 'error-cltl',
    component: ErrorContentLengthTooLarge,
    meta: {
      title: 'errors.title.cltl',
      name: 'content-length-too-large',
    },
  },
  {
    path: '/errors/403',
    name: 'error-403',
    component: ErrorForbidden,
    meta: {
      title: 'errors.title.403',
      name: '403',
    },
  },
  {
    path: '/errors/400',
    name: 'error-400',
    component: ErrorBadRequest,
    meta: {
      title: 'errors.title.400',
      name: '400',
    },
  },
  {
    path: '/feedback',
    name: 'feedback-add',
    component: FeedbackAdd,
    meta: {
      title: 'feedback.title.add',
      name: $t('feedback.sitemap.add'),
    },
  },
  {
    path: '/feedback/sent',
    name: 'feedback-complete',
    component: FeedbackComplete,
    meta: {
      title: 'feedback.title.complete',
      name: $t('feedback.sitemap.complete'),
    },
  },
  {
    path: '/help/Details/:name',
    name: 'help-details',
    props: (route) => ({ name: route.params.name }),
    component: HelpDetails,
    meta: {
      title: 'help.title.details',
      name: $t('help.sitemap.details'),
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingDetail,
    meta: {
      title: 'billing.title.detail',
      name: $t('billing.sitemap.detail'),
      feature: [features.PaymentInvoice, features.PaymentGateway, features.ExternalPaymentRules],
      isRoot: true,
    },
  },
  {
    path: '/billing/detalization',
    name: 'billing-info',
    component: BillingInfo,
    props: (route) => ({ year: route.query.year, month: route.query.month }),
    meta: {
      title: 'billing.title.info',
      name: $t('billing.sitemap.info-empty'),
      feature: [features.PaymentInvoice, features.PaymentGateway],
      sitemap: [
        'billing',
      ],
    },
  },
  {
    path: '/invoice:number',
    name: 'billing-invoice',
    component: BillingInvoice,
    props: (route) => ({ number: route.params.number }),
    meta: {
      title: 'billing.title.invoice',
      name: $t('billing.sitemap.invoice'),
      feature: [features.PaymentInvoice],
      sitemap: [
        'billing',
      ],
    },
  },
  {
    path: '/act:actId',
    name: 'billing-act',
    component: BillingAct,
    props: (route) => ({ actId: route.params.actId }),
    meta: {
      title: 'billing.title.act',
      name: $t('billing.sitemap.act'),
      feature: [features.PaymentInvoice],
      sitemap: [
        'billing',
      ],
    },
  },
  {
    path: '/reconsiliation-report',
    name: 'billing-reconsiliation-report',
    component: BillingReconsiliationReport,
    props: (route) => ({
      optionId: route.query.optionId,
      projectCompanyId: route.query.projectCompanyId,
      partnerCompanyId: route.query.partnerCompanyId,
    }),
    meta: {
      title: 'billing.title.reconsiliation-report',
      name: $t('billing.sitemap.reconsiliation-report'),
      feature: [features.PaymentInvoice],
      sitemap: [
        'billing',
      ],
    },
  },
  {
    path: '/billing/addmoney',
    name: 'billing-add-money',
    component: BillingAddMoney,
    meta: {
      title: 'billing.title.add-money',
      name: $t('billing.sitemap.add-money'),
      feature: [features.PaymentInvoice, features.PaymentGateway, features.ExternalPaymentRules],
      sitemap: [
        'billing',
      ],
    },
  },
  {
    path: '/ssl/create',
    name: 'ssl-create',
    component: SslCreate,
    meta: {
      title: 'ssl.title.create',
      name: $t('ssl.sitemap.create'),
      feature: [features.SSL],
      sitemap: [
        'ssl-list',
      ],
    },
  },
  {
    path: '/ssl',
    name: 'ssl-list',
    component: SslList,
    meta: {
      title: 'ssl.title.list',
      name: $t('ssl.sitemap.list'),
      feature: [features.SSL],
      isRoot: true,
    },
  },
  {
    path: '/ssl/:certId',
    name: 'ssl-detail',
    component: SslDetail,
    props: (route) => ({ certId: parseInt(route.params.certId) }),
    meta: {
      title: 'ssl.title.detail',
      name: $t('ssl.sitemap.detail'),
      feature: [features.SSL],
      sitemap: [
        'ssl-list',
      ],
    },
  },
  {
    path: '/app-api/ssl/:certId/download/:type',
    name: 'ssl-detail-download',
    meta: { isOut: true },
    props: (route) => ({ ticketId: route.params.ticketId, type: route.params.type }),
    beforeEnter: (to, from, next) => {
      window.open(to.fullPath, '_blank')
      next(false)
    },
  },
  {
    path: '/details/:name',
    name: 'one-click-detail',
    component: OneClickAppDetail,
    props: (route) => ({ name: route.params.name }),
    meta: {
      title: 'one-click.title.detail',
      name: $t('one-click.title.detail'),
    },
  },
  {
    path: '/errors/404',
    name: 'error-404',
    component: ErrorNotFound,
    meta: {
      title: 'errors.title.404',
      name: '404',
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'error-404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.state.project.current && !to.query.pid) {
    to.query.pid = store.state.project.current.Id
    router.push(to)
    return
  }

  if (store.state.project.current) {
    store.dispatch(COMMON_APP_SETTINGS, { pid: store.state.project.current.Id })
    if (feature.isActiveFeature(feature.features.CustomerPayBonus)) {
      store.dispatch(BONUS_FETCH)
    }
  }

  metrics.yaHit(to.path)

  const financesAvailable = JSON.parse(localStorage.getItem('financesIsActive'))
  const financesDependentRoutes = ['billing', 'billing-add-money']

  const isSupportDir = to.href.includes('/support')
  const supportAvailable = JSON.parse(localStorage.getItem('supportIsActive'))

  const financesError = financesDependentRoutes.includes(to.name) && !financesAvailable && store.state.loaded
  const supportError = isSupportDir && !supportAvailable

  if (financesError || supportError) {
    next('/404')
  } else {
    next()
  }
})

export default router
