import metrics from '@/metrics';

export default {
  install(app) {
    const name = 'metrics';

    app.config.globalProperties['$' + name] = metrics;
    app[name] = metrics;
  },
};
