<template>
  <span
    v-if="$store.state.app.data.LogonAs"
    class="logonas"
  >
    <span class="logonas-email">{{ $store.state.app.data.User.Email }}</span>
    <BaseButton
      class="logonas-logout"
      size="small"
      reverse
      @click="logoutAs"
    >
      {{ $t('account.shared.account.signout') }}
    </BaseButton>
    <form
      v-show="false"
      ref="form"
      method="post"
      :action="`/app-api/account/signout-as?pid=${$store.state.project.current.Id}`"
    />
  </span>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
export default {
  components: { BaseButton },
  methods: {
    logoutAs () {
      this.$refs.form.submit()
    },
  },
}
</script>

<style lang="less" scoped>
.logonas {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  padding: 0 var(--margin-default);
  color: var(--color-light);
  background-color: var(--color-danger);
  border-left: 1px solid var(--color-border);

  :deep(.vue-button) {
    color: var(--color-light);
  }

  &-email {
    font-size: 14px;
    font-weight: 500;
  }

  &-logout {
    margin-left: 25px;
  }
}
</style>
