import {
  ITGLOBAL_CRM_LEAD,
} from '../actions.type'

import ApiService from '@/api'

const state = {

}

// getters
const getters = {

}
// actions
const actions = {
  [ITGLOBAL_CRM_LEAD] (context, model) {
    return ApiService.post(`/app-api/support/crm-lead`, model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations,
}
