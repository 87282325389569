const state = {
}

// getters
const getters = {

}
// actions
const actions = {

}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
