<template>
  <div
    v-if="loaded"
    id="app"
    :key="$i18n.locale"
    class="app"
    :class="{'app--menu': $store.state.menu.isOpen }"
  >
    <div
      v-if="isBrowserIe"
      class="browser-warning"
    >
      <span>{{ $t('layout.header.browser.warning') }}</span>
    </div>
    <LayoutHeader
      :is-browser-ie="isBrowserIe"
    />
    <LayoutMenu
      :is-browser-ie="isBrowserIe"
    />
    <BaseNotifications />
    <div
      class="app-wrapper"
      :class="{'ie-warning': isBrowserIe}"
    >
      <div
        class="app-main"
        :class="{ 'right-footer' : !$store.state.footerRightBar, 'unset-vh' : unsetAppMainHeight }"
      >
        <Notification v-if="$store.state.app.data" />
        <router-view />
      </div>
    </div>
    <div
      v-if="isFeedbackFormAllowed"
      class="feedback-icon"
      :class="{ 'dark' : isDarkThemeSelected, 'right-footer' : !$store.state.footerRightBar }"
      @click="showPopupFeedback = true"
    >
      <span
        class="feedback-icon-svg"
        v-html="ICON_FEEDBACK"
      />
    </div>
    <PopupFeedbackForm
      v-if="showPopupFeedback"
      @close="showPopupFeedback = false"
    />
  </div>
  <Loading
    v-else
    class="app-loader"
    :class="{ 'black' : firstAppLoad }"
  />
</template>

<script>
import BaseNotifications from './components/base/BaseNotifications.vue'
import Notification from './components/features/notification/shared/Notification.vue'
import LayoutHeader from './components/layout/LayoutHeader.vue'
import LayoutMenu from './components/layout/LayoutMenu.vue'
import feature from './feature'
import { installMetrics } from './metrics'
import { BONUS_FETCH, COMMON_APP_SETTINGS, SUPPORT_SIMPLEONE_GET_DATA } from './store/actions.type'
import partner from '@/partner'
import { isMobile, isTablet } from 'mobile-device-detect'
import { THEME_SET } from '@/store/mutations.type'
import { eventBus } from '@/plugins/eventBus.js'
import { ICON_FEEDBACK } from '@/svg/icons'
import PopupFeedbackForm from './components/shared/PopupFeedbackForm.vue'
import { IS_FEEDBACK_FORM_ALLOWED } from '@/store/getters.type'
export default {
  components: { LayoutHeader, LayoutMenu, BaseNotifications, Notification, PopupFeedbackForm },
  data () {
    return {
      loaded: false,
      isBrowserIe: false,
      publicPath: process.env.BASE_URL,
      firstLoad: false,
      unsetAppMainHeight: null,
      ICON_FEEDBACK,
      showPopupFeedback: false,
      firstAppLoad: false,
    }
  },
  computed: {
    financesActive () {
      return feature.isActiveFeature(feature.features.Finances)
    },
    isDarkThemeSelected () {
      return this.$store.getters.getDarkThemeState
    },
    isFeedbackFormAllowed () {
      return this.$store.getters[IS_FEEDBACK_FORM_ALLOWED]
    },
  },
  watch: {
    '$route.query.pid': function (newPid) {
      if (this.$store.state.project?.current?.Id && Number(newPid) !== this.$store.state.project?.current?.Id) {
        this.fetchData(Number(newPid))
      }
    },
    '$route.name': function () {
      if (this.$i18n) {
        this.$i18n.locale = localStorage?.getItem('locale').replaceAll('"', '')
      }
      document.title = this.$route.meta?.title ? this.$t(this.$route.meta.title, this.$route?.params || {}) : document.domain
    },
  },
  beforeCreate () {
    const darkThemeStorage = localStorage?.getItem('dark-theme') || null
    if (!localStorage?.getItem('theme-name')) {
      this.firstAppLoad = true
    }
    const currentTheme = localStorage?.getItem('theme-name') || 'default'

    this.$store.commit(THEME_SET, { theme: currentTheme, isDark: JSON.parse(darkThemeStorage) })
  },
  created () {
    let pid = this.findGetParameter('pid') || JSON.parse(localStorage?.getItem('LAST_PID'))

    this.fetchData(pid)
      .then((app) => {
        window.user = {
          userId: app?.User?.Id,
        }
        installMetrics()

        const currentLocale = app.Language.toLowerCase()
        localStorage?.setItem('locale', JSON.stringify(currentLocale))
        this.$i18n.locale = currentLocale

        document.title = this.$route.meta?.title ? this.$t(this.$route.meta.title, this.$route?.params || {}) : document.domain
      })
      .then(() => {
        this.firstAppLoad = false
        if (isMobile || isTablet) {
          if (!JSON.parse(sessionStorage?.getItem('MOBILE_SESSION'))) {
            sessionStorage.setItem('MOBILE_SESSION', JSON.stringify(true))
            if (!window.location.href.includes('/details/') || !window.location.href.includes('/help/') || !window.location.href.includes('/support/')) {
              if (!partner.isPartnerName(partner.partners.OBIT) && this.financesActive) {
                this.$router.push({ name: 'billing-add-money' })
              }
            }
          }
        }
      })

    this.checkBrowserIe()

    const self = this
    eventBus.$on('upd-appmain-vh', function (bool) {
      self.unsetAppMainHeight = bool
    })
  },
  methods: {
    fetchData (pid) {
      this.loaded = false
      return new Promise((resolve) => {
        this.$store.dispatch(COMMON_APP_SETTINGS, { pid: pid })
          .then((app) => {
            localStorage.setItem('LAST_PID', String(app.Project.Id))
            if (!this.firstLoad) {
              this.addFavicon()
            }

            if (feature.isActiveFeature(feature.features.SimpleOneSupport)) { this.$store.dispatch(SUPPORT_SIMPLEONE_GET_DATA) }
            if (feature.isActiveFeature(feature.features.CustomerPayBonus)) {
              this.$store.dispatch(BONUS_FETCH, true)
                .then(() => {
                  this.loaded = true
                  resolve(app)
                })
                .catch(() => {
                  this.loaded = true
                  resolve(app)
                })
            } else {
              this.loaded = true
              resolve(app)
            }
          })
          .catch((e) => {
            resolve(e)
            this.loaded = true
          })
      })
    },
    findGetParameter (parameterName) {
      let result = null
      let tmp = []
      window.location.search
        .substr(1)
        .split('&')
        .forEach(function (item) {
          tmp = item.split('=')
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
        })
      return result
    },
    checkBrowserIe () {
      let userAgent = navigator.userAgent.toLowerCase()

      if ((/mozilla/.test(userAgent) && !/firefox/.test(userAgent) && !/chrome/.test(userAgent) && !/safari/.test(userAgent) && !/opera/.test(userAgent)) || /msie/.test(userAgent)) {
        this.isBrowserIe = true
      }
    },
    addFavicon () {
      let isSvg = false
      let partnerName = 'ss'
      if (partner.isPartnerName(partner.partners.DOM_RU)) {
        partnerName = 'domru'
      } else if (partner.isPartnerName(partner.partners.OBIT)) {
        partnerName = 'obit'
        isSvg = true
      } else if (partner.isPartnerName(partner.partners.LINCORE)) {
        partnerName = 'lincore'
      } else if (partner.isPartnerName(partner.partners.IAASHUB)) {
        partnerName = 'iaashub'
      } else if (partner.isPartnerName(partner.partners.ITGLOBAL)) {
        partnerName = 'itglobal'
      } else if (partner.isPartnerName(partner.partners.FLUIR)) {
        partnerName = 'fluir'
      } else if (partner.isPartnerName(partner.partners.ZIQCLOUD)) {
        partnerName = 'ziqcloud'
      } else if (partner.isPartnerName(partner.partners.NETOOZE)) {
        partnerName = 'netooze'
      } else if (partner.isPartnerName(partner.partners.TELEDATA)) {
        partnerName = 'teledata'
      } else if (partner.isPartnerName(partner.partners.YOTA)) {
        partnerName = 'yota'
      } else if (partner.isPartnerName(partner.partners.ADLOOK)) {
        partnerName = 'adlook'
      } else if (partner.isPartnerName(partner.partners.ONEOFFICE)) {
        partnerName = '1office'
      } else if (!partner.isSS()) {
        partnerName = this.$store.state.app.data.PartnerName
      }

      let iconPath = isSvg ? 'favicon.svg' : 'favicon.ico'
      let link = document.createElement('link')
      let head = document.head
      link.href = this.publicPath + `favicon/${partnerName}/${iconPath}`
      link.type = 'image/x-icon'
      link.rel = 'icon'
      head.appendChild(link)
      this.firstLoad = true
    },
  },
}
</script>

<style lang="less" src="@/assets/less/main.less"></style>

<style lang="less" scoped>
.app {
  background-color: var(--color-light);

  &-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: unset;
    height: unset;
    margin-top: auto;
    transform: translate(-50%, -50%);

    &.black {
      :deep(svg) {
        color: black !important;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: var(--header-height);
    margin-left: var(--menu-width-collapse);
    transition: var(--menu-transition);

    &.ie-warning {
      margin-top: calc(var(--header-height) + 40px);
    }
  }

  &-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 64px);
    padding: 65px calc(50% - 543px);
    padding-bottom: 80px;
    overflow: hidden;

    &.unset-vh {
      min-height: unset;
    }

    & .vue-loading:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      width: unset;
      height: unset;
      transform: translate(-50%, -50%);
    }

    &.right-footer {
      width: calc(100% - 320px);
      padding: 65px calc(50% - 703px);
    }
  }

  &--menu {
    .app-wrapper {
      margin-left: var(--menu-width);
    }
  }
}

.feedback-icon {
  position: fixed;
  cursor: pointer;
  right: 32px;
  bottom: 16px;
  border-radius: 50%;
  background-color: var(--color-primary);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;

  &.right-footer {
    right: 335px;
  }

  &-svg {
    height: 16px;
  }

  &.dark {
    :deep(svg) path {
      fill: var(--color-base);
    }
  }
}

.browser-warning {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10006;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  background-color: var(--color-danger);

  & span {
    position: relative;
    top: -2px;
  }
}

@media (max-width: 820px) {
  .feedback-icon {
    right: 23px;
  }
}

@media (max-height: 768px) {
  .app-main {
    min-height: calc(100vh - 176px);
  }
}

@media (max-width: 1440px) {
  .app-main {
    padding: 65px calc(50% - 430px);
  }
}

@media (max-width: 1240px) {
  .app-main {
    padding: 65px calc(50% - 370px);
  }
}

@media (max-width: 1100px) {
  .app-main {
    padding: 65px calc(50% - 346px);
  }
}

@media (max-width: 1000px) {
  .app-main {
    padding: 65px calc(50% - 250px);
  }
}

@media (max-width: 840px) {
  .browser-warning {
    font-size: 11px;
  }
}

@media (max-width: 820px) {
  .app--menu {
    & .app-wrapper {
      margin-left: 0;
    }
  }

  .app-main {
    padding: 65px 24px;
  }
}

@media (max-width: 767px) {
  .browser-warning {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .app-main {
    padding: 45px 24px 110px 24px;
  }
}

@media (max-width: 530px) {
  .app-main {
    padding: 45px 16px 110px 16px;
  }
}
</style>

<style lang="less">
.cfg-users-tooltip {
  z-index: 10100;
}
</style>
