<template>
  <div
    class="vue-loading"
    :class="{ 'vue-loading--overlay': overlay, 'vue-loading--inline': inline }"
  >
    <VueSpinner
      :size="width"
      :height="height"
      :width="width"
      :color="$store.state.theme.variables['--color-primary']"
      class="010100"
    />
  </div>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';
export default {
  components: {
    VueSpinner,
  },
  props: {
    overlay: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    width: { type: Number, default: 70 },
    height: { type: Number, default: 70 },
  },
}
</script>

<style lang="less" scoped>
.vue-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;

  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: var(--color-light);
    opacity: 0.4;
  }

  &--inline {
    display: inline-block;
    width: auto;
    height: auto;
  }

  :deep(svg) {
    & .path {
      stroke-width: 3px;
    }
  }
}
</style>
