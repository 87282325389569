<template>
  <span>
    <BaseButton
      v-if="showBtn"
      reverse
      size="small"
      class="btn-test-paid"
      @click="showPopup = true"
    >
      <i18n-t
        keypath="billing.shared.test-paid.btn-popup"
        tag="span"
      >
        <template #bonus>
          <span>
            {{ formatCurrency(ExtendedTest.Bonus) }}
          </span>
        </template>
      </i18n-t>
    </BaseButton>
    <TestPaidPopup
      v-if="showPopup"
      :extended-test="ExtendedTest"
      @close="showPopup = false"
    />
  </span>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
import TestPaidPopup from './TestPaidPopup.vue'
import { COMMON_FETCH_PROFILE } from '@/store/actions.type'
import partner from '@/partner'
import formatCurrency from '@/lib/formatCurrency'

export default {
  components: {
    BaseButton,
    TestPaidPopup,
  },
  data () {
    return {
      showPopup: false,
      showBtn: false,
      partner: partner,
      ExtendedTest: null,
    }
  },
  created () {
    this.$store.dispatch(COMMON_FETCH_PROFILE)
      .then(data => {
        this.ExtendedTest = data.ExtendedTest
        if (this.ExtendedTest.IsAvailable) {
          this.showBtn = true
        }
      })
  },
  methods: {
    formatCurrency,
  },
}
</script>

<style lang="less" scoped>
.btn-test-paid {
  margin-left: 16px;
  vertical-align: top;
}
</style>
