import store from '@/store/index'

function currencyBeforeAmount () {
  return store.state.app.data.Currency === 'CAD' ||
        store.state.app.data.Currency === 'USD' ||
        store.state.app.data.Currency === 'EUR' ||
        store.state.app.data.Currency === 'AED' ||
        store.state.app.data.Currency === 'BRL'
}

function isCAD () {
  return store.state.app.data.Currency === 'CAD'
}

export default function formatCurrency (amount) {
  if (currencyBeforeAmount()) {
    return isCAD() ? '$' + ' ' + amount + ' ' + 'CAD' : store.state.currency + ' ' + amount
  } else {
    return amount + ' ' + store.state.currency
  }
}
