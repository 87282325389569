import {
  GPT_ADD_API_KEY,
  GPT_GET_API_KEYS,
  GPT_RENAME_API_KEY,
  GPT_DELETE_API_KEY,
  GPT_GET_TASK,
  GPT_GET_MODELS,
  GPT_GET_MODEL
} from '../actions.type'

import ApiService from '@/api'

const state = {
}

// getters
const getters = {
}
// actions
const actions = {
  [GPT_ADD_API_KEY] (context, model) {
    return ApiService.post('/app-api/llm/access-keys', model)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_GET_API_KEYS] (context, model) {
    return ApiService.get('/app-api/llm/access-keys', model)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_GET_MODELS] (context, model) {
    return ApiService.get('/app-api/llm/models', model)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_RENAME_API_KEY] (context, model) {
    return ApiService.post(`/app-api/llm/access-keys/${model.accessKeyId}/rename`, model)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_DELETE_API_KEY] (context, model) {
    return ApiService.delete(`/app-api/llm/access-keys/${model.accessKeyId}`)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_GET_TASK] (context, sagaId) {
    return ApiService.get(`/app-api/llm/sagas/${sagaId}`)
      .then(({ data }) => {
        return data
      })
  },
  [GPT_GET_MODEL] (context, modelId) {
    return ApiService.get(`/app-api/llm/models/${modelId}`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
