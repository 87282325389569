import axios from 'axios'
import { createApp } from 'vue'
import i18n from '@/i18n.js'
import store from '@/store'

const $t = (key) => i18n.global.t(key)

const api = axios.create()

const formatJsonInterceptor = (config) => {
  if (config.method === 'get') {
    config.params = config.params || {}
    config.params['format'] = 'json'
  }

  return config
}

const delimiterInterceptor = (config) => {
  if (config.method === 'get') {
    config.params = config.params || {}
    if (!config.params.isNoDelimiter) {
      config.params['delimiter'] = '/'
    }
  }

  return config
}

const headerInterceptor = (config) => {
  config.headers = config.headers || {}
  config.headers['X-Auth-Token'] = store.state.storage.token.Value
  config.headers['Cache-Control'] = 'no-cache'
  return config
}

const baseUrlInterceptor = (config) => {
  config.baseURL = store.state.storage.detail.Owner.SwiftApiConnection.Endpoint
  config.baseURL += `/v1/AUTH_${store.state.storage.detail.ExternalId}`
  return config
}

const responseInterceptor = response => {
  return response
}

const errorInterceptor = error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 500:
        createApp.notify.error($t('shared.apiError.500'))
        break
    }
  }

  return Promise.reject(error)
}

api.interceptors.request.use(baseUrlInterceptor)
api.interceptors.request.use(headerInterceptor)
api.interceptors.request.use(formatJsonInterceptor)
api.interceptors.request.use(delimiterInterceptor)
api.interceptors.response.use(responseInterceptor, errorInterceptor)

export default api
