import {
  ACCOUNT_USER_DELETE,
  ACCOUNT_API_KEYS_FETCH,
  ACCOUNT_API_KEY_CREATE,
  ACCOUNT_API_KEY_DELETE,
  ACCOUNT_INFO_FETCH,
  ACCOUNT_INFO_EDIT,
  ACCOUNT_PHONE_EDIT,
  ACCOUNT_PHONE_CODE_APPROVE,
  ACCOUNT_PHONE_AUTH_ENABLE,
  ACCOUNT_SSH_FETCH,
  ACCOUNT_SSH_CREATE,
  ACCOUNT_SSH_DELETE,
  ACCOUNT_CHANGE_PASSWORD,
  ACCOUNT_APP_GENERATE_CODE,
  ACCOUNT_APP_AUTH_ENABLE,
  ACCOUNT_SECURITY_FETCH,
  ACCOUNT_2FACTOR_DISABLE,
  ACCOUNT_COMPANY_FETCH,
  ACCOUNT_COMPANY_SAVE,
  ACCOUNT_COMPANY_INN,
  ACCOUNT_PROMOCODE_ACTIVATE,
  ACCOUNT_COMPANY_AUTO_INVOICE,
  ACCOUNT_PHONE_CODE_RESEND,
} from '../actions.type'

import ApiService from '@/api'

// state
const state = {
}

// getters
const getters = {

}

// actions
const actions = {
  [ACCOUNT_USER_DELETE] () {
    return ApiService.delete('/app-api/account')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_API_KEYS_FETCH] () {
    return ApiService.get('/app-api/account/api-key/list/json')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_API_KEY_CREATE] (context, params) {
    return ApiService.post('/app-api/account/api-key', params)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_API_KEY_DELETE] (context, apiKeyId) {
    return ApiService.delete(`/app-api/account/api-key/${apiKeyId}`)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_INFO_FETCH] () {
    return ApiService.get('/app-api/account/settings')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_INFO_EDIT] (context, info) {
    return ApiService.post('/app-api/account/settings', info)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_PHONE_EDIT] (context, model) {
    return ApiService.post('/app-api/account/phone/add', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_PHONE_CODE_APPROVE] (context, model) {
    return ApiService.post('/app-api/account/phone/confirm', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_PHONE_CODE_RESEND] (context, model) {
    return ApiService.post('/app-api/account/phone/resend-code', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_PHONE_AUTH_ENABLE] () {
    return ApiService.post('/app-api/account/security/2fa/phone')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_SSH_FETCH] () {
    return ApiService.get('/app-api/ssh-keys')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_SSH_CREATE] (context, model) {
    return ApiService.post('/app-api/ssh-keys', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_SSH_DELETE] (context, sshId) {
    return ApiService.delete(`/app-api/ssh-keys/${sshId}`)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_CHANGE_PASSWORD] (context, model) {
    return ApiService.post('/app-api/account/security/password', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_APP_GENERATE_CODE] () {
    return ApiService.post('/app-api/account/security/2fa/app/key')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_APP_AUTH_ENABLE] (context, model) {
    return ApiService.post('/app-api/account/security/2fa/app', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_SECURITY_FETCH] () {
    return ApiService.get('/app-api/account/security')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_PROMOCODE_ACTIVATE] (context, model) {
    return ApiService.post('/app-api/promo-codes', model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_2FACTOR_DISABLE] () {
    return ApiService.post('/app-api/account/security/2fa/disable')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_COMPANY_FETCH] () {
    return ApiService.get('/app-api/company/json')
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_COMPANY_SAVE] (context, params) {
    return ApiService.post(`/app-api/company/${params.county}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_COMPANY_INN] (context, inn) {
    return ApiService.get(`/app-api/suggestions/ru/company/${inn}`)
      .then(({ data }) => {
        return data
      })
  },
  [ACCOUNT_COMPANY_AUTO_INVOICE] (context, model) {
    return ApiService.post('/app-api/company/auto-invoice/change', model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
