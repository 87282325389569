import store from '@/store'

import partners from './partners'

const isPartnerName = (partnerName) => {
  return store.state.app.data?.PartnerName === partnerName
}

const isSS = () => {
  return isPartnerName(partners.SS_BY) ||
  isPartnerName(partners.SS_IN) ||
  isPartnerName(partners.SS_IO) ||
  isPartnerName(partners.SS_RU) ||
  isPartnerName(partners.SS_KZ) ||
  isPartnerName(partners.SS_CA) ||
  isPartnerName(partners.SS_BR) ||
  isPartnerName(partners.SS_TEST) ||
  isPartnerName(partners.SS_TR) ||
  isPartnerName(partners.ITGLOBAL) ||
  isPartnerName(partners.SS_US)
}

export default {
  isPartnerName,
  isSS,
  partners,
}
