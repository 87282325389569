
import { createI18n } from 'vue-i18n'

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
**/
const ruPluralizationRule = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2
  }
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}

const datetimeFormats = {
  en: {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit',
    },
    timeHM: {
      hour: '2-digit', minute: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    yyyyMM: {
      year: 'numeric', month: 'long',
    },
    hhmmss: {
      hour: '2-digit', minute: '2-digit', second: '2-digit',
    },
  },
  es: {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit',
    },
    timeHM: {
      hour: '2-digit', minute: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    yyyyMM: {
      year: 'numeric', month: 'long',
    },
    hhmmss: {
      hour: '2-digit', minute: '2-digit', second: '2-digit',
    },
  },
  ru: {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit',
    },
    timeHM: {
      hour: '2-digit', minute: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    yyyyMM: {
      year: 'numeric', month: 'long',
    },
    hhmmss: {
      hour: '2-digit', minute: '2-digit', second: '2-digit',
    },
  },
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  datetimeFormats,
  pluralizationRules: {
    'ru': ruPluralizationRule,
  },
  warnHtmlInMessage: 'off',
})
