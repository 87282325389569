import { FEEDBACK_SUBMIT } from '../actions.type'

import ApiService from '@/api'

const state = {
}

// getters
const getters = {
}
// actions
const actions = {
  [FEEDBACK_SUBMIT] (context, model) {
    return ApiService.post('/app-api/feedback', model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
