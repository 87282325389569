<template>
  <Popup @close="$emit('close')">
    <template #default>
      <div
        v-if="!formSended"
        class="popup-feedback-form"
      >
        <div class="title">
          {{ $t('shared.popup-feedback-form.title') }}
        </div>
        <div class="popup-feedback-form-text">
          {{ $t('shared.popup-feedback-form.text') }}
        </div>
        <div class="popup-feedback-form-textarea">
          <textarea
            v-model.trim="model.FeedbackFormText"
            :placeholder="$t('shared.popup-feedback-form.textarea-ph')"
            class="vue-textarea"
            :class="[{'error' : invalidMessageText.length}]"
            @input="messageValidate"
          />
          <span class="text-error">
            {{ invalidMessageText }}
          </span>
        </div>
        <div class="popup-feedback-form-type block-with-label">
          <span class="block-with-label-name">
            {{ $t('shared.popup-feedback-form.type-label') }}
          </span>
          <BaseDropdown
            v-model="model.UserPreferredContactMethod"
            :options="methods"
            field-value="Id"
            field-title="Title"
          />
        </div>
        <BaseInput
          v-if="model.UserPreferredContactMethod === 'Phone'"
          v-model="model.UserContactPhone"
          :placeholder="$t('shared.popup-feedback-form.phone-ph')"
          type="mobile-number"
        />
        <BaseInput
          v-else
          v-model="model.UserContactEmail"
          :placeholder="$t('shared.popup-feedback-form.email-ph')"
          :class="{ 'error' : invalidMailError }"
        />
        <span
          v-if="invalidMailError"
          class="text-error"
        >
          {{ invalidMailErrorText }}
        </span>
      </div>
      <div
        v-if="formSended"
        class="popup-feedback-form sended"
      >
        <div class="title">
          {{ $t('shared.popup-feedback-form.title-sended') }}
        </div>
        <div class="popup-feedback-form-text">
          {{ $t('shared.popup-feedback-form.text-sended') }}
        </div>
      </div>
    </template>
    <template #controls>
      <BaseButton
        reverse
        @click="$emit('close')"
      >
        {{ formSended ? $t('shared.popup-feedback-form.close-btn') : $t('shared.popup-feedback-form.back-btn') }}
      </BaseButton>
      <BaseButton
        v-if="!formSended"
        class="delete-btn"
        :submit="submit"
        :disabled="Boolean(hasInvalidFields)"
        @click="send"
      >
        {{ $t('shared.popup-feedback-form.confir-btn') }}
      </BaseButton>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/shared/Popup.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { SEND_FEEDBACK_FORM } from '@/store/actions.type'

export default {
  components: {
    Popup,
    BaseButton,
    BaseDropdown,
    BaseInput,
  },
  emits: ['close'],
  data () {
    const methods = [
      {
        Title: this.$t('shared.popup-feedback-form.email-label'),
        Id: 'Email',
      },
      {
        Title: this.$t('shared.popup-feedback-form.phone-label'),
        Id: 'Phone',
      },
    ]

    return {
      methods,
      model: {
        FeedbackFormText: '',
        UserPreferredContactMethod: 'Email',
        UserContactEmail: '',
        UserContactPhone: '',
      },
      submit: false,
      modelState: null,
      invalidMessageText: '',
      invalidMailError: false,
      invalidMailErrorText: "",
      formSended: false,
      accountMail: '',
    }
  },
  computed: {
    hasInvalidFields () {
      return this.invalidMessageText.length || this.invalidMailError || false
    },
  },
  watch: {
    'model.UserContactEmail': function () {
      this.invalidMailError = false
    },
    'model.UserPreferredContactMethod': function () {
      if (this.accountMail) {
        this.model.UserContactEmail = this.accountMail
      } else {
        this.model.UserContactEmail = ''
      }
      this.model.UserContactPhone = ''
      this.invalidMailError = false
      this.invalidMessageText = ''
    },
  },
  created () {
    this.accountMail = this.$store.state.app.data.User.Email
    if (this.accountMail) {
      this.model.UserContactEmail = this.accountMail
    }
  },
  methods: {
    send () {
      if (this.model.UserPreferredContactMethod === 'Email') {
        this.emailValidate()
      }
      this.messageValidate()
      if (this.hasInvalidFields) return

      this.submit = true
      this.$store.dispatch(SEND_FEEDBACK_FORM, this.model)
        .then(() => {
          this.formSended = true
          this.submit = false
          setTimeout(() => {
            this.$emit('close')
          }, 30000)
        })
        .catch(() => {
          this.submit = false
        })
    },
    messageValidate () {
      if (this.model.FeedbackFormText.length >= 1000) {
        this.invalidMessageText = this.$t('shared.popup-feedback-form.message-invalid-length')
      } else if (!this.model.FeedbackFormText.length) {
        this.invalidMessageText = this.$t('shared.popup-feedback-form.message-invalid-empty')
      } else {
        this.invalidMessageText = ''
      }
    },
    emailValidate () {
      if (!this.model.UserContactEmail.length) {
        this.invalidMailErrorText = this.$t('shared.popup-feedback-form.email-invalid-length')
        this.invalidMailError = true
        return
      } else {
        this.invalidMailError = false
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

      if (!emailRegex.test(this.model.UserContactEmail)) {
        this.invalidMailErrorText = this.$t('shared.popup-feedback-form.email-invalid')
        this.invalidMailError = true
      } else {
        this.invalidMailError = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.popup-feedback-form {
  &-text {
    margin-bottom: var(--margin-default);
  }

  &-textarea {
    & textarea {
      height: 200px;
      resize: none;
      &.error {
        border-color: var(--color-danger);
      }
    }

    margin-bottom: var(--margin-default);
  }

  &-type {
    margin-bottom: 10px;
  }

  & .text-error {
    display: block;
    width: 100%;
    font-size: 12px;
    color: var(--color-danger);
    letter-spacing: .48px;
  }

  :deep(.vue-base-input) {
    &.error .vue-base-input-input {
      border-color: var(--color-danger);
    }
  }
}
</style>
