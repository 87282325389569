<template>
  <div
    class="base-info-block"
    :class="[ { 'dark' : isDarkThemeSelected }, 'type--' + type ]"
  >
    <slot name="image" />
    <div
      class="base-info-block-icon"
      :class="[type]"
    >
      <span
        class="base-info-block-icon-svg"
        v-html="iconType"
      />
    </div>
    <div class="base-info-block-content">
      <div
        v-if="$slots.title"
        class="base-info-block-content-title"
        name="title"
      >
        <slot name="title" />
      </div>
      <div
        class="base-info-block-content-text"
        :class="{ 'dark' : isDarkThemeSelected }"
      >
        <slot name="default" />
      </div>
    </div>
    <div
      v-if="hasClose"
      class="button-close"
      @click="$emit('close')"
      v-html="icons.ICON_CROSS"
    />
  </div>
</template>

<script>
import { ICON_INFO_ROUND, ICON_INFO_TRIANGLE, ICON_INFO_OCTAGON, ICON_CROSS } from '@/svg/icons'
export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data () {
    return {
      icons: {
        ICON_INFO_ROUND,
        ICON_INFO_TRIANGLE,
        ICON_INFO_OCTAGON,
        ICON_CROSS,
      },
    }
  },
  computed: {
    iconType () {
      switch (this.type) {
        case 'danger':
          return this.icons.ICON_INFO_OCTAGON
        case 'warning':
          return this.icons.ICON_INFO_TRIANGLE
        default:
          return this.icons.ICON_INFO_ROUND
      }
    },
    isDarkThemeSelected () {
      return this.$store.getters.getDarkThemeState
    },
  },
}
</script>

<style lang="less" scoped>
.base-info-block {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px;
  margin-top: 27px;
  border-left: 2px solid #999a9d;
  border-radius: var(--border-radius);

  &.type {
    &--danger {
      background: #fd012305;
      border: 1px solid #fd012340;

      &.dark {
        border: 1px solid #fd012333;

        & .button-close {
          :deep(svg path) {
            fill: #fff;
          }
        }
      }
    }

    &--warning {
      background: #fdc20105;
      border: 1px solid #f2ebbf;

      &.dark {
        border: 1px solid #fdc20133;

        & .base-info-block-icon-svg {
          :deep(svg path) {
            &:first-child {
              opacity: 0.8;
              fill: #fdc201;
            }
          }
        }
      }
    }

    &--info {
      background-color: #1d23300d;
      border: 1px solid #e8eaea;

      &.dark {
        background: #e7e7e70d;
        border: 1px solid #e7e7e733;
      }
    }
  }

  &-icon {
    display: flex;
    margin-bottom: auto;

    &-svg {
      width: 24px;
      height: 24px;

      :deep(svg) {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }

    &-text {
      display: flex;
      font-size: 12px;
      line-height: 18px;

      &.dark {
        color: var(--color-state-disabled);
      }
    }
  }

  &-list {
    padding-left: 17px;
  }

  & .button-close {
    position: absolute;
    top: 19px;
    right: 19px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 30px;
    line-height: 16px;
    color: var(--color-base);
    cursor: pointer;

    :deep(svg) {
      width: 16px;
      height: 16px;

      & path {
        fill: #999a9d;
      }
    }
  }
}
</style>
