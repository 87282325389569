<template>
  <div
    class="vue-popup"
    @mousedown="close"
  >
    <div
      class="vue-popup-container"
      :class="`size-${defaultGridCount}`"
      @mousedown.stop
    >
      <div
        v-if="canClose"
        class="vue-popup-close"
        @mousedown="close"
      >
        &#215;
      </div>
      <slot />
      <div
        v-if="$slots.controls"
        ref="controlsBlock"
        class="vue-popup-controls"
        :class="`grid-count-${defaultGridCount}`"
      >
        <slot name="controls" />
      </div>
    </div>
  </div>
</template>

<script>
import { COMMON_SET_POPUP_VISIBLE } from '@/store/mutations.type'

export default {
  name: 'Popup',
  props: {
    canClose: { type: Boolean, default: true },
    withPrice: { type: Boolean, default: false },
  },
  emits: ['close'],
  data () {
    return {
      defaultGridCount: 2,
    }
  },
  mounted () {
    this.checkSlotsCount()
  },
  updated () {
    this.checkSlotsCount()
  },
  created () {
    document.addEventListener('keydown', this.onKeyDown)
    document.body.style.overflow = 'hidden'
    this.$store.commit(COMMON_SET_POPUP_VISIBLE, true)
  },
  beforeUnmount () {
    document.body.style.overflow = 'auto'
    document.removeEventListener('keydown', this.onKeyDown)
    this.$store.commit(COMMON_SET_POPUP_VISIBLE, false)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    onKeyDown (e) {
      if (e.key === 'Escape') {
        this.close()
      }
    },
    checkSlotsCount () {
      if (this.$refs?.controlsBlock?.childElementCount) this.defaultGridCount = this.$refs.controlsBlock.childElementCount
    },
  },
}
</script>

<style lang="less" scoped>
.vue-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10010;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--color-background-popup);

  &-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: end;
    margin-top: var(--margin-default);

    &.grid-count {
      &-1 {
        grid-template-columns: 1fr;
      }

      &-2 {
        grid-template-columns: 1fr 1fr;
      }

      &-3 {
        position: relative;
        grid-template-areas:
          "price price"
          "btn-1 btn-2";
        grid-template-columns: 1fr 1fr;
        padding-top: var(--margin-default);

        &::after {
          position: absolute;
          top: 0;
          left: -32px;
          display: block;
          width: calc(100% + 64px);
          height: 1px;
          content: "";
          background-color: var(--color-border);
        }

        :deep(.controls-price) {
          grid-area: price;
        }

        :deep(.vue-price) {
          display: grid;
          grid-area: price;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }
      }
    }

    :deep(.controls-price) {
      font-size: 14px;

      &-title {
        margin-bottom: 10px;
      }

      &-diff {
        color: var(--color-disabled-secondary);
      }
    }
  }

  :deep(.title) {
    display: block;
    width: 98%;
    margin-bottom: var(--margin-middle);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.02em;

    &.small {
      font-size: 24px;
    }
  }

  &-container {
    position: relative;
    max-width: 565px;
    min-height: 100px;
    padding: 32px;
    background-color: var(--color-light);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    margin: auto;
    top: 0;
    bottom: 0;

    &.size-2 {
      max-width: 570px;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 23px;
    font-size: 51px;
    font-weight: 200;
    line-height: 22px;
    cursor: pointer;
  }

  & * {
    word-wrap: break-word;
  }
}

:deep(.vue-price-main) {
  margin-bottom: 0 !important;
}

@media (max-width: 1400px) {
  .vue-popup :deep(.title) {
    font-size: 28px;
  }
}

@media (max-width: 820px) {
  .vue-popup :deep(.title) {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .vue-popup-container {
    width: 100%;
  }

  .vue-popup-controls {
    gap: 16px;
  }
}

@media (max-width: 530px) {
  .vue-popup-close {
    width: 18px;
    height: 19px;
    font-size: 38px;
  }

  .vue-popup :deep(.title) {
    font-size: 22px !important;
  }

  .vue-popup-container {
    min-width: unset;
    max-width: unset !important;
    max-height: 100vh;
    padding: 16px;
    overflow: auto;

    :deep(.vue-button--big) {
      min-width: unset;
    }
  }

  .vue-popup-controls {
    &.grid-count-3 {
      grid-template-areas: unset;
      grid-template-rows: unset;
      grid-template-columns: 1fr;
      padding-top: 0;

      &::after {
        display: none;
      }

      & :deep(.controls-price) {
        grid-area: unset;

        & .vue-price {
          display: flex;
        }
      }

      & :deep(.vue-price) {
        grid-area: unset;
      }
    }
  }
}
</style>
