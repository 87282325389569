import {
  REFERRAL_DETAIL_FETCH,
  REFERRAL_INVITE,
  REFERRAL_TRANSFER_MONEY,
} from '../actions.type'

import ApiService from '@/api'

const state = {

}

// getters
const getters = {

}
// actions
const actions = {
  [REFERRAL_DETAIL_FETCH] () {
    return ApiService.get('/app-api/referral/json')
      .then(({ data }) => {
        return data
      })
  },
  [REFERRAL_INVITE] (context, model) {
    return ApiService.post('/app-api/referral/invite', model)
      .then(({ data }) => {
        return data
      })
  },
  [REFERRAL_TRANSFER_MONEY] (context, model) {
    return ApiService.post('/app-api/referral/transfer-money-to-project', model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations,
}
