import cssVars from 'css-vars-ponyfill'

import { THEME_SET } from '../mutations.type'

import themeBlueGray from '@/themes/blue-gray.json'
import themeBlueWhite from '@/themes/blue-white.json'
import themeGreenGray from '@/themes/green-gray.json'
import themeGreenWhite from '@/themes/green-white.json'
import themeFluir from '@/themes/fluir.json'
import themeIaashub from '@/themes/iaashub.json'
import themeZiqcloud from '@/themes/ziqcloud.json'
import themeNetooze from '@/themes/netooze.json'
import themeMiran from '@/themes/miran.json'
import themeFalcon from '@/themes/falcon.json'
import themeEkacod from '@/themes/ekacod.json'
import themeGloss from '@/themes/gloss.json'

import themeBlueWhiteDark from '@/themes/blue-white__dark.json'
import themeGreenWhiteDark from '@/themes/green-white__dark.json'
import themeGreenGrayDark from '@/themes/green-gray__dark.json'
import themeFluirDark from '@/themes/fluir__dark.json'
import themeIaashubDark from '@/themes/iaashub__dark.json'
import themeZiqcloudDark from '@/themes/ziqcloud__dark.json'
import themeNetoozeDark from '@/themes/netooze__dark.json'
import themeMiranDark from '@/themes/miran__dark.json'
import themeFalconDark from '@/themes/falcon__dark.json'
import themeEkacodDark from '@/themes/ekacod__dark.json'
import themeGlossDark from '@/themes/gloss__dark.json'

export const THEME_BLUE_GREAY = 'blue-gray'
export const THEME_BLUE_WHITE = 'white-blue'
export const THEME_GREEN_GRAY = 'yellow-green'
export const THEME_GREEN_WHITE = 'white-green'
export const THEME_FLUIR = 'fluir'
export const THEME_IAASHUB = 'iaashub'
export const THEME_ZIQCLOUD = 'ziqcloud'
export const THEME_NETOOZE = 'netooze'
export const THEME_MIRAN = 'miran'
export const THEME_FALCON = 'falcon'
export const THEME_EKACOD = 'ekacod'
export const THEME_GLOSS = 'gloss'

export const THEME_BLUE_WHITE_DARK = 'white-blue__dark'
export const THEME_BLUE_GREY_DARK = 'blue-gray__dark'
export const THEME_GREEN_WHITE_DARK = 'white-green__dark'
export const THEME_GREEN_GRAY_DARK = 'yellow-green__dark'
export const THEME_FLUIR_DARK = 'fluir__dark'
export const THEME_IAASHUB_DARK = 'iaashub__dark'
export const THEME_ZIQCLOUD_DARK = 'ziqcloud__dark'
export const THEME_NETOOZE_DARK = 'netooze__dark'
export const THEME_MIRAN_DARK = 'miran__dark'
export const THEME_FALCON_DARK = 'falcon__dark'
export const THEME_EKACOD_DARK = 'ekacod__dark'
export const THEME_GLOSS_DARK = 'gloss__dark'

function getTheme (themeName) {
  switch (themeName) {
    case THEME_NETOOZE:
      return themeNetooze
    case THEME_MIRAN:
      return themeMiran
    case THEME_BLUE_WHITE:
      return themeBlueWhite
    case THEME_GREEN_GRAY:
      return themeGreenGray
    case THEME_GREEN_WHITE:
      return themeGreenWhite
    case THEME_FLUIR:
      return themeFluir
    case THEME_IAASHUB:
      return themeIaashub
    case THEME_ZIQCLOUD:
      return themeZiqcloud
    case THEME_BLUE_WHITE_DARK:
      return themeBlueWhiteDark
    case THEME_BLUE_GREY_DARK:
      return themeBlueWhiteDark
    case THEME_GREEN_WHITE_DARK:
      return themeGreenWhiteDark
    case THEME_GREEN_GRAY_DARK:
      return themeGreenGrayDark
    case THEME_FLUIR_DARK:
      return themeFluirDark
    case THEME_IAASHUB_DARK:
      return themeIaashubDark
    case THEME_ZIQCLOUD_DARK:
      return themeZiqcloudDark
    case THEME_NETOOZE_DARK:
      return themeNetoozeDark
    case THEME_MIRAN_DARK:
      return themeMiranDark
    case THEME_FALCON:
      return themeFalcon
    case THEME_FALCON_DARK:
      return themeFalconDark
    case THEME_EKACOD:
      return themeEkacod
    case THEME_EKACOD_DARK:
      return themeEkacodDark
    case THEME_GLOSS:
      return themeGloss
    case THEME_GLOSS_DARK:
      return themeGlossDark
    case 'default':
    case THEME_BLUE_GREAY:
    default:
      return themeBlueGray
  }
}

const state = {
  name: null,
  variables: getTheme(),
  isIAASHUB: null,
  isDark: false,
}

const getters = {
  getDarkThemeState: state => state.isDark,
}

const actions = {

}

const mutations = {
  [THEME_SET] (state, themeObj) {
    if (themeObj.isDark) {
      if (themeObj.theme === 'default') {
        state.name = 'white-blue__dark'
      } else {
        state.name = themeObj.theme + '__dark'
      }
    } else {
      state.name = themeObj.theme
    }
    state.isDark = themeObj.isDark
    state.variables = getTheme(state.name)
    state.isIAASHUB = (getTheme(state.name) === themeIaashub)
    state.isGreenGray = (getTheme(state.name) === themeGreenGray)
    localStorage.setItem('dark-theme', JSON.parse(state.isDark))
    localStorage.setItem('theme-name', state.name)
    cssVars({
      variables: state.variables,
    })
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
