<template>
  <Popup @close="onClose">
    <template #default>
      <div
        v-if="state === 'model'"
        class="popup-project-create"
      >
        <span class="title">{{ $t('projects.shared.popup.create.title') }}</span>
        <div class="popup-project-create-desc">
          {{ $t('projects.shared.popup.create.desc') }}
        </div>
        <div
          v-if="$store.state.project.current.IsPersonalProject"
          class="popup-project-create-convert"
        >
          <BaseCheckBox
            v-model="model.ConvertPersonalProject"
            :label="$t('projects.shared.popup.create.convert')"
            @update:model-value="model.ConvertPersonalProject = $event"
          />
        </div>
        <div class="popup-project-create-model block-with-label">
          <span class="block-with-label-name">{{ $t('projects.shared.popup.create.name.placeholder') }}</span>
          <BaseInput
            v-model="model.ProjectName"
            :placeholder="$t('projects.shared.popup.create.name.placeholder-ph')"
          />
          <ValidationError
            :model-state="modelState"
            name="ProjectName"
          />
        </div>
        <div class="popup-project-create-logo">
          <label
            class="popup-project-create-logo-img"
            for="popup-project-create-logo-upload-input"
          >
            <img
              v-if="croppedCanvas"
              :src="croppedCanvas.toDataURL()"
            >
            <img
              v-else-if="model.DefaultLogoName"
              :src="logosList.find(x => x.Name === model.DefaultLogoName).Url"
            >
            <span v-else>+</span>
          </label>
          <i18n-t
            keypath="projects.shared.popup.create.desc2"
            tag="span"
            class="popup-project-create-logo-desc"
          >
            <template #list>
              <VDropdown
                class="popup-project-create-logo-tooltip-list"
                placement="bottom"
                container=".popup-project-create"
                :auto-hide="true"
              >
                <span class="popup-project-create-logo-desc-select">{{ $t('projects.shared.popup.create.list.title') }}</span>
                <template #popper="{ hide }">
                  <div class="popup-project-create-logo-popover-content">
                    <div class="logo-list">
                      <img
                        v-for="logo in logosList"
                        :key="logo.Url"
                        :src="logo.Url"
                        class="logo-list-item"
                        @click="setLogo(logo), hide()"
                      >
                    </div>
                  </div>
                </template>
              </VDropdown>
            </template>
          </i18n-t>
        </div>
      </div>
      <div
        v-else
        class="popup-project-create"
      >
        <Header
          h2
          :title="$t('projects.shared.popup.create.title-img')"
        />
        <div class="popup-project-create-desc">
          {{ $t('projects.shared.popup.create.desc-img') }}
        </div>
        <div
          class="popup-project-create-model"
        >
          <vue-cropper
            ref="cropper"
            class="popup-project-create-model-control"
            :src="src"
            alt="Source Image"
            :aspect-ratio="1"
            preview=".popup-project-create-model-view-logo"
            :view-mode="0"
          />
          <div class="popup-project-create-model-view">
            <label class="popup-project-create-model-view-logo popup-project-create-model-view-logo-md" />
            <div class="popup-project-create-model-view-logo popup-project-create-model-view-logo-sm" />
          </div>
        </div>
      </div>
      <input
        v-show="false"
        id="popup-project-create-logo-upload-input"
        type="file"
        accept=".jpg,.jpeg,.png"
        @change="onImgSelect"
      >
    </template>
    <template #controls>
      <BaseButton
        v-if="state === 'model'"
        reverse
        @click="$emit('close')"
      >
        {{ $t('projects.shared.popup.create.btn.back') }}
      </BaseButton>
      <BaseButton
        v-if="state === 'model'"
        :submit="submit"
        @click="onCreate"
      >
        {{ $t('projects.shared.popup.create.btn.create') }}
      </BaseButton>
      <BaseButton
        v-if="state !== 'model'"
        @click="onImgSave"
      >
        {{ $t('projects.shared.popup.create.btn.img-save') }}
      </BaseButton>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/shared/Popup.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import ValidationError from '@/components/shared/ValidationError.vue'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { PROJECT_CREATE } from '@/store/actions.type'
import BaseCheckBox from '@/components/base/BaseCheckBox.vue'

export default {
  components: {
    Popup,
    BaseButton,
    VueCropper,
    BaseInput,
    ValidationError,
    BaseCheckBox,
  },
  emits: ['close', 'success'],
  data () {
    return {
      submit: false,
      modelState: null,
      file: null,
      src: null,
      croppedCanvas: null,
      state: 'model',
      model: {
        ProjectName: '',
        ConvertPersonalProject: false,
        ProjectLogo: null,
        DefaultLogoName: null,
      },
      logosList: [
        {
          Url: require('@/assets/img/project-default-logo/1.jpg'),
          Name: '1.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/2.jpg'),
          Name: '2.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/3.jpg'),
          Name: '3.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/4.jpg'),
          Name: '4.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/5.jpg'),
          Name: '5.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/6.jpg'),
          Name: '6.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/7.jpg'),
          Name: '7.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/8.jpg'),
          Name: '8.jpg',
        },
        {
          Url: require('@/assets/img/project-default-logo/9.jpg'),
          Name: '9.jpg',
        },
      ],
    }
  },
  methods: {
    setLogo (logo) {
      this.model.DefaultLogoName = logo.Name
      this.croppedCanvas = null
    },
    onImgSave () {
      this.croppedCanvas = this.$refs.cropper.getCroppedCanvas({
        width: 70,
        height: 70,
        minWidth: 70,
        minheight: 70,
        fillColor: '#fff',
      })
      this.model.DefaultLogoName = null
      this.state = 'model'
    },
    onImgSelect (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }

      let errSymbols = ['<', '>', ';', ':', '"', "'", '=']
      let file

      if (files && files.length) {
        file = files[0]
        let testName = file.name

        if (new RegExp(errSymbols.join('|'), 'g').test(testName)) {
          this.$notify.warning(this.$t('account.pages.manage.project-info.logo.msg-not-valid-name'))
        } else if (/^image\/\w+$/.test(file.type)) {
          this.file = file
          this.src = URL.createObjectURL(file)
          this.state = 'logo'
        } else {
          this.$notify.warning(this.$t('account.pages.manage.project-info.logo.msg-choose-image'))
        }
      }
    },
    onCreate () {
      let data = new FormData()
      data.append('ProjectName', this.model.ProjectName)
      data.append('ConvertPersonalProject', this.model.ConvertPersonalProject)
      if (this.croppedCanvas) {
        this.croppedCanvas.toBlob((blob) => {
          data.append('ProjectLogo', blob, this.file.name)
          this.createProject(data)
        }, 'image/jpeg')
      } else {
        data.append('DefaultLogoName', this.model.DefaultLogoName || '1.jpg')
        this.createProject(data)
      }
    },
    createProject (model) {
      if (this.submit) {
        return
      }

      this.submit = true
      this.modelState = null

      this.$store.dispatch(PROJECT_CREATE, model)
        .then((response) => {
          this.$emit('success', response.ProjectId)
        })
        .catch((e) => {
          if (e.modelState) {
            this.modelState = e.modelState
          }

          this.submit = false
        })
    },
    onClose () {
      if (this.state === 'model') {
        this.$emit('close')
      } else {
        this.state = 'model'
      }
    },
  },
}
</script>

<style lang="less">
.popup-project-create-logo-desc-select {
  z-index: 10100 !important;
}
</style>

<style lang="less" scoped>
.popup-project-create {
  &-desc,
  &-convert {
    margin-top: 20px;
  }

  &-model {
    margin-top: 20px;

    &-control {
      display: inline-block;
      width: 300px;
      height: 200px;
    }

    &-view {
      display: inline-block;
      margin-left: 30px;
      vertical-align: top;

      &-logo {
        overflow: hidden;

        &-md {
          width: 80px;
          height: 80px;
        }

        &-sm {
          width: 40px;
          height: 40px;
          margin-top: var(--margin-default);
        }
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    margin-top: var(--margin-default);

    &-tooltip-list {
      width: max-content;
      display: inline;
    }

    &-img {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      font-size: 32px;
      color: var(--color-disabled-secondary);
      cursor: pointer;
      background-color: var(--color-background);
      border: 1px dashed var(--color-disabled-secondary);
      border-radius: var(--border-radius);

      &:hover {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }

    &-desc {
      margin-left: var(--margin-default);

      &-select {
        display: inline-block;
        color: var(--color-primary);
        cursor: pointer;
      }
    }

    &-popover {
      z-index: 10100;

      &-content {
        width: 135px;

        .logo-title {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 400;
        }

        .logo-list {
          display: flex;
          flex-wrap: wrap;
          margin: -5px 0 0 -5px;

          &-item {
            width: 40px;
            height: 40px;
            margin: 5px 0 0 5px;
            cursor: pointer;
            border: 2px solid transparent;
            border-radius: 5px;

            &--active {
              cursor: auto;
              border-color: #56a95d;
              box-shadow: none;
            }

            &:hover {
              box-shadow: 0 0 5px;
            }
          }
        }

        .logo-upload {
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }

  &-actions {
    margin-top: var(--margin-default);
    text-align: right;

    &-btn {
      &-confirm {
        margin-left: 30px;
        vertical-align: top;
      }
    }
  }
}
</style>
