import {
  DNS_FETCH_DOMAINS,
  DNS_DELETE_DOMAIN,
  DNS_CREATE_DOMAIN,
  DNS_FETCH_DOMAIN,
  DNS_CREATE_RECORD,
  DNS_DELETE_RECORD,
  DNS_EDIT_RECORD,
  DNS_TASK_DOMAIN,
  DNS_TASK_RECORD,
} from '../actions.type'

import { DNS_DOMAINS_SET, DNS_DOMAINS_EXPIRED_SET, DNS_TTL_SET, COMMON_CLEAR_CACHE } from '../mutations.type'

import ApiService from '@/api'

const state = {
  ttl: null,
  protocols: [
    { value: 'Tcp', name: 'TCP' },
    { value: 'Udp', name: 'UDP' },
    { value: 'Tls', name: 'TLS' },
  ],
  domains: null,
  expire: null,
}

// getters
const getters = {

}
// actions
const actions = {
  [DNS_FETCH_DOMAINS] (context) {
    if (state.domains && state.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.domains)
      })
    } else {
      return ApiService.get('/app-api/dns/domains/list/json')
        .then(({ data }) => {
          context.commit(DNS_DOMAINS_SET, data)
          return data
        })
    }
  },
  [DNS_CREATE_DOMAIN] (context, params) {
    context.commit(DNS_DOMAINS_EXPIRED_SET)
    return ApiService.post('/app-api/dns/domains', params)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_DELETE_DOMAIN] (context, domainId) {
    context.commit(DNS_DOMAINS_EXPIRED_SET)
    return ApiService.delete(`/app-api/dns/domains/${domainId}`)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_FETCH_DOMAIN] (context, domainId) {
    return ApiService.get(`/app-api/dns/domains/${domainId}/records/list/json`)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_CREATE_RECORD] (context, params) {
    return ApiService.post(`/app-api/dns/domains/${params.domainId}/records/${params.type}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_EDIT_RECORD] (context, params) {
    return ApiService.put(`/app-api/dns/domains/${params.domainId}/records/${params.type}/${params.recordId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_DELETE_RECORD] (context, params) {
    return ApiService.delete(`/app-api/dns/domains/${params.domainId}/records/${params.recordId}`)
      .then(({ data }) => {
        return data
      })
  },
  [DNS_TASK_DOMAIN] (context, taskId) {
    return ApiService.get(`/app-api/dns/domains/tasks/${taskId}`)
      .then(({ data }) => {
        context.commit(DNS_DOMAINS_EXPIRED_SET)
        return data
      })
  },
  [DNS_TASK_RECORD] (context, params) {
    return ApiService.get(`/app-api/dns/domains/${params.domainId}/records/tasks/${params.taskId}`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [DNS_TTL_SET] (state, ttl) {
    state.ttl = ttl
  },
  [DNS_DOMAINS_SET] (state, domains) {
    state.domains = domains
    state.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_LIST_EXPIRE_MS)).getTime()
  },
  [DNS_DOMAINS_EXPIRED_SET] (state) {
    state.expire = null
  },
  [COMMON_CLEAR_CACHE] (state) {
    state.expire = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
