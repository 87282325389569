let Emitter = require('tiny-emitter')
let emitter = new Emitter()

export const eventTypes = {
  ADD: 'add',
  ADD_MODEL_ERRORS: 'add-model-errors',
}

export const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}