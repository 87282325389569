<template>
  <div
    class="panel-header"
    :class="{
      'ie-warning': isBrowserIe,
      'mobile-menu-open': $store.state.menu.mobile,
    }"
  >
    <span
      v-if="!$store.state.menu.mobile"
      class="panel-header-menu"
    >
      <router-link-project
        v-if="$store.state.app.data"
        :to="{ name: 'default' }"
        class="panel-header-menu-logo"
      >
        <img
          class="panel-header-menu-logo-img"
          :src="partnerLogoPath"
          height="40px"
        >
      </router-link-project>
      <router-link
        v-else
        :to="{ name: 'default' }"
        class="panel-header-menu-logo"
      >
        <img
          class="panel-header-menu-logo-img"
          src="@/assets/img/project-default-logo/default-logo.svg"
          height="40px"
          alt="default logo"
        >
      </router-link>
    </span>
    <span class="panel-header-actions">
      <span class="panel-header-actions-left">
        <span class="panel-header-actions-create" />
        <!-- <SearchInput /> -->
      </span>
      <span class="panel-header-actions-right">
        <span
          v-if="showBalance"
          class="panel-header-actions-billing"
        >
          <Balance />
          <BaseButton
            v-if="showAddMoneyBtn"
            class="panel-header-actions-billing-btn add-money"
            size="small"
            reverse
            @click="toAddMoney"
          >
            {{ $t('layout.header.billing.add-money') }}
          </BaseButton>
        </span>
        <TestPaid
          v-if="!isMobile"
          class="test-paid-btn"
        />
        <span class="panel-header-actions-control">
          <div
            v-if="showAddProjectBtn"
            class="icon-project-add mobile-hide"
            @click="showProjectCreate = true"
          >
            <span>+</span>
          </div>
          <Account
            class="mobile-hide"
            @dark-theme="darkTheme = $event"
          />
          <Balance
            v-if="showBalance"
            class="mobile-balance"
          />
          <div class="panel-header-mobile-menu">
            <span
              class="panel-header-hamburger"
              :class="{ 'open' : $store.state.menu.mobile }"
              @click="openMobileMenu"
            >
              <div />
            </span>
          </div>
        </span>
      </span>
    </span>
    <LogonAs />
    <PopupProjectCreate
      v-if="showProjectCreate"
      @close="showProjectCreate=false"
      @success="onProjectCreate"
    />
  </div>
</template>

<script>
import BaseButton from '../base/BaseButton.vue'
import Balance from '../features/billing/shared/Balance.vue'
import Account from '../features/account/shared/Account.vue'
import LogonAs from '../features/account/shared/LogonAs.vue'
import feature from '@/feature'
// import SearchInput from '@/components/shared/SearchInput.vue'
import PopupProjectCreate from '../features/projects/shared/PopupProjectCreate.vue'
import { COMMON_APP_SETTINGS } from '@/store/actions.type'
import { COMMON_MOBILE_MENU } from '@/store/mutations.type'
import partner from '@/partner'
import TestPaid from '@/components/features/billing/shared/TestPaid.vue'

export default {
  components: { BaseButton, Balance, Account, LogonAs, PopupProjectCreate, TestPaid },
  props: {
    isBrowserIe: { type: Boolean, required: false },
  },
  data () {
    return {
      features: feature.features,
      showProjectCreate: false,
      publicPath: process.env.BASE_URL,
      darkTheme: this.$store.state.isDarkTheme,
      isMobile: false,
    }
  },
  computed: {
    partnerLogoPath () {
      const partnerName = this.$store.state.app.data.PartnerName

      if (!Object.values(partner.partners).includes(partnerName)) {
        return this.darkTheme
          ? `${this.publicPath}project-logos/default/logo-white.svg`
          : `${this.publicPath}project-logos/default/logo.svg`
      }

      if (partner.isPartnerName(partner.partners.ITGLOBAL) || partner.isPartnerName(partner.partners.SS_TEST) || !partner.isSS()) {
        return this.darkTheme
          ? `${this.publicPath}project-logos/${partnerName}/logo-white.svg`
          : `${this.publicPath}project-logos/${partnerName}/logo.svg`
      } else {
        return this.darkTheme
          ? `${this.publicPath}project-logos/ss/logo-white.svg`
          : `${this.publicPath}project-logos/ss/logo.svg`
      }
    },
    showAddProjectBtn () {
      return feature.isActiveFeature(feature.features.MultiProjects)
    },
    financesActive () {
      return feature.isActiveFeature(feature.features.Finances)
    },
    showBalance () {
      if (feature.isAnyActiveFeature(feature.features.PaymentGateway, feature.features.PaymentInvoice) && partner.isPartnerName(partner.partners.OBIT)) {
        return true
      } else if (!partner.isPartnerName(partner.partners.OBIT) && this.financesActive) {
        return true
      } else {
        return false
      }
    },
    showAddMoneyBtn () {
      return !partner.isPartnerName(partner.partners.MIRAN)
    },
  },
  created () {
    this.isMobile = window.innerWidth < 530
  },
  methods: {
    toAddMoney () {
      if (this.$route.name !== 'billing-add-money') {
        this.$router.push({ name: 'billing-add-money' })
      }
    },
    isAnyActive (...list) {
      return feature.isAnyActiveFeature(...list)
    },
    onProjectCreate (projectId) {
      if (this.$store.state.project.current.Id === projectId) {
        this.$store.dispatch(COMMON_APP_SETTINGS, { pid: projectId, isForce: true })
          .then(() => {
            this.showProjectCreate = false
            this.$router.push({ name: 'account-manage', query: { pid: projectId }, hash: '#Project' })
          })
      } else {
        this.showProjectCreate = false
        this.$router.push({ name: 'account-manage', query: { pid: projectId }, hash: '#Project' })
      }
    },
    openMobileMenu () {
      let mobileState = !this.$store.state.menu.mobile

      this.$store.commit(COMMON_MOBILE_MENU, mobileState)
    },
  },
}
</script>

<style lang="less" scoped>
.panel-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10010;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-light);
  border-bottom: 1px solid var(--color-border);

  &-mobile-menu {
    display: none;
    justify-content: center;
    width: 32px;
    margin-left: 32px;
  }

  &-hamburger {
    position: relative;
    display: block;
    width: 24px;
    cursor: pointer;

    &::before,
    &::after,
    & div {
      display: block;
      height: 2px;
      margin: 5px 0;
      content: "";
      background: var(--color-base);
      border-radius: 3px;
      transition: 0.5s;
    }

    &.open::before {
      transform: translateY(7px) rotate(135deg);
    }

    &.open::after {
      transform: translateY(-7px) rotate(-135deg);
    }

    &.open div {
      transform: scale(0);
    }
  }

  &.ie-warning {
    top: 40px;
  }

  &-menu {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: var(--menu-width);
    min-width: 200px;
    padding: 0 15px;
    padding-left: var(--margin-default);
    transition: all var(--menu-transition);

    &-logo {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-decoration: none;
      background-repeat: no-repeat;
      transition: all var(--menu-transition);

      &-img {
        max-width: 200px;
        height: 32px;
        vertical-align: middle;
        object-fit: contain;

        &--small {
          max-width: 40px;
        }
      }
    }
  }

  &-actions {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-right: var(--margin-default);

    &-left {
      display: flex;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    &-billing {
      display: flex;
      align-items: center;
      margin-left: var(--margin-default);

      &-btn {
        margin-left: var(--margin-default);
      }
    }

    &-control {
      display: flex;
      align-items: center;
      margin-left: var(--margin-default);
    }
  }
}

.mobile-balance {
  display: none;
}

.icon-project-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 15px;
  cursor: pointer;
  border: 1px solid var(--color-border-secondary);
  border-radius: 50%;

  & span {
    display: flex;
    width: 17px;
    height: 16px;
    font-size: 27px;
    font-weight: 200;
    line-height: 13px;
  }
}

.icon-project-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 15px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid var(--color-border-secondary);
  border-radius: 50%;
}

.test-paid-btn {
  display: block;
}
@media (max-width: 1200px) {
  .panel-header-menu {
    width: auto;
    min-width: unset;
    padding-right: 50px;
  }

  .panel-header-menu-logo-img {
    max-width: 120px;
  }
}

@media (max-width: 820px) {
  .panel-header-actions-billing-btn.add-money {
    display: none;
  }

  .panel-header-mobile-menu {
    display: flex;
  }

  .panel-header-actions {
    margin-right: 24px;
  }
}

@media (max-width: 600px) {
  .mobile-menu-open {
    border-bottom: none;
  }

  .mobile-balance {
    display: block;
    margin-left: 0;
  }

  .mobile-hide,
  .panel-header-actions-billing {
    display: none;
  }

  .panel-header-actions {
    margin-right: 16px;
  }

  .panel-header-menu {
    padding-left: 16px;
  }

  .mobile-menu-open {
    & .mobile-hide {
      display: flex;
    }

    & .panel-header-actions {
      display: block;
      width: 100%;
    }

    & .panel-header-actions-control {
      width: 100%;
      margin-left: 16px;
    }

    & .panel-header-mobile-menu {
      margin-left: auto;
    }
  }

  .panel-header-actions {
    margin-right: 16px;
  }
}

@media (max-width: 530px) {
  .panel-header-actions-control {
    margin-left: 5px;
  }

  .test-paid-btn {
    display: none;
  }
}
</style>
