
import ApiService from '@/api'

import {
  CONFIGURED_CALCULATOR_FETCH,
  CONFIGURED_SERVER_CREATE,
  CONFIGURED_SERVERS_FETCH,
  CONFIGURED_SERVER_FETCH,
  CONFIGURED_SERVER_ORDER_FETCH,
  CONFIGURED_TASK_FETCH,
  CONFIGURED_SERVER_DELETE,
  CONFIGURED_SERVER_ORDER_CANCEL,
  CONFIGURED_USER_ADD,
  CONFIGURED_USER_DELETE,
  CONFIGURED_SERVER_REBOOT,
  CONFIGURED_SERVER_RESET,
  CONFIGURED_BANDWIDTH_EDIT,
} from '../actions.type'

import { CONFIGURED_SERVER_CALCULATOR_SET, CONFIGURED_SERVERS_SET } from '../mutations.type'

const state = {
  servers: {
    calculator: {
      data: null,
    },
    list: {
      data: null,
    },
  },
}

// getters
const getters = {
}

// actions
const actions = {
  [CONFIGURED_CALCULATOR_FETCH] (context) {
    return ApiService.get('/app-api/configured-servers/create/json')
      .then(({ data }) => {
        context.commit(CONFIGURED_SERVER_CALCULATOR_SET, data)
        return data
      })
  },
  [CONFIGURED_SERVER_CREATE] (context, params) {
    return ApiService.post(`/app-api/configured-servers/${params.template}/orders`, params.data)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVERS_FETCH] (context) {
    return ApiService.get('/app-api/configured-servers/list/json')
      .then(({ data }) => {
        context.commit(CONFIGURED_SERVERS_SET, data)
        return data
      })
  },
  [CONFIGURED_SERVER_FETCH] (context, params) {
    return ApiService.get(`/app-api/configured-servers/${params.serverId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVER_ORDER_FETCH] (context, orderId) {
    return ApiService.get(`/app-api/configured-servers/orders/${orderId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_TASK_FETCH] (context, orderId) {
    return ApiService.get(`/app-api/configured-servers/sagas/${orderId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVER_DELETE] (context, params) {
    return ApiService.delete(`/app-api/configured-servers/${params.serverId}`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVER_ORDER_CANCEL] (context, orderId) {
    return ApiService.delete(`/app-api/configured-servers/orders/${orderId}`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_USER_ADD] (context, params) {
    return ApiService.post(`/app-api/configured-servers/${params.serverId}/users`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_USER_DELETE] (context, params) {
    return ApiService.delete(`/app-api/configured-servers/${params.serverId}/users`, { data: params.model })
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVER_REBOOT] (context, params) {
    return ApiService.post(`/app-api/configured-servers/${params.serverId}/reboot`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_SERVER_RESET] (context, params) {
    return ApiService.post(`/app-api/configured-servers/${params.serverId}/factory_reset`)
      .then(({ data }) => {
        return data
      })
  },
  [CONFIGURED_BANDWIDTH_EDIT] (context, model) {
    return ApiService.post(`/app-api/configured-servers/${model.serverId}/bandwidth`, model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [CONFIGURED_SERVER_CALCULATOR_SET] (state, calculator) {
    state.servers.calculator.data = calculator
  },
  [CONFIGURED_SERVERS_SET] (state, servers) {
    state.servers.list.data = servers
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
