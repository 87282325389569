<template>
  <button
    :class="classData"
    :disabled="disabled"
    @click="callback($event)"
  >
    <span
      v-if="more"
      class="more-arrow"
      :class="{ 'up': arrowDirection }"
    />
    <VueSpinner
      v-if="submit"
      :value="submit"
      :is-full-page="false"
      :width="loadingSize.width"
      :height="loadingSize.height"
      is-small
    />
    <slot v-if="!submit" />
  </button>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';

export default {
  name: 'BaseButton',
  components: {
    VueSpinner,
  },
  props: {
    size: { type: String, default: 'big' },
    reverse: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    submit: { type: Boolean, default: false },
    more: { type: Boolean, default: null },
    arrowDirection: { type: Boolean, default: false },
  },
  emits: ['click'],
  computed: {
    classData () {
      return [
        'vue-button',
        `vue-button--${this.size}`,
        {
          disabled: this.disabled,
          reverse: this.reverse,
          danger: this.danger,
          more: this.more,
        },
      ]
    },
    loadingSize () {
      switch (this.size) {
        case 'small':
          return {
            width: 25,
          }
        case 'big':
        default:
          return {
            width: 30,
          }
      }
    },
  },
  methods: {
    callback: function (e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="less" scoped>
.vue-button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: inherit;
  font-size: 14px;
  color: var(--color-light-secondary);
  cursor: pointer;
  background: var(--color-primary);
  border: 0;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;

  &.more {
    min-width: unset;

    &:hover {
      & .more-arrow {
        &::after {
          border-color: var(--color-inverse);
        }
      }
    }
  }

  & .more-arrow {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 12px;

    &::after {
      position: absolute;
      top: 1px;
      right: 2px;
      display: block;
      width: 8px;
      height: 8px;
      content: " ";
      border-color: #8497bc;
      border-style: none solid solid none;
      border-width: 1.5px;
      transition: all 0.2s;
      transform: rotate(45deg);
    }

    &.up {
      &::after {
        top: 5px;
        transform: rotate(224deg);
      }
    }
  }

  &:hover {
    background: var(--color-primary-hover);
  }

  &:active {
    background: var(--color-primary-active);
  }

  &.danger {
    background-color: var(--color-danger);

    &:hover {
      background-color: var(--color-danger-hover);
    }

    &:active {
      background-color: var(--color-danger-active);
    }
  }

  &.reverse {
    color: var(--color-base);
    background-color: transparent;
    border: 1px solid var(--color-border-secondary);

    :deep(.vld-overlay svg) {
      stroke: var(--color-primary);
    }

    &:hover {
      color: var(--color-light-secondary);
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary-hover);

      :deep(.vld-overlay svg) {
        stroke: var(--color-light);
      }
    }

    &:active {
      color: var(--color-light);
      background: var(--color-primary-active);
      border: 1px solid var(--color-primary-active);

      :deep(.vld-overlay svg) {
        stroke: var(--color-light);
      }
    }

    &.danger {
      color: var(--color-danger);
      background-color: var(--color-light);

      &:hover {
        color: var(--color-light);
        background-color: var(--color-danger-hover);
        border: 1px solid var(--color-danger-hover);
      }

      &:active {
        color: var(--color-light);
        background-color: var(--color-danger-active);
        border: 1px solid var(--color-danger-active);
      }
    }
  }

  &.disabled {
    &:hover {
      border: none !important;
    }

    color: var(--color-state-disabled) !important;
    cursor: initial;
    background: var(--color-disabled-primary) !important;

    :deep(.vue-add-btn-icon svg line) {
      stroke: var(--color-state-disabled);
    }
  }

  &--big {
    min-width: 200px;
    height: 40px;
    padding: 0 22px;
  }

  &--med {
    min-width: 130px;
    height: 37px;
    padding: 0 17px;
  }

  &--small {
    min-width: 105px;
    height: 32px;
    padding: 0 15px;
    line-height: 22px;
  }

  .vue-spinner {
    :deep(.path) {
      stroke-width: 3px;
    }
  }
}

@media (max-width: 530px) {
  .vue-button {
    &--big {
      height: 37px;
    }
  }
}
</style>
