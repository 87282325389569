import {
  STORAGE_DETAIL_FETCH,
  STORAGE_TOKEN_FETCH,
  STORAGE_ACTIVATE,
  STORAGE_DEACTIVATE,
  STORAGE_TASK_FETCH,
  STORAGE_FOLDER_FETCH,
  STORAGE_FILE_DOWNLOAD,
  STORAGE_FOLDER_CREATE,
  STORAGE_CONTAINER_CREATE,
  STORAGE_OBJECT_DELETE,
  STORAGE_FILE_UPLOAD,
  STORAGE_FILE_COPY,
  STORAGE_CONTAINER_INFO,
  STORAGE_CONTAINER_METADATA_UPDATE,
  STORAGE_OBJECTS_DELETE_BULK,
} from '../actions.type'

import ApiService from '@/api'
import ApiStorageService from '@/components/features/storage/api'
import { STORAGE_DETAIL_SET, STORAGE_TOKEN_SET, STORAGE_FILE_COPY_SET, STORAGE_FILE_MOVE_SET, COMMON_CLEAR_CACHE } from '../mutations.type'

import mime from 'mime-types'

import FileDownload from 'js-file-download'

// state
const state = {
  detail: null,
  token: null,
  file: null,
  isCopy: true,
  isMove: false,
}

// getters
const getters = {
}

// actions
const actions = {
  [STORAGE_DETAIL_FETCH] (context) {
    return ApiService.get('/app-api/storage/detail/json')
      .then(({ data }) => {
        context.commit(STORAGE_DETAIL_SET, data)
        return data
      })
  },
  [STORAGE_TOKEN_FETCH] (context) {
    return ApiService.post('/app-api/storage/auth-token')
      .then(({ data }) => {
        context.commit(STORAGE_TOKEN_SET, data)
        return data
      })
  },
  [STORAGE_ACTIVATE] () {
    return ApiService.post(`/app-api/storage/activate`)
      .then(({ data }) => {
        return data
      })
  },
  [STORAGE_DEACTIVATE] (context) {
    return ApiService.post(`/app-api/storage/deactivate`)
      .then(({ data }) => {
        context.commit(STORAGE_DETAIL_SET, null)
        return data
      })
  },
  [STORAGE_TASK_FETCH] (context, taskId) {
    return ApiService.get(`/app-api/storage/task/${taskId}`)
      .then(({ data }) => {
        return data
      })
  },
  [STORAGE_FOLDER_FETCH] (context, params) {
    return ApiStorageService.get(params.container, { params: params.params })
      .then(({ data }) => {
        return data
      })
  },
  [STORAGE_FILE_DOWNLOAD] (context, params) {
    return ApiStorageService.get(params.path, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, params.name)
      })
  },
  [STORAGE_FILE_UPLOAD] (context, params) {
    let contentType = params.file.type || mime.lookup(params.file.name)
    let config = Object.assign(
      {
        headers: {
          'Content-Type': contentType,
        },
      },
      params.config
    )
    return ApiStorageService.put(`${params.path}/${params.file.name}`, params.file, config)
  },
  [STORAGE_FILE_COPY] (context, params) {
    let config = {
      headers: {
        'X-Copy-From': encodeURIComponent(params.copyFrom),
        'Content-Type': params.content_type,
      },
    }
    return ApiStorageService.put(params.copyTo, null, config)
  },
  [STORAGE_CONTAINER_CREATE] (context, params) {
    return ApiStorageService.put(params.name, null, {
      headers: {
        'X-Container-Read': params.isPublic ? '.r:*,.rlistings' : '',
        'X-Container-Meta-Access-Control-Expose-Headers': 'X-Container-Read',
      },
    })
      .then(({ data }) => {
        context.dispatch(STORAGE_CONTAINER_METADATA_UPDATE, params)
        return data
      })
  },
  [STORAGE_CONTAINER_INFO] (context, path) {
    return ApiStorageService.head(path, {
      headers: {
        'X-Container-Meta-Access-Control-Expose-Headers': 'X-Container-Read',
      },
    })
      .then(response => {
        let data = {
          isPublic: !!(response.headers['x-container-read'] && response.headers['x-container-read'].indexOf('.r:') > -1),
        }
        return data
      })
  },
  [STORAGE_CONTAINER_METADATA_UPDATE] (context, params) {
    return ApiStorageService.post(params.name, null, {
      headers: {
        'X-Container-Read': params.isPublic ? '.r:*,.rlistings' : '',
        'X-Container-Meta-Access-Control-Expose-Headers': 'X-Container-Read',
      },
    })
      .then(data => {
        return data
      })
  },
  [STORAGE_FOLDER_CREATE] (context, path) {
    return ApiStorageService.put(path, null, {
      headers: {
        'Content-Type': 'application/directory',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [STORAGE_OBJECT_DELETE] (context, path) {
    return ApiStorageService.delete(encodeURIComponent(path))
      .then((response) => {
        return response
      })
  },
  [STORAGE_OBJECTS_DELETE_BULK] (context, objects) {
    return ApiStorageService.post('/app-api/?format=json&bulk-delete=true', objects.join('\r\n'), {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [STORAGE_DETAIL_SET] (state, detail) {
    state.detail = detail
  },
  [STORAGE_TOKEN_SET] (state, token) {
    state.token = token
  },
  [STORAGE_FILE_COPY_SET] (state, file) {
    state.file = file
    state.isMove = false
    state.isCopy = true
  },
  [STORAGE_FILE_MOVE_SET] (state, file) {
    state.file = file
    state.isMove = true
    state.isCopy = false
  },
  [COMMON_CLEAR_CACHE] (state) {
    state.detail = null
    state.token = null
    state.file = null
    state.isCopy = false
    state.isMove = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
