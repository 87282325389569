<template>
  <div
    class="base-checkbox"
    :value="value"
    :class="{'checked': checked, 'disabled': disabled }"
    @click="changeHandler"
  >
    <span class="icon"><span
      v-show="checked"
      class="checkbox-mark"
    /></span>
    <span class="label">
      <slot>{{ label }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckBox',
  props: {
    value: { type: [String, Number, Boolean, Object], default: '' },
    modelValue: { type: [String, Number, Boolean, Array, Object], default: false },
    label: { type: String, default: 'checkbox' },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    unchecked: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      uncheckedFlag: false,
    }
  },
  computed: {
    checked () {
      if (Array.isArray(this.modelValue)) {
        if (!this.modelValue || !this.modelValue.length) { return false }

        return this.modelValue.includes(this.value)
      } else {
        return this.modelValue === this.trueValue
      }
    },
  },
  created () {
    if (this.unchecked) {
      this.uncheckedFlag = this.unchecked
    }
  },
  methods: {
    changeHandler () {
      if (this.disabled) {
        return
      }

      if (Array.isArray(this.modelValue)) {
        let values = [].concat(this.modelValue)
        if (values.includes(this.value)) {
          values.splice(values.indexOf(this.value), 1)
        } else {
          values.push(this.value)
        }
        this.$emit('update:modelValue', values)
      } else {
        this.$emit('update:modelValue', this.checked ? this.falseValue : this.trueValue)
      }
    },
  },

}
</script>

<style lang="less" scoped>
.base-checkbox {
  display: flex;
  cursor: pointer;

  .label {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-base);
    user-select: none;
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 1px;
    margin-right: 8px;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    transition: border-color 0.15s ease-in-out, background 0.15s ease-in-out;
  }

  .checkbox-mark {
    width: 8px;
    height: 5px;
    margin-bottom: 3px;
    border: 1px solid white;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
  }

  &.disabled {
    .icon {
      background: var(--color-disabled-primary) !important;
    }

    .label {
      color: var(--color-disabled-secondary) !important;
    }

    cursor: default;
  }

  &:hover {
    .icon {
      padding: 0;
      border: 1px solid var(--color-primary-hover);
    }
  }

  &.checked {
    .icon {
      padding: 0;
      background-color: var(--color-primary);
      border: 0;
    }

    &:hover .icon {
      background-color: var(--color-primary-hover);
    }
  }
}

@media (max-width: 530px) {
  .base-checkbox {
    & .label {
      font-size: 12px;
    }
  }
}
</style>
