import {
  K8S_CALCULATOR_FETCH,
  K8S_CLUSTER_CREATE,
  K8S_CLUSTER_DETAIL_FETCH,
  K8S_TASK_FETCH,
  K8S_CLUSTER_LIST_FETCH,
  K8S_ORDER_CANCEL,
  K8S_ORDER_DETAIL_FETCH,
  K8S_CLUSTER_DELETE,
  K8S_CLUSTER_GROUP_EDIT,
  K8S_CLUSTER_GROUP_ADD,
  K8S_CLUSTER_GROUP_DELETE,
  K8S_CLUSTER_GROUP_INGRESS_ENABLE,
  K8S_CLUSTER_TAG_ADD,
  K8S_CLUSTER_TAG_REMOVE,
  K8S_CLUSTER_UPGRADE,
  K8S_DASHBOARD_ACTIVATE,
} from '../actions.type'

import { COMMON_CLEAR_CACHE, K8S_CLUSTER_CALCULATOR_SET } from '../mutations.type'

import ApiService from '@/api'

const state = {
  cluster: {
    calculator: {
      data: null,
      expire: null,
    },
  },
}

// getters
const getters = {
}
// actions
const actions = {
  [K8S_CLUSTER_LIST_FETCH] () {
    return ApiService.get('/app-api/kubernetes/clusters/json')
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CALCULATOR_FETCH] (context) {
    if (state.cluster.calculator.expire && state.cluster.calculator.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.cluster.calculator.data)
      })
    } else {
      return ApiService.get('/app-api/kubernetes/clusters/create/json')
        .then(({ data }) => {
          context.commit(K8S_CLUSTER_CALCULATOR_SET, data)
          return data
        })
    }
  },
  [K8S_CLUSTER_CREATE] (context, model) {
    return ApiService.post('/app-api/kubernetes/clusters', model)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_DELETE] (context, clusterId) {
    return ApiService.delete(`/app-api/kubernetes/clusters/${clusterId}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_DETAIL_FETCH] (context, clusterId) {
    return ApiService.get(`/app-api/kubernetes/clusters/${clusterId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_UPGRADE] (context, params) {
    return ApiService.post(`/app-api/kubernetes/clusters/${params.clusterId}/upgrade`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_GROUP_EDIT] (context, params) {
    return ApiService.put(`/app-api/kubernetes/clusters/${params.clusterId}/nodesgroups/${params.groupId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_GROUP_ADD] (context, params) {
    return ApiService.post(`/app-api/kubernetes/clusters/${params.clusterId}/nodesgroups`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_GROUP_DELETE] (context, params) {
    return ApiService.delete(`/app-api/kubernetes/clusters/${params.clusterId}/nodesgroups/${params.groupId}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_GROUP_INGRESS_ENABLE] (context, params) {
    return ApiService.post(`/app-api/kubernetes/clusters/${params.clusterId}/nodesgroups/${params.groupId}/ingress`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_TAG_ADD] (context, params) {
    return ApiService.post(`/app-api/kubernetes/clusters/${params.clusterId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_CLUSTER_TAG_REMOVE] (context, params) {
    return ApiService.delete(`/app-api/kubernetes/clusters/${params.clusterId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_TASK_FETCH] (context, taskId) {
    return ApiService.get(`/app-api/kubernetes/tasks/${taskId}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_ORDER_DETAIL_FETCH] (context, orderId) {
    return ApiService.get(`/app-api/kubernetes/clusters/orders/${orderId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_ORDER_CANCEL] (context, orderId) {
    return ApiService.delete(`/app-api/kubernetes/clusters/orders/${orderId}`)
      .then(({ data }) => {
        return data
      })
  },
  [K8S_DASHBOARD_ACTIVATE] (context, params) {
    return ApiService.post(`/app-api/kubernetes/clusters/${params.ClusterId}/dashboard`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [K8S_CLUSTER_CALCULATOR_SET] (state, calculator) {
    state.cluster.calculator.data = calculator
    state.cluster.calculator.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_CALCULATOR_EXPIRE_MS)).getTime()
  },
  [COMMON_CLEAR_CACHE] (state) {
    state.cluster.calculator.expire = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
