<template>
  <BaseInfoBlock
    v-if="showNotification && $route.name !== 'feedback-add'"
    class="notification"
    :class="{'notification--new-year' : isHappyHYnotify}"
    :type="notificationType"
    has-close
    @close="close"
  >
    <template #image>
      <span
        v-if="isHappyHYnotify"
        class="notification--new-year-img"
      />
    </template>
    <template #title>
      <span
        v-if="notification.Title && notification.Title.length"
      >
        {{ notification.Title }}
      </span>
    </template>
    <template #default>
      <div
        ref="descBlock"
        class="notification-desc"
        v-html="notification.Description"
      />
      <BaseButton
        v-if="isHappyHYnotify"
        size="small"
        class="notification-button"
        @click="toAddMoney"
      >
        {{ $t('notification.new-year.button') }}
      </BaseButton>
    </template>
  </BaseInfoBlock>
</template>

<script>
import { COMMON_NOTIFICATIONS_FETCH, COMMON_NOTIFICATION_CLOSE } from '@/store/actions.type'
import BaseInfoBlock from '@/components/shared/BaseInfoBlock.vue'
import BaseButton from '@/components/base/BaseButton.vue'
export default {
  components: {
    BaseInfoBlock,
    BaseButton,
  },
  data () {
    return {
      notification: null,
      submit: false,
    }
  },
  computed: {
    isHappyHYnotify () {
      return this.notification?.TypeNotificationMessageId === 'HappyNewYearNotify'
    },
    notificationType () {
      return this.notification.TechTitle === 'danget' ? 'danger' : this.notification.TechTitle
    },
    showNotification () {
      return this.notification instanceof Object
    },
  },
  watch: {
    '$route': function () {
      this.fetchData()
    },
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.$store.dispatch(COMMON_NOTIFICATIONS_FETCH)
        .then((notifications) => {
          if (notifications.length) {
            this.notification = notifications[0]
          } else {
            this.notification = null
          }
        })
        .then(() => {
          this.routingToBilling()
        })
    },
    close () {
      if (this.submit) {
        return
      }

      this.submit = true

      this.$store.dispatch(COMMON_NOTIFICATION_CLOSE, this.notification.Id)
        .then(() => {
          this.notification = null
          this.submit = false
          this.fetchData()
        })
        .catch(() => {
          this.submit = false
          this.fetchData()
        })
    },
    routingToBilling () {
      if (this.isHappyHYnotify) {
        const billingLink = this.$refs.descBlock.querySelector('a')
        billingLink.addEventListener('click', (e) => {
          e.preventDefault()
          this.$router.push({ name: 'billing-add-money' })
        })
      }
    },
    toAddMoney () {
      if (this.$route.name !== 'billing-add-money') {
        this.$router.push({ name: 'billing-add-money' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.notification {
  margin-bottom: var(--margin-default);

  &-desc {
    * {
      white-space: break-spaces;
    }

    .AddFeedbackFormLink,
    .AddSuccessStoryFeedbackFormLink {
      display: block;
      width: 200px;
      height: 40px;
      padding: 0 20px;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 600;
      line-height: 40px;
      color: var(--color-light);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      background-color: var(--color-primary);
      border-radius: var(--border-radius);
      transition: all 0.2s ease-in-out;

      &:hover {
        background: var(--color-primary-hover);
      }
    }
  }

  &-button {
    width: max-content;
    margin-top: 20px;
  }

  &--new-year {
    position: relative;
    padding-right: 315px;

    :deep(.notification-desc) {
      p:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    :deep(h3) {
      margin-bottom: 10px;
    }

    &-img {
      position: absolute;
      top: 0;
      right: 0;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        order: 1;
        width: 317px;
        height: 146px;
        content: '';
        background-image: url(~@/assets/img/new-year/boxes-lights.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 1000px) {
  .notification--new-year {
    &-img::after {
      display: none;
    }

    padding-right: 30px;
  }
}
</style>
