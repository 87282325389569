import {
  TAGS_FETCH,
} from '../actions.type'

import ApiService from '@/api'

const state = {

}

// getters
const getters = {

}
// actions
const actions = {
  [TAGS_FETCH] () {
    return ApiService.get('/app-api/tags/list/json')
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations,
}
