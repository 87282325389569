<template>
  <span
    class="account"
    :class="{'open': isOpen}"
  >
    <div
      ref="icon"
      class="account-icon"

      @click="switchOpen"
    >
      <span class="span account-icon-symbol">
        {{ $store.state.app.data.User.Email[0].toUpperCase() }}
      </span>
    </div>
    <span
      class="account-menu"
      :class="{ 'minified': withoutMultiProject }"
    >
      <span class="account-menu-login">{{ $store.state.app.data.User.Email.split("@")[0] }}</span>
      <span class="account-menu-email">{{ $store.state.app.data.User.Email }}</span>
      <span class="account-menu-separator" />
      <router-link-project
        v-if="showFinance"
        :to="{ name: 'billing' }"
        class="account-menu-link"
        @click="isOpen = false"
      >
        {{ $t('account.shared.account.billing') }}
      </router-link-project>
      <router-link-project
        v-if="feature.isActiveFeature(feature.features.Support)"
        :to="{ name: 'tickets-list' }"
        class="account-menu-link"
        @click="isOpen = false"
      >
        {{ $t('account.shared.account.support') }}
      </router-link-project>
      <a
        v-if="feature.isActiveFeature(feature.features.SimpleOneSupport)"
        target="_blank"
        :href="simpleOneSupportLink"
        class="account-menu-link"
      >
        {{ $t('layout.menu.features.support') }}
      </a>
      <router-link-project
        :to="{ name: 'account-manage' }"
        class="account-menu-link"
        @click="isOpen = false"
      >
        {{ $t('account.shared.account.settings') }}
      </router-link-project>
      <span class="account-menu-separator" />
      <div class="dark-theme-block">
        <span>{{ $t('account.shared.account.dark-theme') }}</span>
        <div
          class="dark-theme-block-switcher"
          :class="{'active' : darkThemeEnabled}"
          @click="themeSwitch"
        >
          <div class="status-circle" />
        </div>
      </div>
      <span class="account-menu-separator" />
      <a
        class="account-menu-link account-menu-logout"
        href="#"
        @click="signout"
      >
        <span
          class="account-menu-logout-icon"
          v-html="ICON_LOGOUT"
        />
        <span>{{ $t('account.shared.account.signout') }}</span>
      </a>
    </span>
    <form
      v-show="false"
      ref="form"
      method="post"
      :action="`/app-api/account/signout?pid=${$store.state.project.current.Id}`"
    />
  </span>
</template>

<script>
import feature from '@/feature'
import { SIMPLEONE_GET_LINK_PORTAL, SIMPLEONE_GET_LINK_PORTAL_ESSENCE } from '@/store/getters.type'
import { PROJECT_DARK_THEME_TOGGLE } from '@/store/actions.type'
import { ICON_LOGOUT } from '@/svg/icons'
import { THEME_SET } from '@/store/mutations.type'

export default {
  emits: ['dark-theme'],
  data () {
    return {
      isOpen: false,
      darkThemeEnabled: false,
      feature: feature,
      ICON_LOGOUT,
    }
  },
  computed: {
    simpleOneSupportLink () {
      return this.$store.getters[SIMPLEONE_GET_LINK_PORTAL] + 'record/' + this.$store.getters[SIMPLEONE_GET_LINK_PORTAL_ESSENCE]
    },
    showFinance () {
      return feature.isActiveFeature(feature.features.Finances)
    },
    withoutMultiProject () {
      return !feature.isActiveFeature(feature.features.MultiProjects)
    },
  },
  created () {
    window.addEventListener('click', this.onClickHide)
    this.darkThemeEnabled = !!this.$store.state.isDarkTheme
  },
  beforeUnmount () {
    window.removeEventListener('click', this.onClickHide)
  },
  methods: {
    onClickHide (e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    },
    switchOpen () {
      this.isOpen = !this.isOpen
    },
    signout () {
      this.$refs.form.submit()
    },
    themeSwitch () {
      this.darkThemeEnabled = !this.darkThemeEnabled
      this.$store.dispatch(PROJECT_DARK_THEME_TOGGLE)

      this.$store.commit(THEME_SET, { theme: this.$store.state.themeDefault, isDark: this.darkThemeEnabled })

      this.$emit('dark-theme', this.darkThemeEnabled)
    },
  },
}
</script>

<style lang="less" scoped>
.account {
  position: relative;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    border: 1px solid var(--color-border-secondary);
    border-radius: 100%;

    &-symbol {
      line-height: 10px;
    }
  }

  &-menu {
    position: absolute;
    top: 50px;
    right: -27px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 280px;
    max-height: 0;
    padding: 0 0;
    overflow: hidden;
    background-color: var(--color-light);
    border-radius: var(--border-radius);
    opacity: 0;
    transition: all var(--menu-transition);

    &-login {
      padding: 0 24px;
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-email {
      padding: 0 24px;
      overflow: hidden;
      color: var(--color-disabled-secondary);
      text-overflow: ellipsis;
    }

    &-separator {
      display: flex;
      margin: 15px 0;
      border-top: 1px solid var(--color-disabled-primary);
    }

    &-link {
      display: flex;
      align-items: center;
      padding: 6px 24px;
      font-size: 14px;
      font-weight: 500;
      color: var(--color-base);
      text-decoration: none;

      &:hover {
        color: var(--color-primary);
        text-decoration: none;
        background-color: var(--color-background);
      }

      &::after {
        display: none !important;
      }
    }

    &-logout {
      display: flex;
      line-height: 12px;

      &-icon {
        margin-right: 10px;
      }
    }
  }

  &.open &-icon {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }

  &.open &-menu {
    max-height: 360px;
    padding: 15px 0;
    border: 1px solid var(--color-border);
    opacity: 1;
  }
}

.dark-theme-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  font-weight: 500;

  &-switcher {
    position: relative;
    width: 38px;
    height: 20px;
    cursor: pointer;
    background-color: var(--color-disabled-primary);
    border-radius: 21px;
    transition: all 0.3s;

    &:hover {
      background-color: var(--projects-color-border);
    }

    &.active {
      background-color: var(--color-primary);

      & .status-circle {
        top: 2px;
        right: 2px;
        left: unset;
        transition: all 0.3s;
      }
    }

    & .status-circle {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }
}

@media (max-width: 820px) {
  .account-menu {
    right: -92px;
  }
}

@media (max-width: 600px) {
  .account-menu {
    right: unset;
    left: -52px;

    &.minified {
      left: 0;
    }
  }
}
</style>
