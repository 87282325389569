const mSsby = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ssby')
const mSsio = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ssio')
const mSsus = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ssus')
const mSsru = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ssru')
const mSsin = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ssin')
const mSscomtr = () => import(/* webpackChunkName: "metrics_settings" */ './settings/sscomtr')
const mSs4testcom = () => import(/* webpackChunkName: "metrics_settings" */ './settings/ss4testcom')
const mDomru = () => import(/* webpackChunkName: "metrics_settings" */ './settings/domru')
const mObitru = () => import(/* webpackChunkName: "metrics_settings" */ './settings/obitru')
const mLincore = () => import(/* webpackChunkName: "metrics_settings" */ './settings/lincore')
const mYota = () => import(/* webpackChunkName: "metrics_settings" */ './settings/yota')
const mIaashub = () => import(/* webpackChunkName: "metrics_settings" */ './settings/iaashub')
const m1office = () => import(/* webpackChunkName: "metrics_settings" */ './settings/1office')

export function installMetrics () {
  let mMetric = null

  switch (window.document.domain) {
    case 'panel.serverspace.by':
      mMetric = mSsby
      break
    case 'my.serverspace.io':
      mMetric = mSsio
      break
    case 'my.serverspace.us':
      mMetric = mSsus
      break
    case 'my.serverspace.ru':
      mMetric = mSsru
      break
    case 'my.serverspace.in':
      mMetric = mSsin
      break
    case 'my.serverspace.com.tr':
      mMetric = mSscomtr
      break
    case 'panel.ss4test.com':
    case 'localhost':
      mMetric = mSs4testcom
      break
    case 'vdc.b2b.domru.ru':
      mMetric = mDomru
      break
    case 'vps.obit.ru':
      mMetric = mObitru
      break
    case 'my.lincore.kz':
      mMetric = mLincore
      break
    case 'my.yotacloud.com':
      mMetric = mYota
      break
    case 'my.iaashub.io':
      mMetric = mIaashub
      break
    case 'panel.1office.pro':
      mMetric = m1office
      break
  }

  if (mMetric) {
    mMetric()
      .then((module) => {
        module.default.install()
      })
  }
}

function yaGoal (evtCategory, goalId, evtType = 'click') {
  if (!window.yandexCounterId) {
    return
  }

  let yaCounter = (typeof window['yaCounter' + window.yandexCounterId] !== 'undefined') ? window['yaCounter' + window.yandexCounterId] : null
  if (!yaCounter) {
    return
  }

  let goal = evtType + '_' + goalId
  let params = {}
  params[evtCategory] = {}
  params[evtCategory][evtType] = {}
  params[evtCategory][evtType][goalId] = {}

  yaCounter.reachGoal(goal, params)
}

function yaHit (url) {
  let yaCounter = (typeof window['yaCounter' + window.yandexCounterId] !== 'undefined') ? window['yaCounter' + window.yandexCounterId] : null

  if (!yaCounter) {
    return
  }

  yaCounter.hit(url)
}

function gaGoal (evtCategory, goalId, evtType = 'click') {
  let ga = (typeof window.ga === 'function') ? window.ga : null
  if (!ga) {
    return
  }

  ga('send', 'event', evtCategory, evtType, goalId)
}

function ga4Goal (evtName, model) {
  let gtag = (typeof window.gtag === 'function') ? window.gtag : null
  if (!gtag) {
    return
  }

  gtag('event', evtName, model)
}

function allGoal (evtCategory, goalId, evtType = 'click') {
  yaGoal(evtCategory, goalId, evtType)
  gaGoal(evtCategory, goalId, evtType)
}

export const metrics = {
  yaHit,
  yaGoal,
  gaGoal,
  ga4Goal,
  allGoal,
}

export default metrics
