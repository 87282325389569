import 'babel-polyfill';
import 'scroll-behavior-polyfill';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import RouterLinkProject from './router/router-link-project.vue';
import store from './store';
import i18n from './i18n';
import FloatingVue from 'floating-vue'
import Clipboard from 'v-clipboard';
import AppNotifications from '@/plugins/app-notifications.js';
import AppMetrics from '@/plugins/app-metrics.js';
import Loading from '@/components/shared/Loading.vue';
import LoadScript from 'vue-plugin-load-script';
import AppPrice from '@/plugins/app-price.js';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'floating-vue/dist/style.css'
const app = createApp(App);

app.config.globalProperties.productionTip = false;

app.config.errorHandler = function (err) {
  if (err.name === 'ServerValidationError') {
    return false;
  } else {
    /* eslint-disable no-console */
    console.error(err);
    /* eslint-enable no-console */
  }
};
app.use(FloatingVue);
app.use(Clipboard);
app.use(AppNotifications);
app.use(AppMetrics);
app.use(LoadScript);
app.use(AppPrice);
app.use(PerfectScrollbar)
app.component('Loading', Loading);
app.component('RouterLinkProject', RouterLinkProject);

app.use(router);
app.use(store);
app.use(i18n);

app.mount('#app');
