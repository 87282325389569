import ApiService from '@/api'

import {
  VMWARE_SERVERS_FETCH,
  VMWARE_SERVER_CALCULATOR_FETCH,
  VMWARE_SERVER_CREATE,
  VMWARE_SERVER_DELETE,
  VMWARE_SERVER_DETAIL_FETCH,
  VMWARE_TASK_FETCH,
  VMWARE_SERVER_CONFIGURATION_CHANGE,
  VMWARE_SERVER_DISK_CREATE,
  VMWARE_SERVER_DISK_EDIT,
  VMWARE_SERVER_DISK_DELETE,
  VMWARE_SERVER_LICENSES_AVAILABALE_FETCH,
  VMWARE_SERVER_LICENSES_ADD,
  VMWARE_SERVER_POWER_ACTION,
  VMWARE_SERVER_SNAPSHOT_CREATE,
  VMWARE_SERVER_SNAPSHOT_RESTORE,
  VMWARE_SERVER_SNAPSHOT_DELETE,
  VMWARE_SERVER_NETWORKS_AVAILABALE_FETCH,
  VMWARE_SERVER_NIC_EDIT,
  VMWARE_SERVER_NETWORK_CLIENT_ADD,
  VMWARE_SERVER_NETWORK_SHARED_ADD,
  VMWARE_SERVER_NETWORK_DISCONNECT,
  VMWARE_SERVER_FIREWALL_RULE_VALIDATE,
  VMWARE_SERVER_FIREWALL_UPDATE,
  VMWARE_NETWORKS_FETCH,
  VMWARE_NETWORK_CALCULATOR_FETCH,
  VMWARE_NETWORK_CREATE,
  VMWARE_NETWORK_FETCH,
  VMWARE_NETWORK_CONNECT,
  VMWARE_NETWORK_EDIT,
  VMWARE_NETWORK_DELETE,
  VMWARE_TEMPLATES_FETCH,
  VMWARE_TEMPLATES_SERVERS,
  VMWARE_TEMPLATE_CREATE,
  VMWARE_TEMPLATE_DELETE,
  VMWARE_SERVER_REBUILD_GET_IMAGES,
  VMWARE_SERVER_REBUILD,
  VMWARE_SERVER_COPY,
  VMWARE_SERVER_BACKUP_SETTINGS,
  VMWARE_SERVER_BACKUP_DISABLE,
  VMWARE_SERVER_BACKUP_RESTORE,
  VMWARE_SERVER_CONSOLE_TOKEN_FETCH,
  VMWARE_NETWORK_TAG_REMOVE,
  VMWARE_NETWORK_TAG_ADD,
  VMWARE_SERVER_TAG_ADD,
  VMWARE_SERVER_TAG_REMOVE,
  VMWARE_NETWORK_NAT_CREATE,
  VMWARE_NETWORK_NAT_EDIT,
  VMWARE_NETWORK_NAT_DELETE,
  VMWARE_NETWORK_VPN_CREATE,
  VMWARE_NETWORK_VPN_EDIT,
  VMWARE_NETWORK_VPN_DELETE,
  VMWARE_NETWORK_VPN_STATE,
  VMWARE_NETWORK_FIREWALL_RULE_VALIDATE,
  VMWARE_NETWORK_FIREWALL_UPDATE,
  VMWARE_SERVER_RENAME,
  VMWARE_TEMPLATE_DETAIL,
  VMWARE_SERVER_PERFORMANCE,
  VMWARE_SERVER_GET_REASON,
  VMWARE_SERVER_SEND_REASON,
} from '../actions.type'

import { VMWARE_SERVER_CALCULATOR_SET, VMWARE_SERVERS_SET, VMWARE_SERVERS_EXPIRED_SET, VMWARE_NETWORK_CALCULATOR_SET, VMWARE_NETWORKS_SET, VMWARE_NETWORKS_EXPIRED_SET, COMMON_CLEAR_CACHE } from '../mutations.type'

const state = {
  servers: {
    calculator: {
      data: null,
      expire: null,
    },
    list: {
      data: null,
      expire: null,
    },
  },
  networks: {
    calculator: {
      data: null,
      expire: null,
    },
    list: {
      data: null,
      expire: null,
    },
  },
}

// getters
const getters = {
}

// actions
const actions = {
  [VMWARE_SERVER_CALCULATOR_FETCH] (context) {
    if (state.servers.calculator.expire && state.servers.calculator.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.servers.calculator.data)
      })
    } else {
      return ApiService.get('/app-api/servers/create/json')
        .then(({ data }) => {
          context.commit(VMWARE_SERVER_CALCULATOR_SET, data)
          return data
        })
    }
  },
  [VMWARE_SERVERS_FETCH] (context) {
    if (state.servers.list.expire && state.servers.list.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.servers.list.data)
      })
    } else {
      return ApiService.get('/app-api/servers/json')
        .then(({ data }) => {
          context.commit(VMWARE_SERVERS_SET, data)
          return data
        })
    }
  },
  [VMWARE_SERVER_CREATE] (context, model) {
    return ApiService.post('/app-api/servers', model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_DELETE] (context, serverId) {
    return ApiService.delete(`/app-api/servers/${serverId}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_DETAIL_FETCH] (context, serverId) {
    return ApiService.get(`/app-api/servers/${serverId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_PERFORMANCE] (context, params) {
    return ApiService.query(`/app-api/servers/${params.serverId}/performance?period=${params.interval}`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_CONSOLE_TOKEN_FETCH] (context, serverId) {
    return ApiService.post(`/app-api/servers/${serverId}/web-console`, null, { timeout: 120000 })
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_TASK_FETCH] (context, taskId) {
    return ApiService.get(`/app-api/vmware/tasks/${taskId}/json`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_POWER_ACTION] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/power/${params.action}`, {})
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_REBUILD_GET_IMAGES] (context, serverId) {
    return ApiService.get(`/app-api/servers/${serverId}/rebuild/images`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_REBUILD] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/rebuild`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_COPY] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/copy`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_SNAPSHOT_CREATE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/snapshots`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_SNAPSHOT_RESTORE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/snapshots/${params.snapshotId}/restore`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_SNAPSHOT_DELETE] (context, params) {
    return ApiService.delete(`/app-api/servers/${params.serverId}/snapshots/${params.snapshotId}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_BACKUP_SETTINGS] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/backup`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_BACKUP_RESTORE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/backup/restore-points/${params.restorePointId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_BACKUP_DISABLE] (context, serverId) {
    return ApiService.delete(`/app-api/servers/${serverId}/backup`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_CONFIGURATION_CHANGE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/configure`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_RENAME] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/rename`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_DISK_CREATE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/additional-disks`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_DISK_EDIT] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/additional-disks/${params.diskId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_DISK_DELETE] (context, params) {
    return ApiService.delete(`/app-api/servers/${params.serverId}/additional-disks/${params.diskId}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_LICENSES_AVAILABALE_FETCH] (context, params) {
    return ApiService.get(`/app-api/servers/${params.serverId}/licenses/available`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_LICENSES_ADD] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/licenses`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_NETWORKS_AVAILABALE_FETCH] (context, params) {
    return ApiService.get(`/app-api/servers/${params.serverId}/networks/available`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_NIC_EDIT] (context, params) {
    return ApiService.put(`/app-api/servers/${params.serverId}/nic/${params.nicId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_NETWORK_DISCONNECT] (context, params) {
    return ApiService.delete(`/app-api/servers/${params.serverId}/nic/${params.nicId}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_NETWORK_CLIENT_ADD] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/nic/client-network`, {
      NetworkId: params.networkId,
    })
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_NETWORK_SHARED_ADD] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/nic/shared-network`, {
      IsIpV6: params.IsIpV6,
      BandwidthMbps: params.BandwidthMbps,
    })
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_FIREWALL_RULE_VALIDATE] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/firewall/validate-rule`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_FIREWALL_UPDATE] (context, params) {
    return ApiService.put(`/app-api/servers/${params.serverId}/firewall`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_TAG_ADD] (context, params) {
    return ApiService.post(`/app-api/servers/${params.serverId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_SERVER_TAG_REMOVE] (context, params) {
    return ApiService.delete(`/app-api/servers/${params.serverId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORKS_FETCH] (context) {
    if (state.networks.list.expire && state.networks.list.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.networks.list.data)
      })
    } else {
      return ApiService.get(`/app-api/networks/json`)
        .then(({ data }) => {
          context.commit(VMWARE_NETWORKS_SET, data)
          return data
        })
    }
  },
  [VMWARE_NETWORK_CALCULATOR_FETCH] (context) {
    if (state.networks.calculator.expire && state.networks.calculator.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.networks.calculator.data)
      })
    } else {
      return ApiService.get(`/app-api/networks/create/json`)
        .then(({ data }) => {
          context.commit(VMWARE_NETWORK_CALCULATOR_SET, data)
          return data
        })
    }
  },
  [VMWARE_NETWORK_CREATE] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkType}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_FETCH] (context, params) {
    return ApiService.get(`/app-api/networks/${params.networkType}/${params.networkId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_NETWORK_CONNECT] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkId}/connect`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_SERVERS_EXPIRED_SET)
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_NAT_CREATE] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkId}/nat/rules`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_NAT_EDIT] (context, params) {
    return ApiService.put(`/app-api/networks/${params.networkId}/nat/rules/${params.natId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_NAT_DELETE] (context, params) {
    return ApiService.delete(`/app-api/networks/${params.networkId}/nat/rules/${params.natId}`)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_VPN_CREATE] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkId}/vpn/tunnels`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_VPN_EDIT] (context, params) {
    return ApiService.put(`/app-api/networks/${params.networkId}/vpn/tunnels/${params.vpnId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_VPN_DELETE] (context, params) {
    return ApiService.delete(`/app-api/networks/${params.networkId}/vpn/tunnels/${params.vpnId}`)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_VPN_STATE] (context, networkId) {
    return ApiService.get(`/app-api/networks/${networkId}/vpn/tunnels/status`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_NETWORK_FIREWALL_RULE_VALIDATE] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkId}/firewall/validate`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_NETWORK_FIREWALL_UPDATE] (context, params) {
    return ApiService.put(`/app-api/networks/${params.networkId}/firewall`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_EDIT] (context, params) {
    return ApiService.put(`/app-api/networks/${params.networkType}/${params.networkId}`, params.model)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_DELETE] (context, params) {
    return ApiService.delete(`/app-api/networks/${params.networkType}/${params.networkId}`)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_TAG_ADD] (context, params) {
    return ApiService.post(`/app-api/networks/${params.networkType}/${params.networkId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_NETWORK_TAG_REMOVE] (context, params) {
    return ApiService.delete(`/app-api/networks/${params.networkType}/${params.networkId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        context.commit(VMWARE_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VMWARE_TEMPLATES_FETCH] () {
    return ApiService.get(`/app-api/server-templates/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_TEMPLATES_SERVERS] () {
    return ApiService.get(`/app-api/server-templates/servers`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_TEMPLATE_CREATE] (context, model) {
    return ApiService.post(`/app-api/server-templates/create`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_TEMPLATE_DELETE] (context, templateId) {
    return ApiService.delete(`/app-api/server-templates/${templateId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_TEMPLATE_DETAIL] (context, templateId) {
    return ApiService.get(`/app-api/server-templates/${templateId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_GET_REASON] () {
    return ApiService.get(`/app-api/server-deletion-reasons`)
      .then(({ data }) => {
        return data
      })
  },
  [VMWARE_SERVER_SEND_REASON] (context, params) {
    return ApiService.post(`/app-api/server-deletion-reasons`, params)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [VMWARE_SERVER_CALCULATOR_SET] (state, calculator) {
    state.servers.calculator.data = calculator
    state.servers.calculator.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_CALCULATOR_EXPIRE_MS)).getTime()
  },
  [VMWARE_SERVERS_SET] (state, servers) {
    state.servers.list.data = servers
    state.servers.list.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_LIST_EXPIRE_MS)).getTime()
  },
  [VMWARE_SERVERS_EXPIRED_SET] (state) {
    state.servers.list.expire = null
  },
  [VMWARE_NETWORK_CALCULATOR_SET] (state, calculator) {
    state.networks.calculator.data = calculator
    state.networks.calculator.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_CALCULATOR_EXPIRE_MS)).getTime()
  },
  [VMWARE_NETWORKS_SET] (state, networks) {
    state.networks.list.data = networks
    state.networks.list.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_LIST_EXPIRE_MS)).getTime()
  },
  [VMWARE_NETWORKS_EXPIRED_SET] (state) {
    state.networks.list.expire = null
  },
  [COMMON_CLEAR_CACHE] (state) {
    state.servers.calculator.expire = null
    state.servers.list.expire = null
    state.networks.calculator.expire = null
    state.networks.list.expire = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
