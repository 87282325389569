<template>
  <router-link
    :to="projectTo"
    @click="onClick"
  >
    <slot />
  </router-link>
</template>

<script>
import { RouterLink } from 'vue-router'

export default {
  name: 'RouterLinkProject',
  components: { RouterLink },
  props: {
    to: { type: [String, Object], default: null },
  },
  emits: ['click'],
  data: function () {
    return {
      projectTo: this.addProjectId(this.to),
    }
  },
  watch: {
    to: function (newValue) {
      this.projectTo = this.addProjectId(newValue)
    },
  },
  methods: {
    addProjectId (projectTo) {
      if (typeof projectTo === 'object') {
        projectTo.query = projectTo.query || {}
        projectTo.query.pid = this.$store.state.project.current.Id
        return projectTo
      }

      if (typeof projectTo === 'string') {
        if (projectTo.indexOf(`pid=${this.$store.state.project.current.Id}`) >= 0) {
          return projectTo
        }

        let queryIndex = projectTo.indexOf('?')
        let hashIndex = projectTo.indexOf('#')

        if (queryIndex >= 0 && hashIndex >= 0) {
          return `${projectTo.slice(0, hashIndex)}&pid=${this.$store.state.project.current.Id}${projectTo.slice(hashIndex)}`
        }

        if (queryIndex >= 0 && hashIndex < 0) {
          return projectTo + `&pid=${this.$store.state.project.current.Id}`
        }

        if (queryIndex < 0 && hashIndex >= 0) {
          return `${projectTo.slice(0, hashIndex)}?pid=${this.$store.state.project.current.Id}${projectTo.slice(hashIndex)}`
        }

        if (queryIndex < 0 && hashIndex < 0) {
          return projectTo + `?pid=${this.$store.state.project.current.Id}`
        }
      }
      return projectTo
    },
    onClick () {
      this.$emit('click')
    },
  },
}
</script>
