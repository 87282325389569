import {
  BILLING_ACT_DOWNLOAD,
  BILLING_ACT_FETCH,
  BILLING_ACT_PARCELBILL_DOWNLOAD,
  BILLING_ADD_PROMISED,
  BILLING_DETAIL_ACTS_FETCH,
  BILLING_DETAIL_BY_MONTH_DAY_FETCH,
  BILLING_DETAIL_BY_MONTH_DAY_INSTANCE_FETCH,
  BILLING_DETAIL_BY_MONTH_FETCH,
  BILLING_DETAIL_FETCH,
  BILLING_DETAIL_INVOICES_FETCH,
  BILLING_INVOICE_DOWNLOAD,
  BILLING_INVOICE_FETCH,
  BILLING_PAYMENTS_FETCH,
  BILLING_PAYMENT_AUTOPAY_BEPAID_SET,
  BILLING_PAYMENT_AUTOPAY_CLOUD_PAYMENT_SET,
  BILLING_PAYMENT_AUTOPAY_DISABLE,
  BILLING_PAYMENT_AUTOPAY_EDIT,
  BILLING_PAYMENT_AUTOPAY_STRIPE_SET,
  BILLING_PAYMENT_PAY_STRIPE_STATUS,
  BILLING_PAYMENT_CARD_DELETE,
  BILLING_PAYMENT_INVOICE_CREATE,
  BILLING_PAYMENT_INVOICE_FETCH,
  BILLING_PAYMENT_INVOICE_SET,
  BILLING_PAYMENT_PAY_BEPAID,
  BILLING_PAYMENT_PAY_BEPAID_ERIP,
  BILLING_PAYMENT_PAY_BEPAID_ERIP_STATUS,
  BILLING_PAYMENT_PAY_BEPAID_STATUS,
  BILLING_PAYMENT_PAY_BEPAID_TOKEN,
  BILLING_PAYMENT_PAY_CLOUD_PAYMENT,
  BILLING_PAYMENT_PAY_CLOUD_PAYMENT_STATUS,
  BILLING_PAYMENT_PAY_CLOUD_PAYMENT_TOKEN,
  BILLING_PAYMENT_PAY_EXTERNAL,
  BILLING_PAYMENT_PAY_PAYPAL,
  BILLING_PAYMENT_PAY_PAYPAL_CARDS,
  BILLING_PAYMENT_PAY_PAYPAL_CAPTURE,
  BILLING_PAYMENT_PAY_STRIPE,
  BILLING_PAYMENT_PAY_STRIPE_TOKEN,
  BILLING_PAYMENT_PAY_ROBOKASSA,
  BILLING_PAYMENT_PAY_ROBOKASSA_STATUS,
  BILLING_RECONSILIATION_REPORT_DOWNLOAD,
  BILLING_RECONSILIATION_REPORT_FETCH,
  BILLING_RECONSILIATION_REPORT_SETTINGS,
  BILLING_TEST_PAID,
  BILLING_BONUS_TASKS,
  BILLING_BONUS_TASK_SEND,
  BILLING_TARIFFS_FETCH,
  BILLING_GET_QRCODE_SBP,
  BILLING_GET_STATUS_SBP,
  BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT,
  BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_TOKEN,
  BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_STATUS,
  BILLING_PAYMENT_AUTOPAY_TIP_TOP_SET,
} from '../actions.type'

import ApiService from '@/api'

import FileDownload from 'js-file-download'
import { BILLING_SCRIPT_BEPAID_STATE_SET, BILLING_SCRIPT_CLOUD_PAYMENTS_STATE_SET, BILLING_SCRIPT_SEON_STATE_SET, BILLING_SCRIPT_TIP_TOP_STATE_SET } from '../mutations.type'

const state = {
  seon: {
    isLoaded: false,
    isLoading: false,
    isError: false,
  },
  bepaid: {
    isLoaded: false,
    isLoading: false,
    isError: false,
  },
  cloudPayments: {
    isLoaded: false,
    isLoading: false,
    isError: false,
  },
  tipTop: {
    isLoaded: false,
    isLoading: false,
    isError: false,
  },
}

// getters
const getters = {
}
// actions
const actions = {
  [BILLING_TEST_PAID] () {
    return ApiService.post('/app-api/billing/extended-test')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_FETCH] () {
    return ApiService.get('/app-api/billing/json')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_BY_MONTH_FETCH] (context, params) {
    return ApiService.query(`/app-api/billing/detalization/json?year=${params.year}&month=${params.month}`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_BY_MONTH_DAY_FETCH] (context, params) {
    return ApiService.query(`/app-api/billing/detalization/day?year=${params.year}&month=${params.month}&day=${params.day}`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_BY_MONTH_DAY_INSTANCE_FETCH] (context, params) {
    return ApiService.query(`/app-api/billing/${params.name}?${params.query}`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_INVOICES_FETCH] () {
    return ApiService.query(`/app-api/company/docs/invoices/json`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_DETAIL_ACTS_FETCH] () {
    return ApiService.query(`/app-api/company/docs/acts/json`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_ACT_FETCH] (context, actId) {
    return ApiService.query(`/app-api/company/docs/act${actId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_ACT_DOWNLOAD] (context, actId) {
    return ApiService.post(`/app-api/company/docs/act${actId}/pdf`, null, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, 'file.pdf')
      })
  },
  [BILLING_ACT_PARCELBILL_DOWNLOAD] (context, actId) {
    return ApiService.post(`/app-api/company/docs/parcelbill${actId}/pdf`, null, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, 'file.pdf')
      })
  },
  [BILLING_INVOICE_FETCH] (context, number) {
    return ApiService.query(`/app-api/company/docs/invoice${number}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_INVOICE_DOWNLOAD] (context, number) {
    return ApiService.post(`/app-api/company/docs/invoice${number}/pdf`, null, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, 'file.pdf')
      })
  },
  [BILLING_RECONSILIATION_REPORT_SETTINGS] () {
    return ApiService.get(`/app-api/company/docs/reconsiliation-report/settings`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_RECONSILIATION_REPORT_FETCH] (context, query) {
    return ApiService.query(`/app-api/company/docs/reconsiliation-report/json?${query}`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_RECONSILIATION_REPORT_DOWNLOAD] (context, query) {
    return ApiService.post(`/app-api/company/docs/reconsiliation-report/pdf?${query}`, null, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, 'file.pdf')
      })
  },
  [BILLING_PAYMENTS_FETCH] () {
    return ApiService.get('/app-api/billing/addmoney/json')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_ADD_PROMISED] () {
    return ApiService.post('/app-api/billing/promised-payment')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_INVOICE_FETCH] () {
    return ApiService.get('/app-api/company/invoice/create')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_INVOICE_SET] (context, model) {
    return ApiService.post('/app-api/company/auto-invoice/change', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_INVOICE_CREATE] (context, model) {
    return ApiService.post('/app-api/company/invoice/create', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_BEPAID] (context, model) {
    return ApiService.post('/app-api/payment-gateways/bepaid/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_BEPAID_STATUS] (context, onlinePaymentId) {
    return ApiService.get(`/app-api/payment-gateways/bepaid/payments/${onlinePaymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_BEPAID_TOKEN] (context, model) {
    return ApiService.post('/app-api/payment-gateways/bepaid/card/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_BEPAID_ERIP] (context, model) {
    return ApiService.post('/app-api/payment-gateways/bepaid-erip/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_BEPAID_ERIP_STATUS] (context, onlinePaymentId) {
    return ApiService.get(`/app-api/payment-gateways/bepaid-erip/payments/${onlinePaymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_CLOUD_PAYMENT] (context, model) {
    return ApiService.post('/app-api/payment-gateways/cloudpayments/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_CLOUD_PAYMENT_TOKEN] (context, model) {
    return ApiService.post('/app-api/payment-gateways/cloudpayments/card/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_CLOUD_PAYMENT_STATUS] (context, onlinePaymentId) {
    return ApiService.get(`/app-api/payment-gateways/cloudpayments/payments/${onlinePaymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT] (context, model) {
    return ApiService.post('/app-api/payment-gateways/tiptop/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_TOKEN] (context, model) {
    return ApiService.post('/app-api/payment-gateways/tiptop/card/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_TIP_TOP_PAYMENT_STATUS] (context, onlinePaymentId) {
    return ApiService.get(`/app-api/payment-gateways/tiptop/payments/${onlinePaymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_STRIPE] (context, model) {
    return ApiService.post('/app-api/payment-gateways/stripe/payment-intents', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_STRIPE_TOKEN] (context, model) {
    return ApiService.post('/app-api/payment-gateways/stripe/payment-intents', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_PAYPAL] (context, model) {
    return ApiService.post('/app-api/payment-gateways/paypal/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_PAYPAL_CARDS] (context, model) {
    return ApiService.post('/app-api/payment-gateways/paypal/card/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_PAYPAL_CAPTURE] (context, model) {
    return ApiService.post(`/app-api/payment-gateways/paypal/payments/capture`, model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_EXTERNAL] (context, model) {
    return ApiService.post('/app-api/payment-gateways/external/checkout', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_BEPAID_SET] (context, model) {
    return ApiService.post('/app-api/payment-gateways/bepaid/auto-payment', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_CLOUD_PAYMENT_SET] (context, model) {
    return ApiService.post('/app-api/payment-gateways/cloudpayments/auto-payment', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_TIP_TOP_SET] (context, model) {
    return ApiService.post('/app-api/payment-gateways/tiptop/auto-payment', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_STRIPE_SET] (context, model) {
    return ApiService.post('/app-api/payment-gateways/stripe/setup-intents', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_STRIPE_STATUS] (context, onlinePaymentId) {
    return ApiService.query(`/app-api/payment-gateways/stripe/payments/${onlinePaymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_DISABLE] (context, type) {
    return ApiService.post(`/app-api/payment-gateways/${type}/auto-payment/disable`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_AUTOPAY_EDIT] (context, params) {
    return ApiService.post(`/app-api/payment-gateways/${params.type}/auto-payment/edit`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_CARD_DELETE] (context, params) {
    return ApiService.delete(`/app-api/payment-gateways/${params.type}/card`, { data: params.model })
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_BONUS_TASKS] () {
    return ApiService.get('/app-api/marketing-tasks')
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_BONUS_TASK_SEND] (context, params) {
    return ApiService.post('/app-api/marketing-tasks', params)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_TARIFFS_FETCH] () {
    return ApiService.get('/app-api/tariffs').then(({ data }) => {
      return data
    })
  },
  [BILLING_PAYMENT_PAY_ROBOKASSA] (context, model) {
    return ApiService.post('/app-api/payment-gateways/robokassa/payments', model)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_PAYMENT_PAY_ROBOKASSA_STATUS] (context, id) {
    return ApiService.get(`/app-api/payment-gateways/robokassa/payments/${id}/status`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_GET_QRCODE_SBP] (context, params) {
    return ApiService.post(`/app-api/payment-gateways/itpay-sbp/payments?paymentGatewayId=${params.PaymentGatewayId}&PaymentAmount=${params.PaymentAmount}`)
      .then(({ data }) => {
        return data
      })
  },
  [BILLING_GET_STATUS_SBP] (context, paymentId) {
    return ApiService.get(`/app-api/payment-gateways/itpay-sbp/payments/${paymentId}/status`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [BILLING_SCRIPT_SEON_STATE_SET] (state, data) {
    state.seon.isLoaded = data.isLoaded
    state.seon.isLoading = data.isLoading
    state.seon.isError = data.isError
  },
  [BILLING_SCRIPT_BEPAID_STATE_SET] (state, data) {
    state.bepaid.isLoaded = data.isLoaded
    state.bepaid.isLoading = data.isLoading
    state.bepaid.isError = data.isError
  },
  [BILLING_SCRIPT_CLOUD_PAYMENTS_STATE_SET] (state, data) {
    state.cloudPayments.isLoaded = data.isLoaded
    state.cloudPayments.isLoading = data.isLoading
    state.cloudPayments.isError = data.isError
  },
  [BILLING_SCRIPT_TIP_TOP_STATE_SET] (state, data) {
    state.tipTop.isLoaded = data.isLoaded
    state.tipTop.isLoading = data.isLoading
    state.tipTop.isError = data.isError
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
