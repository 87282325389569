import BaseNotifications from '@/components/base/BaseNotifications.vue'
import { eventBus, eventTypes } from './eventBus.js'

export default {
  install(app) {
    app.component('AppNotifications', BaseNotifications)

    const notify = {
      notice: (msg) => {
        eventBus.$emit(eventTypes.ADD, { name: msg, type: 'notice' })
      },
      error: (msg) => {
        eventBus.$emit(eventTypes.ADD, { name: msg, type: 'error' })
      },
      warning: (msg) => {
        eventBus.$emit(eventTypes.ADD, { name: msg, type: 'warning' })
      },
      confirmation: (msg) => {
        eventBus.$emit(eventTypes.ADD, { name: msg, type: 'confirmation' })
      },
      addModelErrors: (modelState) => {
        eventBus.$emit(eventTypes.ADD_MODEL_ERRORS, modelState)
      },
    }

    const name = 'notify'

    app.config.globalProperties['$' + name] = notify
    app[name] = notify
  },
}
